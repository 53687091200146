import React from "react";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search"; // Import the search icon
import MoroccanPlaces from "./MoroccanPlaces";
import { FaSearch } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";





const LocationSearchAutocompleteLg = ({ setSelectedLocation }) => {
  const locations = MoroccanPlaces();
  const navigate = useNavigate();
      const { t } = useTranslation();


  const handleLocationSelect = (newValue) => {
    setSelectedLocation(newValue);
    window.scrollTo(0, 0); 

    // Navigate to the results page with the selected location
    navigate("/results", { state: { selectedLocation: newValue } });
  };

  return (
    <Autocomplete
      options={locations}
      getOptionLabel={(option) => option.name}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {},
              "&:hover fieldset": {
                borderColor: "red",
                opacity: 0,
              },
              "&.Mui-focused fieldset": {
                borderColor: "red",
                opacity: 0,
              },
              "&.Mui-focused": {
                boxShadow: "none",
              },
              "& input": {
                boxShadow: "none",
              },
              "& textarea": {
                boxShadow: "none",
              },
            },
          }}
          label={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                lineHeight: "50px",
                fontSize: "1.25rem",
              }}
            >
              {t("searchplaceholder")}
            </Box>
          }
          variant="outlined"
          InputLabelProps={{
            // Add this to style the label
            style: { lineHeight: "50px", paddingRight: "4px" }, // Adjust the lineHeight to vertically center the label
          }}
          InputProps={{
            ...params.InputProps,
            style: { paddingRight: "8px", height: "80px" },
            autoComplete: "new-password",
            endAdornment: (
              <>
                <FaSearch
                  onClick={() => {
                    window.scrollTo(0, 0); // Scroll to the top
                    navigate("/results");
                  }}
                  className="cursor-pointer text-2xl hover:opacity-70 transition ease-in-out duration-360"
                  style={{
                    marginRight: 8,
                    marginLeft: 8,
                    color: "e61b00",
                    fontWeight: "bold",
                  }} // Adding fontWeight: "bold"
                />{" "}
                {/* Render the search icon */}
                {/* Remove the arrow icon */}
              </>
            ),
          }}
        />
      )}
      onChange={(event, newValue) => {
        handleLocationSelect(newValue);
      }}
      filterOptions={(options, { inputValue }) => {
        if (inputValue.trim() === "") {
          return [
            {
              name: "Type your address",
              latitude: 33.5731,
              longitude: -7.5898,
            },
          ];
        }

        const filteredOptions = options.filter((option) =>
          option.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        return filteredOptions.slice(0, 6);
      }}
    />
  );
};

export default LocationSearchAutocompleteLg;
