import React from 'react'
import { useTranslation } from "react-i18next";


export default function TermsAndConditions() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="p-4 max-w-4xl mx-auto ">
        <h1 className="text-2xl lg:text-4xl py-4 lg:py-20 text-center font-bold mb-4">
          {t("termsconditions")}
        </h1>
        <p className="mb-4">
          {t("termsconditions_p1", {
            url: (
              <a
                href="https://www.beytty.com"
                className="text-red-500 underline"
              >
                www.beytty.com
              </a>
            ),
            date: "[Date]",
          })}
        </p>

        <h2 className="text-xl font-semibold mb-2">{t("section1_title")}</h2>

        
        <p className="mb-4">
          {t("subsection1_1_p1", {
            amount: "[Amount]",
            city: "[City]",
            number: "[Number]",
            address: "[Address]",
          })}
        </p>
        <p className="mb-2">
          {t("subsection1_1_p2", { director_name: "[Director Name]" })}
        </p>
        <p className="mb-2">
          {t("subsection1_1_p3", { manager_name: "[Manager Name]" })}
        </p>
        <p className="mb-2">
          {t("subsection1_1_p4", { phone_number: "[Phone Number]" })}
        </p>
        <p className="mb-2">
          {t("subsection1_1_p5", {
            email: (
              <a
                href="mailto:contact@beytty.com"
                className="text-red-500 underline"
              >
                contact@beytty.com
              </a>
            ),
          })}
        </p>

      

        <h2 className="text-xl mt-4 font-semibold mb-2">{t("section2_title")}</h2>
        <ul className="list-disc pl-5">
          <li className="mb-2">
            <p>{t("definition_listing")}</p>
          </li>
          <li className="mb-2">
            <p>{t("definition_properties_sold")}</p>
          </li>
          <li className="mb-2">
            <p>{t("definition_partner")}</p>
          </li>
          <li className="mb-2">
            <p>{t("definition_real_estate_professional")}</p>
          </li>
          <li className="mb-2">
            <p>{t("definition_partner_real_estate_professional")}</p>
          </li>
          <li className="mb-2">
            <p>{t("definition_services")}</p>
          </li>
          <li className="mb-2">
            <p>
              {t("definition_site", {
                url: (
                  <a
                    href="https://www.beytty.com"
                    className="text-red-500 underline"
                  >
                    https://www.beytty.com
                  </a>
                ),
              })}
            </p>
          </li>
          <li className="mb-2">
            <p>{t("definition_user")}</p>
          </li>
          <li className="mb-2">
            <p>{t("definition_agent_profile")}</p>
          </li>
        </ul>
        <h2 className="text-xl font-semibold mb-2">{t("section3_title")}</h2>

        <h3 className="mt-4 text-lg font-semibold mb-1">
          {t("subsection3_1_title")}
        </h3>
        <p className="mb-4">{t("subsection3_1_p1")}</p>
        <p className="mb-4">{t("subsection3_1_p2")}</p>
        <p className="mb-4">{t("subsection3_1_p3")}</p>

        <h3 className="text-lg font-semibold mb-1">
          {t("subsection3_2_title")}
        </h3>
        <p className="mb-4">{t("subsection3_2_p1")}</p>
        <p className="mb-4">{t("subsection3_2_p2")}</p>

        <h3 className="text-lg font-semibold mb-1">
          {t("subsection3_3_title")}
        </h3>
        <p className="mb-4">
          {t("subsection3_3_p1", {
            your_company_address: "[Your Company Address]",
            email: (
              <a
                href="mailto:contact@beytty.com"
                className="text-red-500 underline"
              >
                contact@beytty.com
              </a>
            ),
          })}
        </p>
        <p className="mb-4">{t("subsection3_3_p2")}</p>

        <h3 className="text-lg font-semibold mb-1">
          {t("subsection3_4_title")}
        </h3>
        <p className="mb-4">{t("subsection3_4_p1")}</p>

        <h3 className="text-lg font-semibold mb-1">{t("section4_title")}</h3>
        <p className="mb-4">
          {t("section4_p1", {
            email: (
              <a
                href="mailto:contact@beytty.com"
                className="text-red-500 underline"
              >
                contact@beytty.com
              </a>
            ),
          })}
        </p>

        <h3 className="text-lg font-semibold mb-1">{t("section5_title")}</h3>
        <p className="mb-4">
          {t("section5_p1", {
            phone_number: "[Phone Number]",
            email: (
              <a
                href="mailto:contact@beytty.com"
                className="text-red-500 underline"
              >
                contact@beytty.com
              </a>
            ),
          })}
        </p>
        <p className="mb-4">{t("section5_p2")}</p>
        <p className="mb-4">{t("section5_p3")}</p>
        <h3 className="text-lg font-semibold mb-1">{t("section6_title")}</h3>
        <p className="mb-4">{t("section6_p1")}</p>
        <p className="mb-4">{t("section6_p2")}</p>

        <h4 className="text-md font-semibold mb-1">{t("section6.1_title")}</h4>
        <h5 className="text-md font-semibold mb-1">
          {t("section6.1.1_title")}
        </h5>
        <p className="mb-4">{t("section6.1.1_p1")}</p>

        <h5 className="text-md font-semibold mb-1">
          {t("section6.1.2_title")}
        </h5>
        <p className="mb-4">{t("section6.1.2_p1")}</p>
        <ul className="list-disc pl-5 mb-4">
          <li>{t("section6.1.2_bullet1")}</li>
          <li>{t("section6.1.2_bullet2")}</li>
          <li>{t("section6.1.2_bullet3")}</li>
          <li>{t("section6.1.2_bullet4")}</li>
          <li>{t("section6.1.2_bullet5")}</li>
        </ul>
        <p className="mb-4">{t("section6.1.2_p2")}</p>
        <ul className="list-disc pl-5 mb-4">
          <li>{t("section6.1.2.1_bullet1")}</li>
          <li>{t("section6.1.2.1_bullet2")}</li>
          <li>{t("section6.1.2.1_bullet3")}</li>
          <li>{t("section6.1.2.1_bullet4")}</li>
          <li>{t("section6.1.2.1_bullet5")}</li>
          <li>{t("section6.1.2.1_bullet6")}</li>
          <li>{t("section6.1.2.1_bullet7")}</li>
          <li>{t("section6.1.2.1_bullet8")}</li>
          <li>{t("section6.1.2.1_bullet9")}</li>
          <li>{t("section6.1.2.1_bullet10")}</li>
        </ul>
        <p className="mb-4">{t("section6.1.2_p3")}</p>
        <li>{t("section6.1.2_list3")}</li>
        <li>{t("section6.1.2_list3.1")}</li>

        <h4 className="text-md mt-4 font-semibold mb-1">
          {t("section6.1.3_title")}
        </h4>
        <p className="mb-4">{t("section6.1.3_p1")}</p>

        <h4 className="text-md font-semibold mb-1">
          {t("section6.1.4_title")}
        </h4>
        <p className="mb-4">{t("section6.1.4_p1")}</p>
        <ul className="list-disc pl-5 mb-4">
          <li>{t("section6.1.4_list")}</li>
          <li>{t("section6.1.4_list.1")}</li>
          <li>{t("section6.1.4_list.2")}</li>
          <li>{t("section6.1.4_list.3")}</li>
        </ul>
        <p className="mb-4">{t("section6.1.4_p2")}</p>

        <h4 className="text-md font-semibold mb-1">{t("section6.2_title")}</h4>
        <p className="mb-4">{t("section6.2_p1")}</p>
        <ul className="list-disc pl-5 mb-4">
          <li>{t("section6.2_list")}</li>
          <li>{t("section6.2_list.1")}</li>
          <li>{t("section6.2_list.2")}</li>
          <li>{t("section6.2_list.3")}</li>
          <li>{t("section6.2_list.4")}</li>
          <li>{t("section6.2_list.5")}</li>
        </ul>
        <p className="mb-4">{t("section6.2_p2")}</p>
        <ul className="list-disc pl-5">
          <li>{t("section6_contact_list")}</li>
          <li>{t("section6_contact_list.1")}</li>
        </ul>
        <h4 className="mt-4 text-md font-semibold mb-1">
          {t("section6.3_title")}
        </h4>
        <p className="mb-4">{t("section6.3_p1")}</p>
        <p className="mb-4">{t("section6.3_p2")}</p>

        <h5 className="text-md font-semibold mb-1">
          {t("section6.3.1_title")}
        </h5>
        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>{t("section6.3.1_cookie1_title")}:</strong>{" "}
            {t("section6.3.1_cookie1_description")}
          </li>
          <li>
            <strong>{t("section6.3.1_cookie2_title")}:</strong>{" "}
            {t("section6.3.1_cookie2_description")}
          </li>
          <li>
            <strong>{t("section6.3.1_cookie3_title")}:</strong>{" "}
            {t("section6.3.1_cookie3_description")}
          </li>
          <li>
            <strong>{t("section6.3.1_cookie4_title")}:</strong>{" "}
            {t("section6.3.1_cookie4_description")}
          </li>
          <li>
            <strong>{t("section6.3.1_cookie5_title")}:</strong>{" "}
            {t("section6.3.1_cookie5_description")}
          </li>
          <li>
            <strong>{t("section6.3.1_cookie6_title")}:</strong>{" "}
            {t("section6.3.1_cookie6_description")}
          </li>
        </ul>

        <p className="mb-4">{t("section6.3_p3")}</p>

        <p className="mb-4">{t("section6.3_p4")}</p>

        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>{t("section6.3_p5_title")}:</strong>{" "}
            {t("section6.3_p5_description")}
          </li>
          <li>
            <strong>{t("section6.3_p6_title")}:</strong>{" "}
            {t("section6.3_p6_description")}
          </li>
          <li>
            <strong>{t("section6.3_p7_title")}:</strong>{" "}
            {t("section6.3_p7_description")}
          </li>
        </ul>

        <p className="mb-4">
          {t("section6.3_p8")}
        </p>
        <p className="mb-4">
          <a
            href="https://www.youronlinechoices.com/fr/controler-ses-cookies/"
            className="text-red-500 underline"
          >
            {" "}
            {t("section6.3_p10")}
          </a>{" "}
          {t("section6.3_p9_separator")}{" "}
          <a
            href="https://www.allaboutcookies.org/fr/"
            className="text-red-500 underline"
          >
            {t("section6.3_p11")}
          </a>
          .
        </p>

        <h5 className="text-md font-semibold mb-1">{t("section6.3_p12.1")}</h5>
        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>{t("section6.3_p12.2")}</strong>
            <ul className="list-disc pl-5">
              <li>{t("section6.3_p12.3")}</li>
              <li>
                <a href={t("")} className="text-red-500 underline">
                  {t("section6.3_p12.13")}
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>{t("section6.3_p12.4")}</strong>
            <ul className="list-disc pl-5">
              <li>{t("section6.3_p12.5")}</li>
              <li>
                <a href={t("")} className="text-red-500 underline">
                  {t("section6.3_p12.13")}
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>{t("section6.3_p12.6")}</strong>
            <ul className="list-disc pl-5">
              <li>{t("section6.3_p12.7")}</li>
              <li>
                <a href={t("")} className="text-red-500 underline">
                  {t("")}
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>{t("section6.3_p12.8")}</strong>
            <ul className="list-disc pl-5">
              <li>{t("section6.3_p12.9")}</li>
              <li>
                <a href={t("")} className="text-red-500 underline">
                  {t("section6.3_p12.13")}
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>{t("section6.3_p12.11")}</strong>
            <ul className="list-disc pl-5">
              <li>{t("section6.3_p12.12")}</li>
              <li>
                <a href={t("")} className="text-red-500 underline">
                  {t("section6.3_p12.13")}
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <h4 className="text-md font-semibold mb-1">{t("section6.4_title")}</h4>
        <p className="mb-4">{t("section6.4_p1")}</p>
        <p className="mb-4 font-semibold">{t("section6.4_p2")}</p>
        <p className="mb-4">{t("section6.4_p2_sales_fee")}</p>
        <p className="mb-4">{t("section6.4_p2_purchases_fee")}</p>
        <p className="mb-4">{t("section6.4_p2_rentals_landlord_fee")}</p>
        <p className="mb-4">{t("section6.4_p2_rentals_tenant_fee")}</p>
        <h4 className="text-md font-semibold mb-1">{t("section6.5_title")}</h4>
        <p className="mb-4">{t("section6.5_p1")}</p>
        <p className="mb-4">{t("section6.5_p2")}</p>

        <p className="mb-4">{t("section6.5_data_description")}</p>
        <ul className="list-disc pl-5 mb-4">
          <li>{t("section6.5_data_item1")}</li>
          <li>{t("section6.5_data_item2")}</li>
          <li>{t("section6.5_data_item3")}</li>
          <li>{t("section6.5_data_item4")}</li>
          <li>{t("section6.5_data_item5")}</li>
        </ul>

        <h5 className="text-md font-semibold mb-1">
          {t("section6.5_legal_basis_title")}
        </h5>
        <p className="mb-4">{t("section6.5_legal_basis_description")}</p>

        <h5 className="text-md font-semibold mb-1">
          {t("section6.5_data_retention_title")}
        </h5>
        <p className="mb-4">{t("section6.5_data_retention_description")}</p>

        <h5 className="text-md font-semibold mb-1">
          {t("section6.5_data_recipients_title")}
        </h5>
        <p className="mb-4">{t("section6.5_data_recipients_description")}</p>

        <h5 className="text-md font-semibold mb-1">
          {t("section6.5_data_subjects_rights_title")}
        </h5>
        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>{t("section6.5_rights_access_title")}:</strong>{" "}
            {t("section6.5_rights_access_description")}
          </li>
          <li>
            <strong>{t("section6.5_rights_erasure_title")}:</strong>{" "}
            {t("section6.5_rights_erasure_description")}
          </li>
          <li>
            <strong>{t("section6.5_rights_rectification_title")}:</strong>{" "}
            {t("section6.5_rights_rectification_description")}
          </li>
          <li>
            <strong>{t("section6.5_rights_opposition_title")}:</strong>{" "}
            {t("section6.5_rights_opposition_description")}
          </li>
          <li>
            <strong>{t("section6.5_rights_limitation_title")}:</strong>{" "}
            {t("section6.5_rights_limitation_description")}
          </li>
        </ul>

        <h5 className="text-md font-semibold mb-1">
          {t("section6.5_exercising_rights_title")}
        </h5>
        <p className="mb-4">
          {t("section6.5_exercising_rights_description")}{" "}
          <a
            href="mailto:contact@beytty.com"
            className="text-red-500 underline"
          >
            contact@beytty.com
          </a>
          . {t("section6.5_exercising_rights_additional")}
        </p>

        <p className="mb-4">{t("section6.5_dispute_clause")}</p>

        <h4 className="text-lg font-semibold mb-2">{t("section7_title")}</h4>
        <p className="mb-4">{t("section7_p1")}</p>

        <h4 className="text-lg font-semibold mb-2">{t("section8_title")}</h4>
        <p className="mb-4">{t("section8_p1")}</p>

        <h5 className="text-md font-semibold mb-1">
          {t("section8.1_partner_responsibility_title")}
        </h5>
        <p className="mb-4">
          {t("section8.1_partner_responsibility_description")}
        </p>

        <h5 className="text-md font-semibold mb-1">
          {t("section8.2_limitations_of_liability_title")}
        </h5>
        <p className="mb-4">
          {t("section8.2_limitations_of_liability_description")}
        </p>

        <h4 className="text-lg font-semibold mb-2">{t("section9_title")}</h4>
        <p className="mb-4">{t("section9_p1")}</p>

        <h4 className="text-lg font-semibold mb-2">{t("section10_title")}</h4>
        <p className="mb-4">
          {t("section10_p1")}
          <a
            href="mailto:contact@beytty.com"
            className="text-red-500 underline"
          >
            contact@beytty.com
          </a>
          .
        </p>

        <h4 class="text-lg font-semibold mb-2">{t("terms_of_sale_title")}</h4>
        <h5 class="text-md font-semibold mb-1">{t("1section1_title")}</h5>
        <p class="mb-4">{t("section1_description")}</p>

        <h5 class="text-md font-semibold mb-1">{t("1section2_title")}</h5>
        <p class="mb-4">{t("section2_description")}</p>

        <h4 class="text-lg font-semibold mb-2">{t("1section3_title")}</h4>
        <p class="mb-4">{t("section3_description")}</p>

        <h4 class="text-lg font-semibold mb-2">{t("1section4_title")}</h4>
        <p class="mb-4">{t("section4_description")}</p>

        <h4 class="text-lg font-semibold mb-2">{t("1section5_title")}</h4>
        <p class="mb-4">{t("section5_description")}</p>
        <ul class="list-disc list-inside mb-4">
          <li>{t("section5_list_item1")}</li>
          <li>{t("section5_list_item2")}</li>
          <li>{t("section5_list_item3")}</li>
          <li>{t("section5_list_item4")}</li>
        </ul>
        <p class="mb-4">{t("section5_additional_description")}</p>

        <h4 class="text-lg font-semibold mb-2">{t("1section6_title")}</h4>
        <p class="mb-4">{t("section6_description")}</p>
        <ul class="list-disc list-inside mb-4">
          <li>{t("section6_list_item1")}</li>
          <li>{t("section6_list_item2")}</li>
          <li>{t("section6_list_item3")}</li>
          <li>{t("section6_list_item4")}</li>
        </ul>
        <p class="mb-4">{t("section6_compliance_clause")}</p>

        <h4 class="text-lg font-semibold mb-2">{t("1section7_title")}</h4>
        <p class="mb-4">{t("section7_description")}</p>
        <ul class="list-disc list-inside mb-4">
          <li>{t("section7_list_item1")}</li>
          <li>{t("section7_list_item2")}</li>
          <li>{t("section7_list_item3")}</li>
          <li>{t("section7_list_item4")}</li>
        </ul>
        <p class="mb-4">{t("section7_compliance_clause")}</p>

        <h4 class="text-lg font-semibold mb-2">{t("1section8_title")}</h4>
        <p class="mb-4">{t("section8_description")}</p>
        <ul class="list-disc list-inside mb-4">
          <li>{t("section8_list_item1")}</li>
          <li>{t("section8_list_item2")}</li>
          <li>{t("section8_list_item3")}</li>
          <li>{t("section8_list_item4")}</li>
        </ul>
        <p class="mb-4">{t("section8_compliance_clause")}</p>

        <h4 class="text-lg font-semibold mb-2">{t("1section9_title")}</h4>
        <p class="mb-4">{t("section9_description")}</p>

        <h4 class="text-lg font-semibold mb-2">{t("1section10_title")}</h4>
        <p class="mb-4">{t("section10_description")}</p>

        <h4 class="text-lg font-semibold mb-2">{t("section11_title")}</h4>
        <p class="mb-4">{t("section11_description")}</p>

        <h4 class="text-lg font-semibold mb-2">{t("section12_title")}</h4>
        <p class="mb-4">{t("section12_description")}</p>
      </div>
    </div>
  );
}

