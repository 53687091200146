import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { initializeAnalytics, logPageView } from "./analytics"; // Import the analytics functions

import Home from "./pages/Home";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPassword from "./pages/ForgotPassword";
import Header from "./components/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateListing from "./pages/CreateListing";
import Listing from "./pages/Listing";
import EditListing from "./pages/EditListing";
import Results from "./pages/Results";
import AgentSignUp from "./pages/AgentSignUp";
import Footer from "./components/Footer";
import AgentGuard from "./components/AgentGuard";
import Help from "./pages/Help";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import SecondHeader from "./components/SecondHeader";
import Sell from "./pages/Sell";
import BeyttyListing from "./pages/BeyttyListing";
import AgentList from "./pages/AgentList";
import AgentProfile from "./pages/AgentProfile";
import MyListings from "./pages/MyListings";
import AdminPanel from "./pages/AdminPanel";
import AdminGuard from "./components/TheAdminGuard";
import AdminSignUp from "./pages/AdminSignUp";
import UserGuard from "./components/UserGuard";
import MeGuard from "./components/meGuard";
import BeyttyDetails from "./pages/BeyttyDetails";
import TermsAndConditions from "./pages/TermsAndConditions";
import TermsOfUse from "./pages/TermsOfUse";
import FavoriteListings from "./pages/FavoriteListings";
import i18n from "i18next"; // Import i18n from i18next library
import { I18nextProvider, useTranslation } from "react-i18next";
import Unverified from "./components/Unverified";
import PhoneNumberGuard from "./components/PhoneNumberGurad";
import NotFound from "./pages/NotFound";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FinancingCalculatorPage from "./pages/FinancingCalculatorPage";

const theme = createTheme({
  typography: {
    fontFamily: "Lexend, sans-serif",
  },
});

function App() {
  useEffect(() => {
    initializeAnalytics(); // Initialize Google Analytics
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <Router>
            <HeaderWithCondition />
            <RoutesWithAnalytics />
            <FooterWithCondition />
          </Router>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </I18nextProvider>
      </ThemeProvider>
    </>
  );
}

// Initialize i18next
i18n.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "fr", // Default language
  resources: {
    en: {
      translation: require("./locales/en/translation.json"),
    },
    fr: {
      translation: require("./locales/fr/translation.json"),
    },
    ar: {
      translation: require("./locales/ar/translation.json"),
    },
  },
});

function RoutesWithAnalytics() {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname); // Log page view on route change
    window.scrollTo(0, 0); // Scroll to the top of the page on route change
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/profile" element={<Unverified />}>
        <Route path="/profile" element={<Profile />} />
      </Route>
      <Route path="/mylistings" element={<Unverified />}>
        <Route path="/mylistings" element={<MyListings />} />
      </Route>
      <Route path="/savedlistings" element={<Unverified />}>
        <Route path="/savedlistings" element={<FavoriteListings />} />
      </Route>
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/termsandconditions" element={<TermsAndConditions />} />
      <Route path="/termsofuse" element={<TermsOfUse />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route
        path="/admin-sign-up"
        element={
          <MeGuard>
            <AdminSignUp />
          </MeGuard>
        }
      />
      <Route path="/agent-sign-up" element={<AgentSignUp />} />
      <Route path="/beyttylisting" element={<Unverified />}>
        <Route path="/beyttylisting" element={<BeyttyListing />} />
      </Route>
      <Route path="/help" element={<Help />} />
      <Route path="/agent/:agentId" element={<AgentProfile />} />
      <Route path="/email-verification" element={<EmailVerificationPage />} />
      <Route
        path="/admin-panel"
        element={
          <AdminGuard>
            <AdminPanel />
          </AdminGuard>
        }
      />
      <Route path="/sell" element={<Sell />} />
      <Route path="/results" element={<Results />} />
      <Route
        path="/FinancingCalculator"
        element={<FinancingCalculatorPage />}
      />
      <Route path="/agentlist" element={<AgentList />} />
      <Route path="/listingdetails/:listingId" element={<Listing />} />
      <Route path="/beyttydetails/:listingId" element={<BeyttyDetails />} />
      <Route path="/create-listing" element={<PhoneNumberGuard />}>
        <Route path="/create-listing" element={<CreateListing />} />
      </Route>
      <Route path="/edit-listing" element={<PrivateRoute />}>
        <Route path="/edit-listing/:listingId" element={<EditListing />} />
      </Route>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      {/* 404 Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function HeaderWithCondition() {
  const [hideHeader, setHideHeader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    const shouldHideHeader = [
      "/sign-in",
      "/admin-sign-up",
      "/forgot-password",
      "/sign-up",
      "/agent-sign-up",
      "/agent-sign-in",
      "/admin-panel",
    ].includes(currentPath);
    setHideHeader(shouldHideHeader);
  }, [window.location.pathname]);

  return hideHeader ? null : <Header />;
}

function FooterWithCondition() {
  const [hideFooter, setHideFooter] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    const shouldHideFooter = [
      "/sign-in",
      "/admin-sign-up",
      "/forgot-password",
      "/sign-up",
      "/agent-sign-up",
      "/agent-sign-in",
      "/create-listing",
      "/results",
      "/edit-listing",
      "/beyttylisting",
      "/admin-panel",
    ].includes(currentPath);
    setHideFooter(shouldHideFooter);
  }, [window.location.pathname]);

  return hideFooter ? null : <Footer />;
}

export default App;
