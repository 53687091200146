// utils/areas.js

export const areas = [
  {
    name: "Casablanca, Californie",
    pricePerSquareMeterVilla: 14600,
    pricePerSquareMeterOther: 16000,
    coordinates: [
      { lat: 33.5428254, lng: -7.6194315 },
      { lat: 33.5406435, lng: -7.6304253 },
      { lat: 33.546438, lng: -7.6319284 },
      { lat: 33.5458657, lng: -7.6339038 },
      { lat: 33.5456511, lng: -7.6373823 },
      { lat: 33.5407866, lng: -7.6407749 },
      { lat: 33.5348129, lng: -7.6392289 },
      { lat: 33.5345177, lng: -7.6421817 },
      { lat: 33.5370396, lng: -7.6443611 },
      { lat: 33.5318795, lng: -7.6479051 },
      { lat: 33.5299119, lng: -7.6419788 },
      { lat: 33.530377, lng: -7.6372978 },
      { lat: 33.5216835, lng: -7.6341629 },
      { lat: 33.5212542, lng: -7.6320156 },
      { lat: 33.5276403, lng: -7.6308595 },
      { lat: 33.5269069, lng: -7.6220344 },
      { lat: 33.5308421, lng: -7.6186632 },
      { lat: 33.5313608, lng: -7.6185559 },
      { lat: 33.5318258, lng: -7.6173749 },
      { lat: 33.5368161, lng: -7.6174486 },
      { lat: 33.541037, lng: -7.6181787 },
      { lat: 33.5428254, lng: -7.6193812 },
    ],
  },
  {
    name: "Casablanca, Vermont",
    pricePerSquareMeterVilla: 12700,
    pricePerSquareMeterOther: 12700,
    coordinates: [
      { lat: 33.5515526, lng: -7.6321992 },
      { lat: 33.546438, lng: -7.6318127 },
      { lat: 33.5406793, lng: -7.6305029 },
      { lat: 33.5449894, lng: -7.6093098 },
      { lat: 33.545669, lng: -7.6079355 },
      { lat: 33.5503544, lng: -7.6109846 },
      { lat: 33.5509446, lng: -7.6108772 },
      { lat: 33.5514989, lng: -7.6100613 },
      { lat: 33.5525719, lng: -7.6104478 },
      { lat: 33.5537879, lng: -7.6106625 },
      { lat: 33.5559695, lng: -7.6110705 },
      { lat: 33.5515705, lng: -7.6322636 },
    ],
  },
  {
    name: "Casablanca, Bd Fes",
    pricePerSquareMeterVilla: 14000,
    pricePerSquareMeterOther: 16000,
    coordinates: [
      { lat: 33.5429327, lng: -7.6194034 },
      { lat: 33.5444171, lng: -7.6121243 },
      { lat: 33.541502, lng: -7.6104709 },
      { lat: 33.5399818, lng: -7.6120169 },
      { lat: 33.5391412, lng: -7.6149157 },
      { lat: 33.5390696, lng: -7.6154954 },
      { lat: 33.5396956, lng: -7.6164402 },
      { lat: 33.540107, lng: -7.618158 },
      { lat: 33.5428791, lng: -7.6193604 },
    ],
  },
  {
    name: "Casablanca, Emeraude",
    pricePerSquareMeterVilla: 13000,
    pricePerSquareMeterOther: 14500,
    coordinates: [
      { lat: 33.5258336, lng: -7.6169608 },
      { lat: 33.5224706, lng: -7.6167034 },
      { lat: 33.5169786, lng: -7.6146654 },
      { lat: 33.5191075, lng: -7.6064891 },
      { lat: 33.5232935, lng: -7.5979111 },
      { lat: 33.5271036, lng: -7.5994772 },
      { lat: 33.5266207, lng: -7.6021372 },
      { lat: 33.5299477, lng: -7.6050119 },
      { lat: 33.5285167, lng: -7.6066422 },
      { lat: 33.5271752, lng: -7.6098386 },
      { lat: 33.5258694, lng: -7.6169823 },
    ],
  },
  {
    name: "Casablanca, Jnane californie",
    pricePerSquareMeterOther: 12900,
    coordinates: [
      { lat: 33.5259588, lng: -7.6168535 },
      { lat: 33.5274614, lng: -7.6093023 },
      { lat: 33.5304485, lng: -7.6103535 },
      { lat: 33.5295721, lng: -7.6123915 },
      { lat: 33.5300193, lng: -7.6126703 },
      { lat: 33.5292859, lng: -7.6170895 },
      { lat: 33.5259051, lng: -7.616875 },
    ],
  },
  {
    name: "Casablanca, Oasis",
    pricePerSquareMeterVilla: 18700,
    pricePerSquareMeterOther: 19000,
    coordinates: [
      { lat: 33.5512307, lng: -7.6332639 },
      { lat: 33.5635331, lng: -7.6277722 },
      { lat: 33.5638907, lng: -7.6289306 },
      { lat: 33.5637119, lng: -7.6308613 },
      { lat: 33.5636404, lng: -7.6339504 },
      { lat: 33.563998, lng: -7.6348943 },
      { lat: 33.5547714, lng: -7.6442475 },
      { lat: 33.5490132, lng: -7.6435611 },
      { lat: 33.5512307, lng: -7.6333069 },
    ],
  },
  {
    name: "Casablanca, Riviera",
    pricePerSquareMeterVilla: 18179,
    pricePerSquareMeterOther: 15600,
    coordinates: [
      { lat: 33.5548787, lng: -7.6442046 },
      { lat: 33.563998, lng: -7.6347227 },
      { lat: 33.5690757, lng: -7.6439952 },
      { lat: 33.5618523, lng: -7.6444244 },
      { lat: 33.5546641, lng: -7.6442475 },
    ],
  },
  {
    name: "Casablanca, CFC",
    pricePerSquareMeterOther: 25200,
    coordinates: [
      { lat: 33.5509088, lng: -7.6517988 },
      { lat: 33.5555939, lng: -7.6505974 },
      { lat: 33.5569529, lng: -7.6528285 },
      { lat: 33.5646774, lng: -7.6478515 },
      { lat: 33.5657502, lng: -7.6506832 },
      { lat: 33.5657502, lng: -7.6546734 },
      { lat: 33.5668587, lng: -7.6565612 },
      { lat: 33.567109, lng: -7.6583203 },
      { lat: 33.5696479, lng: -7.6623533 },
      { lat: 33.5699339, lng: -7.6676306 },
      { lat: 33.5621742, lng: -7.6682742 },
      { lat: 33.5611013, lng: -7.6692181 },
      { lat: 33.5595636, lng: -7.669261 },
      { lat: 33.5573463, lng: -7.6673302 },
      { lat: 33.5552721, lng: -7.6666438 },
      { lat: 33.5522679, lng: -7.6652279 },
      { lat: 33.5503366, lng: -7.6702049 },
      { lat: 33.546438, lng: -7.6699045 },
      { lat: 33.544435, lng: -7.6657857 },
      { lat: 33.5484767, lng: -7.6641124 },
      { lat: 33.5480833, lng: -7.6626965 },
      { lat: 33.5478329, lng: -7.6605084 },
      { lat: 33.5475825, lng: -7.6578054 },
      { lat: 33.5462592, lng: -7.6563038 },
      { lat: 33.5466526, lng: -7.6543301 },
      { lat: 33.5477256, lng: -7.6530859 },
      { lat: 33.5506942, lng: -7.6518417 },
    ],
  },
  {
    name: "Casablanca, Anfa Sup",
    pricePerSquareMeterVilla: 19100,
    pricePerSquareMeterOther: 19100,
    coordinates: [
      { lat: 33.5826269, lng: -7.6762142 },
      { lat: 33.5840927, lng: -7.6715376 },
      { lat: 33.5883113, lng: -7.6735112 },
      { lat: 33.5919578, lng: -7.6761284 },
      { lat: 33.5971054, lng: -7.6651448 },
      { lat: 33.5984995, lng: -7.6600028 },
      { lat: 33.5983922, lng: -7.6551909 },
      { lat: 33.5903491, lng: -7.6539926 },
      { lat: 33.5883471, lng: -7.6533919 },
      { lat: 33.5860591, lng: -7.6521063 },
      { lat: 33.5849865, lng: -7.654767 },
      { lat: 33.5834135, lng: -7.6581999 },
      { lat: 33.5771566, lng: -7.6735571 },
      { lat: 33.5818404, lng: -7.674973 },
      { lat: 33.5826269, lng: -7.6762172 },
    ],
  },
  {
    name: "Casablanca, Ain diab",
    pricePerSquareMeterVilla: 18400,
    coordinates: [
      { lat: 33.5907244, lng: -7.6753773 },
      { lat: 33.5884901, lng: -7.6735186 },
      { lat: 33.5841464, lng: -7.6719096 },
      { lat: 33.5827878, lng: -7.6762001 },
      { lat: 33.5829308, lng: -7.6769938 },
      { lat: 33.5825018, lng: -7.6810483 },
      { lat: 33.5828772, lng: -7.682979 },
      { lat: 33.5831632, lng: -7.6876986 },
      { lat: 33.5843966, lng: -7.6859824 },
      { lat: 33.5862736, lng: -7.6833437 },
      { lat: 33.5860233, lng: -7.6826358 },
      { lat: 33.5880611, lng: -7.6813058 },
      { lat: 33.5884365, lng: -7.6786028 },
      { lat: 33.5894553, lng: -7.6779806 },
      { lat: 33.5898754, lng: -7.6778555 },
      { lat: 33.5901748, lng: -7.6776838 },
      { lat: 33.5898575, lng: -7.6765573 },
      { lat: 33.5900631, lng: -7.6765989 },
      { lat: 33.5907244, lng: -7.6753761 },
    ],
  },
  {
    name: "Casablanca, Anfa Place",
    pricePerSquareMeterOther: 30000,
    coordinates: [
      { lat: 33.6004923, lng: -7.6639421 },
      { lat: 33.5983297, lng: -7.6678249 },
      { lat: 33.5971858, lng: -7.6667631 },
      { lat: 33.5969713, lng: -7.6659693 },
      { lat: 33.5975165, lng: -7.664178 },
      { lat: 33.5990714, lng: -7.6629553 },
      { lat: 33.6005191, lng: -7.6639099 },
    ],
  },
  {
    name: "Casablanca, Val fleurie",
    pricePerSquareMeterOther: 15000,
    coordinates: [
      { lat: 33.5708368, lng: -7.6428573 },
      { lat: 33.5703183, lng: -7.64201 },
      { lat: 33.5717754, lng: -7.6406263 },
      { lat: 33.5709798, lng: -7.6398862 },
      { lat: 33.5694691, lng: -7.6404654 },
      { lat: 33.5693618, lng: -7.6385669 },
      { lat: 33.5685572, lng: -7.6383309 },
      { lat: 33.5685483, lng: -7.6369365 },
      { lat: 33.5683695, lng: -7.6369258 },
      { lat: 33.568298, lng: -7.6365611 },
      { lat: 33.5770136, lng: -7.633786 },
      { lat: 33.5775857, lng: -7.6343438 },
      { lat: 33.5769511, lng: -7.6361847 },
      { lat: 33.5772639, lng: -7.6367211 },
      { lat: 33.5776304, lng: -7.6373434 },
      { lat: 33.5708457, lng: -7.6429007 },
    ],
  },
  {
    name: "Casablanca, Beausejour",
    pricePerSquareMeterVilla: 17500,
    pricePerSquareMeterOther: 13500,
    coordinates: [
      { lat: 33.5647489, lng: -7.6475573 },
      { lat: 33.5658575, lng: -7.6505613 },
      { lat: 33.5657859, lng: -7.6548529 },
      { lat: 33.5668945, lng: -7.6570415 },
      { lat: 33.5719721, lng: -7.6478148 },
      { lat: 33.56904, lng: -7.6442099 },
      { lat: 33.5644628, lng: -7.6474285 },
    ],
  },
  {
    name: "Casablanca, CIL",
    pricePerSquareMeterVilla: 21000,
    pricePerSquareMeterOther: 13780,
    coordinates: [
      { lat: 33.5719363, lng: -7.6479864 },
      { lat: 33.566823, lng: -7.6570415 },
      { lat: 33.5672163, lng: -7.6584148 },
      { lat: 33.5695406, lng: -7.6621914 },
      { lat: 33.5700769, lng: -7.6662683 },
      { lat: 33.5711497, lng: -7.6662254 },
      { lat: 33.5725084, lng: -7.6636076 },
      { lat: 33.5738314, lng: -7.6647234 },
      { lat: 33.574618, lng: -7.66541 },
      { lat: 33.5755834, lng: -7.663393 },
      { lat: 33.5749041, lng: -7.6628351 },
      { lat: 33.5758337, lng: -7.6611292 },
      { lat: 33.5775231, lng: -7.6578999 },
      { lat: 33.5780773, lng: -7.6563334 },
      { lat: 33.578712, lng: -7.6553679 },
      { lat: 33.5719721, lng: -7.6479864 },
    ],
  },
  {
    name: "Casablanca, Marina",
    pricePerSquareMeterOther: 25300,
    coordinates: [
      { lat: 33.6107951, lng: -7.6261425 },
      { lat: 33.6088562, lng: -7.6265073 },
      { lat: 33.6071406, lng: -7.6222908 },
      { lat: 33.6049335, lng: -7.6194584 },
      { lat: 33.607355, lng: -7.6168406 },
      { lat: 33.6078822, lng: -7.6203811 },
      { lat: 33.6084809, lng: -7.6207995 },
      { lat: 33.6091421, lng: -7.6212394 },
      { lat: 33.6102858, lng: -7.6236641 },
      { lat: 33.6107415, lng: -7.6249516 },
      { lat: 33.610813, lng: -7.6261318 },
    ],
  },
  {
    name: "Casablanca, Bourgogne Est",
    pricePerSquareMeterVilla: 15000,
    pricePerSquareMeterOther: 15000,
    coordinates: [
      { lat: 33.6035931, lng: -7.6396823 },
      { lat: 33.600251, lng: -7.6390386 },
      { lat: 33.6006799, lng: -7.6368928 },
      { lat: 33.6007693, lng: -7.6343393 },
      { lat: 33.6005727, lng: -7.6326227 },
      { lat: 33.601645, lng: -7.6328588 },
      { lat: 33.602217, lng: -7.631464 },
      { lat: 33.6063453, lng: -7.6330519 },
      { lat: 33.6036646, lng: -7.6397038 },
    ],
  },
  {
    name: "Casablanca, Errahma",
    pricePerSquareMeterOther: 7300,
    coordinates: [
      { lat: 33.5424677, lng: -7.7319288 },
      { lat: 33.5358145, lng: -7.745018 },
      { lat: 33.5323445, lng: -7.7487516 },
      { lat: 33.5299477, lng: -7.7529571 },
      { lat: 33.5386404, lng: -7.7580657 },
      { lat: 33.5364226, lng: -7.7599975 },
      { lat: 33.52705, lng: -7.7551895 },
      { lat: 33.5243668, lng: -7.7604698 },
      { lat: 33.5182488, lng: -7.7581945 },
      { lat: 33.5239732, lng: -7.7484925 },
      { lat: 33.5282305, lng: -7.7343258 },
      { lat: 33.534169, lng: -7.7241516 },
      { lat: 33.5405004, lng: -7.7145354 },
      { lat: 33.5480475, lng: -7.7241516 },
      { lat: 33.5424499, lng: -7.731961 },
    ],
  },
  {
    name: "Casablanca, Oulfa",
    pricePerSquareMeterVilla: 8500,
    pricePerSquareMeterOther: 9400,
    coordinates: [
      { lat: 33.5554867, lng: -7.6761173 },
      { lat: 33.5554509, lng: -7.6747865 },
      { lat: 33.5565953, lng: -7.6746147 },
      { lat: 33.5570245, lng: -7.673885 },
      { lat: 33.5519102, lng: -7.6741855 },
      { lat: 33.5517672, lng: -7.673241 },
      { lat: 33.5506584, lng: -7.6733269 },
      { lat: 33.5500147, lng: -7.6713092 },
      { lat: 33.5459015, lng: -7.6693344 },
      { lat: 33.5410727, lng: -7.6724481 },
      { lat: 33.5395346, lng: -7.6768041 },
      { lat: 33.5386046, lng: -7.6801097 },
      { lat: 33.5392485, lng: -7.6820415 },
      { lat: 33.5420027, lng: -7.6841021 },
      { lat: 33.5529832, lng: -7.6901981 },
      { lat: 33.5536627, lng: -7.6891678 },
      { lat: 33.5564523, lng: -7.6906703 },
      { lat: 33.558598, lng: -7.6933319 },
      { lat: 33.556488, lng: -7.6965516 },
      { lat: 33.5593848, lng: -7.6992991 },
      { lat: 33.5620311, lng: -7.6959506 },
      { lat: 33.5637834, lng: -7.6966804 },
      { lat: 33.5665011, lng: -7.6986552 },
      { lat: 33.5682891, lng: -7.6960794 },
      { lat: 33.5701485, lng: -7.6974531 },
      { lat: 33.5707563, lng: -7.6939759 },
      { lat: 33.5687182, lng: -7.6928168 },
      { lat: 33.5682891, lng: -7.6935895 },
      { lat: 33.5665726, lng: -7.6921728 },
      { lat: 33.5702557, lng: -7.6863774 },
      { lat: 33.566966, lng: -7.6838445 },
      { lat: 33.5641052, lng: -7.6830289 },
      { lat: 33.5609583, lng: -7.6849178 },
      { lat: 33.5600285, lng: -7.6852612 },
      { lat: 33.5613874, lng: -7.68114 },
      { lat: 33.5581689, lng: -7.6797233 },
      { lat: 33.5588126, lng: -7.6777057 },
      { lat: 33.5555582, lng: -7.6760743 },
    ],
  },
  {
    name: "Casablanca, Hay Hassani",
    pricePerSquareMeterOther: 9600,
    coordinates: [
      { lat: 33.5720078, lng: -7.6873648 },
      { lat: 33.5688254, lng: -7.6850036 },
      { lat: 33.5712569, lng: -7.6791653 },
      { lat: 33.5679315, lng: -7.6771905 },
      { lat: 33.5713284, lng: -7.6691198 },
      { lat: 33.5770136, lng: -7.6734127 },
      { lat: 33.5746538, lng: -7.6816552 },
      { lat: 33.5721866, lng: -7.6873648 },
    ],
  },
  {
    name: "Casablanca, Polo",
    pricePerSquareMeterVilla: 12800,
    pricePerSquareMeterOther: 16000,
    coordinates: [
      { lat: 33.5604934, lng: -7.6259694 },
      { lat: 33.5572569, lng: -7.6243595 },
      { lat: 33.5537521, lng: -7.6234151 },
      { lat: 33.5548429, lng: -7.6172977 },
      { lat: 33.5561483, lng: -7.6109871 },
      { lat: 33.5611371, lng: -7.6122535 },
      { lat: 33.567556, lng: -7.6111802 },
      { lat: 33.5643377, lng: -7.6214189 },
      { lat: 33.5634616, lng: -7.6244475 },
      { lat: 33.5623709, lng: -7.6267207 },
      { lat: 33.5604219, lng: -7.625905 },
    ],
  },
  {
    name: "Casablanca, Hermitage",
    pricePerSquareMeterVilla: 15000,
    pricePerSquareMeterOther: 15000,
    coordinates: [
      { lat: 33.5690757, lng: -7.6116739 },
      { lat: 33.5678957, lng: -7.6107295 },
      { lat: 33.5660899, lng: -7.6153659 },
      { lat: 33.5646595, lng: -7.6206247 },
      { lat: 33.5662687, lng: -7.6205603 },
      { lat: 33.566376, lng: -7.6263772 },
      { lat: 33.5685394, lng: -7.6260767 },
      { lat: 33.5677348, lng: -7.6155376 },
      { lat: 33.5688433, lng: -7.6157522 },
      { lat: 33.5691115, lng: -7.611631 },
    ],
  },
  {
    name: "Casablanca, Les Hopitaux",
    pricePerSquareMeterVilla: 23000,
    pricePerSquareMeterOther: 13800,
    coordinates: [
      { lat: 33.5692188, lng: -7.6118456 },
      { lat: 33.5740459, lng: -7.6152585 },
      { lat: 33.5830381, lng: -7.6151083 },
      { lat: 33.5832705, lng: -7.6233507 },
      { lat: 33.5774784, lng: -7.625626 },
      { lat: 33.5693797, lng: -7.6260123 },
      { lat: 33.5694333, lng: -7.6232457 },
      { lat: 33.569183, lng: -7.6204744 },
      { lat: 33.5689327, lng: -7.6160742 },
      { lat: 33.5691651, lng: -7.6118456 },
    ],
  },
  {
    name: "Casablanca, Gauthier 1",
    pricePerSquareMeterOther: 26900,
    coordinates: [
      { lat: 33.584343, lng: -7.6246386 },
      { lat: 33.5860233, lng: -7.6220628 },
      { lat: 33.5901703, lng: -7.6195944 },
      { lat: 33.5890085, lng: -7.6184997 },
      { lat: 33.5895447, lng: -7.6171474 },
      { lat: 33.5909747, lng: -7.6164223 },
      { lat: 33.5922616, lng: -7.6171474 },
      { lat: 33.5940311, lng: -7.6216335 },
      { lat: 33.593066, lng: -7.6240161 },
      { lat: 33.5897592, lng: -7.6258191 },
      { lat: 33.5868813, lng: -7.628309 },
      { lat: 33.5843609, lng: -7.6246386 },
    ],
  },
  {
    name: "Casablanca, Gauthier 2",
    pricePerSquareMeterVilla: 26000,
    pricePerSquareMeterOther: 17600,
    coordinates: [
      { lat: 33.5894732, lng: -7.6259909 },
      { lat: 33.5856301, lng: -7.6296184 },
      { lat: 33.5880432, lng: -7.6332022 },
      { lat: 33.5909747, lng: -7.6359505 },
      { lat: 33.594049, lng: -7.6294252 },
      { lat: 33.5925655, lng: -7.6280515 },
      { lat: 33.5910819, lng: -7.6272573 },
      { lat: 33.5894196, lng: -7.6260338 },
    ],
  },
  {
    name: "Casablanca, Lusitania",
    pricePerSquareMeterVilla: 18000,
    pricePerSquareMeterOther: 18000,
    coordinates: [
      { lat: 33.594192, lng: -7.6291895 },
      { lat: 33.5924136, lng: -7.6277299 },
      { lat: 33.5906261, lng: -7.6267962 },
      { lat: 33.589938, lng: -7.6259268 },
      { lat: 33.5920293, lng: -7.624596 },
      { lat: 33.5932, lng: -7.623986 },
      { lat: 33.5940222, lng: -7.6216893 },
      { lat: 33.594907, lng: -7.623716 },
      { lat: 33.5954074, lng: -7.6248751 },
      { lat: 33.5954342, lng: -7.6256478 },
      { lat: 33.5948891, lng: -7.6275695 },
      { lat: 33.5942099, lng: -7.6292002 },
    ],
  },
  {
    name: "Casablanca, Ziraoui",
    pricePerSquareMeterOther: 16700,
    coordinates: [
      { lat: 33.5911892, lng: -7.6360682 },
      { lat: 33.5942099, lng: -7.6294249 },
      { lat: 33.5963279, lng: -7.6308201 },
      { lat: 33.5929766, lng: -7.6371951 },
      { lat: 33.5911981, lng: -7.636036 },
    ],
  },
  {
    name: "Casablanca, Bourgogne Est",
    pricePerSquareMeterOther: 15900,
    coordinates: [
      { lat: 33.5929945, lng: -7.637281 },
      { lat: 33.5964083, lng: -7.6308523 },
      { lat: 33.5976684, lng: -7.6316358 },
      { lat: 33.598848, lng: -7.6323119 },
      { lat: 33.6017076, lng: -7.6329451 },
      { lat: 33.6009302, lng: -7.6347803 },
      { lat: 33.6007604, lng: -7.6371182 },
      { lat: 33.6004297, lng: -7.6389552 },
      { lat: 33.5943797, lng: -7.637882 },
      { lat: 33.5929676, lng: -7.637281 },
    ],
  },
  {
    name: "Casablanca, Bourgogne Ouest",
    pricePerSquareMeterOther: 17200,
    coordinates: [
      { lat: 33.5928693, lng: -7.6373668 },
      { lat: 33.5918684, lng: -7.6413378 },
      { lat: 33.5962653, lng: -7.6480026 },
      { lat: 33.5973288, lng: -7.6486723 },
      { lat: 33.5998578, lng: -7.6489192 },
      { lat: 33.6031016, lng: -7.6472235 },
      { lat: 33.6026369, lng: -7.645186 },
      { lat: 33.601645, lng: -7.6425764 },
      { lat: 33.6005012, lng: -7.6404943 },
      { lat: 33.6003314, lng: -7.6392064 },
      { lat: 33.5957649, lng: -7.6381078 },
      { lat: 33.59278, lng: -7.6373136 },
    ],
  },
  {
    name: "Casablanca, Triangle d'or",
    pricePerSquareMeterOther: 18600,
    coordinates: [
      { lat: 33.5939954, lng: -7.6448907 },
      { lat: 33.5906351, lng: -7.6453414 },
      { lat: 33.591779, lng: -7.6417568 },
      { lat: 33.5940669, lng: -7.6448477 },
    ],
  },
  {
    name: "Casablanca, Racine",
    pricePerSquareMeterOther: 17600,
    coordinates: [
      { lat: 33.5961581, lng: -7.648003 },
      { lat: 33.594049, lng: -7.6448907 },
      { lat: 33.5907602, lng: -7.64532 },
      { lat: 33.5916182, lng: -7.641671 },
      { lat: 33.5927621, lng: -7.6372707 },
      { lat: 33.5911892, lng: -7.636176 },
      { lat: 33.5889548, lng: -7.641349 },
      { lat: 33.5879538, lng: -7.6447619 },
      { lat: 33.5871673, lng: -7.6487758 },
      { lat: 33.5886331, lng: -7.6513086 },
      { lat: 33.5922437, lng: -7.6539702 },
      { lat: 33.5935486, lng: -7.6542707 },
      { lat: 33.596176, lng: -7.648003 },
    ],
  },
  {
    name: "Casablanca, Belvedere",
    pricePerSquareMeterVilla: 12500,
    pricePerSquareMeterOther: 11600,
    coordinates: [
      { lat: 33.6050764, lng: -7.5796953 },
      { lat: 33.5984459, lng: -7.5771197 },
      { lat: 33.5981063, lng: -7.582593 },
      { lat: 33.5909211, lng: -7.5904918 },
      { lat: 33.5870958, lng: -7.59457 },
      { lat: 33.5868813, lng: -7.5934538 },
      { lat: 33.5838961, lng: -7.5966091 },
      { lat: 33.5863808, lng: -7.6002365 },
      { lat: 33.5870601, lng: -7.6007087 },
      { lat: 33.5893481, lng: -7.6021468 },
      { lat: 33.5900273, lng: -7.6035481 },
      { lat: 33.5961224, lng: -7.6035205 },
      { lat: 33.5975701, lng: -7.6030483 },
      { lat: 33.5989642, lng: -7.595772 },
      { lat: 33.6051301, lng: -7.5797383 },
    ],
  },
  {
    name: "Casablanca, Hay El Yacout",
    pricePerSquareMeterVilla: 16500,
    pricePerSquareMeterOther: 12400,
    coordinates: [
      { lat: 33.553913, lng: -7.5920322 },
      { lat: 33.5491205, lng: -7.5930624 },
      { lat: 33.5507479, lng: -7.6026569 },
      { lat: 33.5485304, lng: -7.6023135 },
      { lat: 33.5443456, lng: -7.6023349 },
      { lat: 33.539642, lng: -7.5995661 },
      { lat: 33.5413231, lng: -7.5935776 },
      { lat: 33.5429864, lng: -7.5852924 },
      { lat: 33.5443456, lng: -7.5825021 },
      { lat: 33.5486019, lng: -7.5861725 },
      { lat: 33.5539488, lng: -7.5920107 },
    ],
  },
  {
    name: "Casablanca, Hay Rmila",
    pricePerSquareMeterOther: 9300,
    coordinates: [
      { lat: 33.5567384, lng: -7.6063003 },
      { lat: 33.5535018, lng: -7.6042398 },
      { lat: 33.5542886, lng: -7.6025012 },
      { lat: 33.5541992, lng: -7.601106 },
      { lat: 33.5572212, lng: -7.5946883 },
      { lat: 33.5589914, lng: -7.5953966 },
      { lat: 33.5567384, lng: -7.6063433 },
    ],
  },
  {
    name: "Casablanca, Bachkou",
    pricePerSquareMeterOther: 13580,
    coordinates: [
      { lat: 33.5518566, lng: -7.6319499 },
      { lat: 33.5555403, lng: -7.6305548 },
      { lat: 33.5571318, lng: -7.6245019 },
      { lat: 33.5552184, lng: -7.6237507 },
      { lat: 33.55377, lng: -7.6236433 },
      { lat: 33.5518029, lng: -7.6319929 },
    ],
  },
  {
    name: "Casablanca, Franceville",
    pricePerSquareMeterVilla: 17900,
    pricePerSquareMeterOther: 17000,
    coordinates: [
      { lat: 33.5655893, lng: -7.6373589 },
      { lat: 33.564141, lng: -7.6350622 },
      { lat: 33.5636582, lng: -7.6330661 },
      { lat: 33.563837, lng: -7.6309841 },
      { lat: 33.5639264, lng: -7.6288376 },
      { lat: 33.5636046, lng: -7.6279362 },
      { lat: 33.5623351, lng: -7.6269488 },
      { lat: 33.5634973, lng: -7.6236648 },
      { lat: 33.5663044, lng: -7.6234931 },
      { lat: 33.566376, lng: -7.6265625 },
      { lat: 33.5694333, lng: -7.6260688 },
      { lat: 33.5696479, lng: -7.6296747 },
      { lat: 33.5678957, lng: -7.6296318 },
      { lat: 33.5670733, lng: -7.6310914 },
      { lat: 33.5656787, lng: -7.6373374 },
    ],
  },
  {
    name: "Casablanca, Les princesses",
    pricePerSquareMeterVilla: 25000,
    pricePerSquareMeterOther: 19200,
    coordinates: [
      { lat: 33.5720972, lng: -7.6477904 },
      { lat: 33.5749935, lng: -7.6427249 },
      { lat: 33.5806784, lng: -7.6476187 },
      { lat: 33.579284, lng: -7.650173 },
      { lat: 33.5783544, lng: -7.6489924 },
      { lat: 33.5767633, lng: -7.6504305 },
      { lat: 33.575798, lng: -7.6518901 },
      { lat: 33.5721509, lng: -7.6478334 },
    ],
  },
  {
    name: "Casablanca, Longchamps",
    pricePerSquareMeterVilla: 19000,
    coordinates: [
      { lat: 33.5711944, lng: -7.6661413 },
      { lat: 33.5701485, lng: -7.6662486 },
      { lat: 33.570211, lng: -7.6682877 },
      { lat: 33.5726336, lng: -7.6697365 },
      { lat: 33.5736616, lng: -7.6708419 },
      { lat: 33.5771745, lng: -7.6733317 },
      { lat: 33.5798918, lng: -7.6672574 },
      { lat: 33.5819297, lng: -7.6620309 },
      { lat: 33.5821264, lng: -7.6610757 },
      { lat: 33.5801689, lng: -7.6570727 },
      { lat: 33.5789801, lng: -7.6556238 },
      { lat: 33.5783365, lng: -7.6560424 },
      { lat: 33.5774874, lng: -7.6579634 },
      { lat: 33.5759052, lng: -7.6610543 },
      { lat: 33.5725084, lng: -7.6671289 },
      { lat: 33.5712927, lng: -7.6661845 },
    ],
  },
  {
    name: "Casablanca, Maarif",
    pricePerSquareMeterVilla: 23000,
    pricePerSquareMeterOther: 16000,
    coordinates: [
      { lat: 33.5808035, lng: -7.6475979 },
      { lat: 33.574922, lng: -7.6427255 },
      { lat: 33.5777287, lng: -7.6375742 },
      { lat: 33.5793734, lng: -7.6359429 },
      { lat: 33.5855943, lng: -7.629568 },
      { lat: 33.5870958, lng: -7.6321437 },
      { lat: 33.5909568, lng: -7.6360931 },
      { lat: 33.5880611, lng: -7.6440778 },
      { lat: 33.587793, lng: -7.6448505 },
      { lat: 33.5859876, lng: -7.6462886 },
      { lat: 33.5855228, lng: -7.6454085 },
      { lat: 33.5848435, lng: -7.6459452 },
      { lat: 33.5818046, lng: -7.6441636 },
      { lat: 33.5808393, lng: -7.647555 },
    ],
  },
  {
    name: "Casablanca, Hay El Farah",
    pricePerSquareMeterOther: 10700,
    coordinates: [
      { lat: 33.5645344, lng: -7.5952304 },
      { lat: 33.5626391, lng: -7.5949299 },
      { lat: 33.5629251, lng: -7.5931269 },
      { lat: 33.561459, lng: -7.5920108 },
      { lat: 33.5600285, lng: -7.591882 },
      { lat: 33.5617629, lng: -7.585429 },
      { lat: 33.5625854, lng: -7.5853861 },
      { lat: 33.5629073, lng: -7.5848709 },
      { lat: 33.5653389, lng: -7.5860085 },
      { lat: 33.5701663, lng: -7.5782171 },
      { lat: 33.5724905, lng: -7.5814367 },
      { lat: 33.5724727, lng: -7.5830465 },
      { lat: 33.5715966, lng: -7.5871247 },
      { lat: 33.5717039, lng: -7.5882623 },
      { lat: 33.5723654, lng: -7.5894857 },
      { lat: 33.5729554, lng: -7.590516 },
      { lat: 33.5723118, lng: -7.5953454 },
      { lat: 33.57199, lng: -7.5971055 },
      { lat: 33.5646238, lng: -7.595281 },
    ],
  },
];
