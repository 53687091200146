import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import OAuth from "../components/OAuth";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";



export default function SignIn() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { email, password } = formData;
  const navigate = useNavigate();
  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }
  async function onSubmit(e) {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    setLoading(true);

    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        navigate("/");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("Firebase authentication error:", error);
      switch (error.code) {
        case "auth/user-not-found":
          setEmailError("User not found. Please check your email address.");
          break;
        case "auth/invalid-email":
          setEmailError("Invalid email address. Please enter a valid email.");
          break;
        case "auth/wrong-password":
          setPasswordError("Incorrect password. Please check your password.");
          break;
        default:
          setPasswordError("Incorrect email or password. Please try again.");
      }
    }
  }
  return (
    <section>
      <Helmet>
        <title>Sign In - Access Your Beytty Account</title>
        <meta
          name="description"
          content="Sign in to your Beytty account to manage your real estate activities. Access your listings, connect with agents, and continue your property search or sale."
        />
        <meta
          name="keywords"
          content="sign in, login, real estate account, access Beytty, property management, real estate activities, Morocco real estate"
        />
        <meta
          property="og:title"
          content="Sign In - Access Your Beytty Account"
        />
        <meta
          property="og:description"
          content="Sign in to your Beytty account to manage your real estate activities. Access your listings, connect with agents, and continue your property search or sale."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.beytty.com/sign-in" />
        <meta property="og:image" content="/apple-touch-icon.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Sign In - Access Your Beytty Account"
        />
        <meta
          name="twitter:description"
          content="Sign in to your Beytty account to manage your real estate activities. Access your listings, connect with agents, and continue your property search or sale."
        />
        <meta name="twitter:image" content="/apple-touch-icon.png"/>

        <title>Connexion - Accédez à Votre Compte Beytty</title>
        <meta
          name="description"
          content="Connectez-vous à votre compte Beytty pour gérer vos activités immobilières. Accédez à vos annonces, connectez-vous avec des agents, et poursuivez votre recherche ou vente de propriété."
        />
        <meta
          name="keywords"
          content="connexion, se connecter, compte immobilier, accéder à Beytty, gestion de propriété, activités immobilières, immobilier au Maroc"
        />
        <meta
          property="og:title"
          content="Connexion - Accédez à Votre Compte Beytty"
        />
        <meta
          property="og:description"
          content="Connectez-vous à votre compte Beytty pour gérer vos activités immobilières. Accédez à vos annonces, connectez-vous avec des agents, et poursuivez votre recherche ou vente de propriété."
        />
        <meta
          name="twitter:title"
          content="Connexion - Accédez à Votre Compte Beytty"
        />
        <meta
          name="twitter:description"
          content="Connectez-vous à votre compte Beytty pour gérer vos activités immobilières. Accédez à vos annonces, connectez-vous avec des agents, et poursuivez votre recherche ou vente de propriété."
        />
      </Helmet>

      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className=" hidden md:grid w-full h-48 grid-cols-1 grid-rows-1 overflow-hidden bg-cover md:h-screen bg-blue-100 ">
          <div className='h-full  col-span-1 col-start-1 row-span-1 row-start-1"'>
            <img
              src={process.env.PUBLIC_URL + "/img007.png"}
              alt="key"
              className=" object-cover h-full bg-blend-overlay"
            />
          </div>
          <div className="h-full col-span-1 col-start-1 row-span-1 row-start-1 text-center  ">
            <a href="/">
              <img
                src={process.env.PUBLIC_URL + "/LogoBeytty.png"}
                className="mt-12 w-36 md:w-60 text-center hover:opacity-70 transition ease-in-out duration-200 inline-block"
              />
            </a>
          </div>
        </div>
        {loading ? (
          <div className="items-center flex w-full justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <div className="flex items-center overflow-y-auto bg-white md:my-0 h-screen md:shadow-md shadow-black/30">
            <form
              onSubmit={onSubmit}
              className="max-w-md px-8 md:px-4 w-[28rem] h-screen md:h-auto mx-auto"
            >
              <a href="/">
                <img
                  src={process.env.PUBLIC_URL + "/LogoBeytty.png"}
                  className="md:hidden mt-20 mb-8 w-40 text-center mx-auto flex hover:opacity-70 transition ease-in-out duration-200 "
                />
              </a>
              <div className=" justify-start text-center md:text-left text-xl md:text-4xl md:py-8 text-black">
                {t("signin")}{" "}
              </div>
              <input
                type="email"
                id="email"
                value={email}
                onChange={onChange}
                placeholder="Email"
                className="w-full mt-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
              />
              {emailError && (
                <p className="text-red-500 text-sm mb-2">{emailError}</p>
              )}
              <div className="relative mt-6 mb-6">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={onChange}
                  placeholder={t("password")}
                  className="w-full px-4  py-2 text-md  shadow-md color-grey-700 bg-white border-gray-300 rounded transition ease-in-out"
                />
                {showPassword ? (
                  <AiFillEye
                    className="absolute right-3 top-3 text-md cursor-pointer"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="absolute right-3 top-3 text-xl cursor-pointer"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                )}
                {passwordError && (
                  <p className="text-red-500 text-sm mb-2">{passwordError}</p>
                )}
              </div>

              <div className="flex mb-6 justify-between whitespace-nowrap text-sm sm:text-md">
                <p className="justify-end w-full mx-auto flex">
                  <Link
                    to="/forgot-password"
                    className="text-black hover:text-black transition duration-200 ease-in-out underline"
                  >
                    {" "}
                    {t("forgotpassword")}{" "}
                  </Link>
                </p>
              </div>
              <button
                className="w-full uppercase bg-custom-red rounded text-white px-7 py-3"
                type="submit"
              >
                {t("signin")}{" "}
              </button>
              <p className=" mx-auto flex justify-center">
                <Link
                  to="/sign-up"
                  className="text-black hover:text-red-700 transition duration-200 ease-in-out underline mt-4"
                >
                  {" "}
                  {t("createnewaccount")}{" "}
                </Link>
              </p>
              <div className="flex items-center my-4 before:border-t before:flex-1 before:border-gray-300 after:border-t after:flex-1 after:border-gray-300">
                <p
                  className="text-center
        font-semibold mx-4"
                >
                  {t("or")}
                </p>
              </div>
              <OAuth />
            </form>
          </div>
        )}
      </div>
    </section>
  );
}
