import React, { useState } from "react";

const StarSelector = ({ onChange }) => {
  const [rating, setRating] = useState(0);

  const handleClick = (value) => {
    setRating(value);
    onChange(value);
  };

  return (
    <div>
      {[...Array(5)].map((_, index) => {
        const starValue = index + 1;
        return (
          <span
            key={index}
            className={
              starValue <= rating ? "text-red-700" : "text-gray-300"
            }
            onClick={() => handleClick(starValue)}
            style={{ cursor: "pointer" }}
          >
            &#9733;
          </span>
        );
      })}
    </div>
  );
};

export default StarSelector;
