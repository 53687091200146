import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import OAuth from "../components/OAuth";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { db } from "../firebase";
import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
  getDocs,
  query,
  collection,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Helmet } from "react-helmet";




export default function SignUp() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [termsError, setTermsError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+212");
  const [passwordError, setPasswordError] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { firstName, lastName, email, password, } = formData;

  const handleSignUpAsAgentClick = () => {
    // Navigate to the AgentSignUp page when clicked
    navigate("/agent-sign-up");
  };

  const handlePhoneChange = (value) => {
    if (!value.startsWith("+")) {
      value = `+${value}`;
    }
    setPhoneNumber(value);
  };

  const onCountryCodeChange = (e) => {
    setSelectedCountryCode(e.target.value);
  };

  const navigate = useNavigate();

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  
async function onSubmit(e) {
  setLoading(true);
  e.preventDefault();
  setEmailError("");
  setLastNameError("");
  setFirstNameError("");
  setPhoneError("");
  setPasswordError("");
  setTermsError("");

  // Check for errors in the first step fields
  if (!firstName.trim()) {
    setLoading(false);
    setFirstNameError("Please enter your first name.");
    return;
  }

  if (!lastName.trim()) {
    setLoading(false);
    setLastNameError("Please enter your last name.");
    return;
  }

  if (!phoneNumber.trim()) {
    setLoading(false);
    setPhoneError("Please enter your phone number.");
    return;
  }

  if (!email.trim()) {
    setLoading(false);
    setEmailError("Please enter your email address.");
    return;
  }

  if (!password.trim()) {
    setLoading(false);
    setPasswordError("Please enter your password.");
    return;
  }

  const domainRegex = /@([a-zA-Z0-9.-]+)$/;
  const match = email.match(domainRegex);

  if (!match) {
    setLoading(false);
    setEmailError("Invalid email address. Please enter a valid email.");
    return;
  }

  const emailDomain = match[1].toLowerCase();
  const allowedDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "icloud.com",
    "aol.com",
    "protonmail.com",
    "mail.com",
    "zoho.com",
    "yandex.com",
    "gmx.com",
  ];

  if (!allowedDomains.includes(emailDomain)) {
    setLoading(false);
    setEmailError(
      "Invalid email domain. Please use a supported email provider."
    );
    return;
  }

  // Validate phone number
  const phoneNumberObject = parsePhoneNumberFromString(phoneNumber, "MA"); // Replace 'MA' with the default country code if different
  if (!phoneNumberObject || !phoneNumberObject.isValid()) {
    setLoading(false);
    setPhoneError("Invalid phone number. Please enter a valid phone number.");
    return;
  }

  if (!agreeToTerms) {
    setLoading(false);
    setTermsError("You must agree to the terms and services.");
    return;
  }

  try {
    // Query for the "users" collection
    const usersCollection = collection(db, "users");
    const usersQuery = query(
      usersCollection,
      where("phoneNumber", "==", phoneNumber)
    );
    const usersSnapshot = await getDocs(usersQuery);

    // Query for the "agents" collection
    const agentsCollection = collection(db, "agents");
    const agentsQuery = query(
      agentsCollection,
      where("phoneNumber", "==", phoneNumber)
    );
    const agentsSnapshot = await getDocs(agentsQuery);

    // Check if either collection contains the phone number
    if (!usersSnapshot.empty || !agentsSnapshot.empty) {
      setLoading(false);
      setPhoneError(t("phonealreadyexists")); 
      return;
    }

    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    const initialProfilePicture = process.env.PUBLIC_URL + "/anonym.png";

    await updateProfile(auth.currentUser, {
      displayName: `${firstName} ${lastName}`,
      photoURL: initialProfilePicture,
    });

    const formDataCopy = { ...formData };
    delete formDataCopy.password;
    formDataCopy.timestamp = serverTimestamp();
    formDataCopy.phoneNumber = phoneNumber;

    await sendEmailVerification(user);
    await setDoc(doc(db, "users", user.uid), formDataCopy);

    navigate("/email-verification");
  } catch (error) {
    console.error("Firebase authentication error:", error);

    switch (error.code) {
      case "auth/email-already-in-use":
        setEmailError("This email is already in use. Please choose another.");
        break;
      case "auth/invalid-email":
        setEmailError("Invalid email address. Please enter a valid email.");
        break;
      case "auth/weak-password":
        setPasswordError(
          "Password is too weak. Please choose a stronger password."
        );
        break;
      default:
        setEmailError("Something went wrong with the registration");
    }
  } finally {
    setLoading(false);
  }
}

  const handleOpenTerms = () => {
    setOpenTerms(true);
  };

  const handleCloseTerms = () => {
    setOpenTerms(false);
  };

  return (
    <section>
      <Helmet>
        <title>Sign Up - Join Beytty and Start Your Real Estate Journey</title>
        <meta
          name="description"
          content="Create an account on Beytty to access a wide range of real estate services. Sign up to connect with agents, explore listings, and manage your property search or sale."
        />
        <meta
          name="keywords"
          content="signup, create account, real estate registration, join Beytty, property search, real estate services, Morocco real estate"
        />
        <meta
          property="og:title"
          content="Sign Up - Join Beytty and Start Your Real Estate Journey"
        />
        <meta
          property="og:description"
          content="Create an account on Beytty to access a wide range of real estate services. Sign up to connect with agents, explore listings, and manage your property search or sale."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.beytty.com/sign-up" />
        <meta property="og:image" content="/apple-touch-icon.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Sign Up - Join Beytty and Start Your Real Estate Journey"
        />
        <meta
          name="twitter:description"
          content="Create an account on Beytty to access a wide range of real estate services. Sign up to connect with agents, explore listings, and manage your property search or sale."
        />
        <meta name="twitter:image" content="/apple-touch-icon.png" />

        <title>
          Inscription - Rejoignez Beytty et Commencez Votre Aventure Immobilière
        </title>
        <meta
          name="description"
          content="Créez un compte sur Beytty pour accéder à une large gamme de services immobiliers. Inscrivez-vous pour vous connecter avec des agents, explorer les annonces, et gérer votre recherche ou vente de propriété."
        />
        <meta
          name="keywords"
          content="inscription, créer un compte, enregistrement immobilier, rejoindre Beytty, recherche de propriété, services immobiliers, immobilier au Maroc"
        />
        <meta
          property="og:title"
          content="Inscription - Rejoignez Beytty et Commencez Votre Aventure Immobilière"
        />
        <meta
          property="og:description"
          content="Créez un compte sur Beytty pour accéder à une large gamme de services immobiliers. Inscrivez-vous pour vous connecter avec des agents, explorer les annonces, et gérer votre recherche ou vente de propriété."
        />
        <meta
          name="twitter:title"
          content="Inscription - Rejoignez Beytty et Commencez Votre Aventure Immobilière"
        />
        <meta
          name="twitter:description"
          content="Créez un compte sur Beytty pour accéder à une large gamme de services immobiliers. Inscrivez-vous pour vous connecter avec des agents, explorer les annonces, et gérer votre recherche ou vente de propriété."
        />
      </Helmet>

      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="hidden md:grid w-full h-48 grid-cols-1 grid-rows-1 overflow-hidden bg-cover md:h-screen bg-blue-100 ">
          <div className='h-full  col-span-1 col-start-1 row-span-1 row-start-1"'>
            <img
              src={process.env.PUBLIC_URL + "/img007.png"}
              alt="key"
              className=" object-cover h-full bg-blend-overlay"
            />
          </div>
          <div className="h-full col-span-1 col-start-1 row-span-1 row-start-1 text-center  ">
            <a href="/">
              <img
                src={process.env.PUBLIC_URL + "/LogoBeytty.png"}
                className="mt-12 w-36 md:w-60 text-center hover:opacity-70 transition ease-in-out duration-200 inline-block"
              />
            </a>
          </div>
        </div>
        {loading ? (
          <div className="items-center flex w-full justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <div className="flex items-center overflow-y-auto py-12 bg-white md:my-0 md:h-screen md:shadow-md shadow-black/30">
            <form
              onSubmit={onSubmit}
              className="max-w-md px-8 md:px-4 w-[28rem]  mx-auto"
            >
              <a href="/">
                <img
                  src={process.env.PUBLIC_URL + "/LogoBeytty.png"}
                  className="md:hidden mb-8 w-40 text-center mx-auto flex hover:opacity-70 transition ease-in-out duration-200 "
                />
              </a>
              <div className=" justify-start text-center md:text-left text-xl md:text-4xl md:py-8 text-black">
                {t("signup")}{" "}
              </div>
              <div className="flex gap-2">
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={onChange}
                  placeholder={t("firstname")}
                  className="w-full mt-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
                />
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={onChange}
                  placeholder={t("lastname")}
                  className="w-full mt-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
                />
              </div>
              {firstNameError && (
                <p className="text-red-500 text-sm ">{firstNameError}</p>
              )}

              {lastNameError && (
                <p className="text-red-500 text-sm ">{lastNameError}</p>
              )}
              <input
                type="email"
                id="email"
                value={email}
                onChange={onChange}
                placeholder="Email"
                className="w-full mt-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
              />
              {emailError && (
                <p className="text-red-500 text-sm ">{emailError}</p>
              )}
              <div className="flex mt-6 py-2">
                <PhoneInput
                  className="w-full text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
                  country={"ma"} // Default country, e.g., Morocco
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                    fontSize: "1rem",
                    color: "#4a4a4a",
                    borderRadius: "0.25rem",
                    border: "1px solid #ccc",
                  }}
                  containerStyle={{
                    width: "100%",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0.25rem",
                    backgroundColor: "#fff",
                  }}
                  buttonStyle={{
                    border: "none",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                  }}
                  countryCodeEditable={false}
                />
              </div>
              {phoneError && (
                <p className="text-red-500 text-sm ">{phoneError}</p>
              )}
              <div className="relative mt-6 ">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={onChange}
                  placeholder={t("password")}
                  className="w-full px-4  py-2 text-md  shadow-md color-grey-700 bg-white border-gray-300 rounded transition ease-in-out"
                />
                {showPassword ? (
                  <AiFillEye
                    className="absolute right-3 top-3 text-xl cursor-pointer"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="absolute right-3 top-3 text-xl cursor-pointer"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                )}
              </div>
              {passwordError && (
                <p className="text-red-500 text-sm mb-2">{passwordError}</p>
              )}
              <div className="flex mt-2 items-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreeToTerms}
                      onChange={(e) => setAgreeToTerms(e.target.checked)}
                      color="primary"
                      sx={{
                        color: "red",
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  sx={{ marginRight: "0px" }}
                />
                <p
                  className=" hover:underline text-sm cursor-pointer  mb-[1px]"
                  onClick={handleOpenTerms}
                >
                  {t("iagreetothe")}{" "}
                  <span className="font-semibold">
                    {t("termsandconditions")}
                  </span>
                </p>
              </div>
              {termsError && (
                <p className="text-red-500 text-sm -mt-2">{termsError}</p>
              )}

              <div className="flex justify-between whitespace-nowrap text-sm sm:text-md">
                <p className="mt-3  mb-6">
                  {t("haveanaccount")}{" "}
                  <Link
                    to="/sign-in"
                    className="text-black hover:text-red-700 transition duration-200 ease-in-out underline font-semibold"
                  >
                    {" "}
                    {t("signin")}{" "}
                  </Link>
                </p>
              </div>
              <button
                className="w-full uppercase bg-custom-red rounded text-white px-7 py-3"
                type="submit"
              >
                {t("signup")}
              </button>
              <div className="flex items-center my-4 before:border-t before:flex-1 before:border-gray-300 after:border-t after:flex-1 after:border-gray-300">
                <p
                  className="text-center
        font-semibold mx-4"
                >
                  {t("or")}
                </p>
              </div>
              <OAuth />
              <p
                className="text-center md:text-lg py-6 font-semibold mx-4 cursor-pointer hover:text-red-700"
                onClick={handleSignUpAsAgentClick}
              >
                {t("areyouanagent")}{" "}
              </p>
            </form>
          </div>
        )}
      </div>
      <Dialog open={openTerms} onClose={handleCloseTerms}>
        <DialogContent>
          <DialogContentText>
            <div className="p-4 max-w-4xl mx-auto text-sm">
              <h1 className="text-xl lg:text-3xl py-4 lg:py-10 text-center font-bold mb-4">
                {t("termsconditions")}
              </h1>
              <p className="mb-4">
                {t("termsconditions_p1", {
                  url: (
                    <a
                      href="https://www.beytty.com"
                      className="text-red-500 underline"
                    >
                      www.beytty.com
                    </a>
                  ),
                  date: "[Date]",
                })}
              </p>

              <h2 className="text-xl font-semibold mb-2">
                {t("section1_title")}
              </h2>

              
              <p className="mb-4">
                {t("subsection1_1_p1", {
                  amount: "[Amount]",
                  city: "[City]",
                  number: "[Number]",
                  address: "[Address]",
                })}
              </p>
              <p className="mb-2">
                {t("subsection1_1_p2", { director_name: "[Director Name]" })}
              </p>
              <p className="mb-2">
                {t("subsection1_1_p3", { manager_name: "[Manager Name]" })}
              </p>
              <p className="mb-2">
                {t("subsection1_1_p4", { phone_number: "[Phone Number]" })}
              </p>
              <p className="mb-2">
                {t("subsection1_1_p5", {
                  email: (
                    <a
                      href="mailto:contact@beytty.com"
                      className="text-red-500 underline"
                    >
                      contact@beytty.com
                    </a>
                  ),
                })}
              </p>
              
              <h2 className="text-xl mt-4 font-semibold mb-2">
                {t("section2_title")}
              </h2>
              <ul className="list-disc pl-5">
                <li className="mb-2">
                  <p>{t("definition_listing")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_properties_sold")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_partner")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_real_estate_professional")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_partner_real_estate_professional")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_services")}</p>
                </li>
                <li className="mb-2">
                  <p>
                    {t("definition_site", {
                      url: (
                        <a
                          href="https://www.beytty.com"
                          className="text-red-500 underline"
                        >
                          https://www.beytty.com
                        </a>
                      ),
                    })}
                  </p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_user")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_agent_profile")}</p>
                </li>
              </ul>
              <h2 className="text-xl font-semibold mb-2">
                {t("section3_title")}
              </h2>

              <h3 className="mt-4 text-lg font-semibold mb-1">
                {t("subsection3_1_title")}
              </h3>
              <p className="mb-4">{t("subsection3_1_p1")}</p>
              <p className="mb-4">{t("subsection3_1_p2")}</p>
              <p className="mb-4">{t("subsection3_1_p3")}</p>

              <h3 className="text-lg font-semibold mb-1">
                {t("subsection3_2_title")}
              </h3>
              <p className="mb-4">{t("subsection3_2_p1")}</p>
              <p className="mb-4">{t("subsection3_2_p2")}</p>

              <h3 className="text-lg font-semibold mb-1">
                {t("subsection3_3_title")}
              </h3>
              <p className="mb-4">
                {t("subsection3_3_p1", {
                  your_company_address: "[Your Company Address]",
                  email: (
                    <a
                      href="mailto:contact@beytty.com"
                      className="text-red-500 underline"
                    >
                      contact@beytty.com
                    </a>
                  ),
                })}
              </p>
              <p className="mb-4">{t("subsection3_3_p2")}</p>

              <h3 className="text-lg font-semibold mb-1">
                {t("subsection3_4_title")}
              </h3>
              <p className="mb-4">{t("subsection3_4_p1")}</p>

              <h3 className="text-lg font-semibold mb-1">
                {t("section4_title")}
              </h3>
              <p className="mb-4">
                {t("section4_p1", {
                  email: (
                    <a
                      href="mailto:contact@beytty.com"
                      className="text-red-500 underline"
                    >
                      contact@beytty.com
                    </a>
                  ),
                })}
              </p>

              <h3 className="text-lg font-semibold mb-1">
                {t("section5_title")}
              </h3>
              <p className="mb-4">
                {t("section5_p1", {
                  phone_number: "[Phone Number]",
                  email: (
                    <a
                      href="mailto:contact@beytty.com"
                      className="text-red-500 underline"
                    >
                      contact@beytty.com
                    </a>
                  ),
                })}
              </p>
              <p className="mb-4">{t("section5_p2")}</p>
              <p className="mb-4">{t("section5_p3")}</p>
              <h3 className="text-lg font-semibold mb-1">
                {t("section6_title")}
              </h3>
              <p className="mb-4">{t("section6_p1")}</p>
              <p className="mb-4">{t("section6_p2")}</p>

              <h4 className="text-md font-semibold mb-1">
                {t("section6.1_title")}
              </h4>
              <h5 className="text-md font-semibold mb-1">
                {t("section6.1.1_title")}
              </h5>
              <p className="mb-4">{t("section6.1.1_p1")}</p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.1.2_title")}
              </h5>
              <p className="mb-4">{t("section6.1.2_p1")}</p>
              <ul className="list-disc pl-5 mb-4">
                <li>{t("section6.1.2_bullet1")}</li>
                <li>{t("section6.1.2_bullet2")}</li>
                <li>{t("section6.1.2_bullet3")}</li>
                <li>{t("section6.1.2_bullet4")}</li>
                <li>{t("section6.1.2_bullet5")}</li>
              </ul>
              <p className="mb-4">{t("section6.1.2_p2")}</p>
              <ul className="list-disc pl-5 mb-4">
                <li>{t("section6.1.2.1_bullet1")}</li>
                <li>{t("section6.1.2.1_bullet2")}</li>
                <li>{t("section6.1.2.1_bullet3")}</li>
                <li>{t("section6.1.2.1_bullet4")}</li>
                <li>{t("section6.1.2.1_bullet5")}</li>
                <li>{t("section6.1.2.1_bullet6")}</li>
                <li>{t("section6.1.2.1_bullet7")}</li>
                <li>{t("section6.1.2.1_bullet8")}</li>
                <li>{t("section6.1.2.1_bullet9")}</li>
                <li>{t("section6.1.2.1_bullet10")}</li>
              </ul>
              <p className="mb-4">{t("section6.1.2_p3")}</p>
              <li>{t("section6.1.2_list3")}</li>
              <li>{t("section6.1.2_list3.1")}</li>

              <h4 className="text-md mt-4 font-semibold mb-1">
                {t("section6.1.3_title")}
              </h4>
              <p className="mb-4">{t("section6.1.3_p1")}</p>

              <h4 className="text-md font-semibold mb-1">
                {t("section6.1.4_title")}
              </h4>
              <p className="mb-4">{t("section6.1.4_p1")}</p>
              <ul className="list-disc pl-5 mb-4">
                <li>{t("section6.1.4_list")}</li>
                <li>{t("section6.1.4_list.1")}</li>
                <li>{t("section6.1.4_list.2")}</li>
                <li>{t("section6.1.4_list.3")}</li>
              </ul>
              <p className="mb-4">{t("section6.1.4_p2")}</p>

              <h4 className="text-md font-semibold mb-1">
                {t("section6.2_title")}
              </h4>
              <p className="mb-4">{t("section6.2_p1")}</p>
              <ul className="list-disc pl-5 mb-4">
                <li>{t("section6.2_list")}</li>
                <li>{t("section6.2_list.1")}</li>
                <li>{t("section6.2_list.2")}</li>
                <li>{t("section6.2_list.3")}</li>
                <li>{t("section6.2_list.4")}</li>
                <li>{t("section6.2_list.5")}</li>
              </ul>
              <p className="mb-4">{t("section6.2_p2")}</p>
              <ul className="list-disc pl-5">
                <li>{t("section6_contact_list")}</li>
                <li>{t("section6_contact_list.1")}</li>
              </ul>
              <h4 className="mt-4 text-md font-semibold mb-1">
                {t("section6.3_title")}
              </h4>
              <p className="mb-4">{t("section6.3_p1")}</p>
              <p className="mb-4">{t("section6.3_p2")}</p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.3.1_title")}
              </h5>
              <ul className="list-disc pl-5 mb-4">
                <li>
                  <strong>{t("section6.3.1_cookie1_title")}:</strong>{" "}
                  {t("section6.3.1_cookie1_description")}
                </li>
                <li>
                  <strong>{t("section6.3.1_cookie2_title")}:</strong>{" "}
                  {t("section6.3.1_cookie2_description")}
                </li>
                <li>
                  <strong>{t("section6.3.1_cookie3_title")}:</strong>{" "}
                  {t("section6.3.1_cookie3_description")}
                </li>
                <li>
                  <strong>{t("section6.3.1_cookie4_title")}:</strong>{" "}
                  {t("section6.3.1_cookie4_description")}
                </li>
                <li>
                  <strong>{t("section6.3.1_cookie5_title")}:</strong>{" "}
                  {t("section6.3.1_cookie5_description")}
                </li>
                <li>
                  <strong>{t("section6.3.1_cookie6_title")}:</strong>{" "}
                  {t("section6.3.1_cookie6_description")}
                </li>
              </ul>

              <p className="mb-4">{t("section6.3_p3")}</p>

              <p className="mb-4">{t("section6.3_p4")}</p>

              <ul className="list-disc pl-5 mb-4">
                <li>
                  <strong>{t("section6.3_p5_title")}:</strong>{" "}
                  {t("section6.3_p5_description")}
                </li>
                <li>
                  <strong>{t("section6.3_p6_title")}:</strong>{" "}
                  {t("section6.3_p6_description")}
                </li>
                <li>
                  <strong>{t("section6.3_p7_title")}:</strong>{" "}
                  {t("section6.3_p7_description")}
                </li>
              </ul>

              <p className="mb-4">{t("section6.3_p8")}</p>
              <p className="mb-4">
                <a
                  href="https://www.youronlinechoices.com/fr/controler-ses-cookies/"
                  className="text-red-500 underline"
                >
                  {" "}
                  {t("section6.3_p10")}
                </a>{" "}
                {t("section6.3_p9_separator")}{" "}
                <a
                  href="https://www.allaboutcookies.org/fr/"
                  className="text-red-500 underline"
                >
                  {t("section6.3_p11")}
                </a>
                .
              </p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.3_p12.1")}
              </h5>
              <ul className="list-disc pl-5 mb-4">
                <li>
                  <strong>{t("section6.3_p12.2")}</strong>
                  <ul className="list-disc pl-5">
                    <li>{t("section6.3_p12.3")}</li>
                    <li>
                      <a href={t("")} className="text-red-500 underline">
                        {t("section6.3_p12.13")}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t("section6.3_p12.4")}</strong>
                  <ul className="list-disc pl-5">
                    <li>{t("section6.3_p12.5")}</li>
                    <li>
                      <a href={t("")} className="text-red-500 underline">
                        {t("section6.3_p12.13")}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t("section6.3_p12.6")}</strong>
                  <ul className="list-disc pl-5">
                    <li>{t("section6.3_p12.7")}</li>
                    <li>
                      <a href={t("")} className="text-red-500 underline">
                        {t("")}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t("section6.3_p12.8")}</strong>
                  <ul className="list-disc pl-5">
                    <li>{t("section6.3_p12.9")}</li>
                    <li>
                      <a href={t("")} className="text-red-500 underline">
                        {t("section6.3_p12.13")}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t("section6.3_p12.11")}</strong>
                  <ul className="list-disc pl-5">
                    <li>{t("section6.3_p12.12")}</li>
                    <li>
                      <a href={t("")} className="text-red-500 underline">
                        {t("section6.3_p12.13")}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <h4 className="text-md font-semibold mb-1">
                {t("section6.4_title")}
              </h4>
              <p className="mb-4">{t("section6.4_p1")}</p>
              <p className="mb-4 font-semibold">{t("section6.4_p2")}</p>
              <p className="mb-4">{t("section6.4_p2_sales_fee")}</p>
              <p className="mb-4">{t("section6.4_p2_purchases_fee")}</p>
              <p className="mb-4">{t("section6.4_p2_rentals_landlord_fee")}</p>
              <p className="mb-4">{t("section6.4_p2_rentals_tenant_fee")}</p>
              <h4 className="text-md font-semibold mb-1">
                {t("section6.5_title")}
              </h4>
              <p className="mb-4">{t("section6.5_p1")}</p>
              <p className="mb-4">{t("section6.5_p2")}</p>

              <p className="mb-4">{t("section6.5_data_description")}</p>
              <ul className="list-disc pl-5 mb-4">
                <li>{t("section6.5_data_item1")}</li>
                <li>{t("section6.5_data_item2")}</li>
                <li>{t("section6.5_data_item3")}</li>
                <li>{t("section6.5_data_item4")}</li>
                <li>{t("section6.5_data_item5")}</li>
              </ul>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.5_legal_basis_title")}
              </h5>
              <p className="mb-4">{t("section6.5_legal_basis_description")}</p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.5_data_retention_title")}
              </h5>
              <p className="mb-4">
                {t("section6.5_data_retention_description")}
              </p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.5_data_recipients_title")}
              </h5>
              <p className="mb-4">
                {t("section6.5_data_recipients_description")}
              </p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.5_data_subjects_rights_title")}
              </h5>
              <ul className="list-disc pl-5 mb-4">
                <li>
                  <strong>{t("section6.5_rights_access_title")}:</strong>{" "}
                  {t("section6.5_rights_access_description")}
                </li>
                <li>
                  <strong>{t("section6.5_rights_erasure_title")}:</strong>{" "}
                  {t("section6.5_rights_erasure_description")}
                </li>
                <li>
                  <strong>{t("section6.5_rights_rectification_title")}:</strong>{" "}
                  {t("section6.5_rights_rectification_description")}
                </li>
                <li>
                  <strong>{t("section6.5_rights_opposition_title")}:</strong>{" "}
                  {t("section6.5_rights_opposition_description")}
                </li>
                <li>
                  <strong>{t("section6.5_rights_limitation_title")}:</strong>{" "}
                  {t("section6.5_rights_limitation_description")}
                </li>
              </ul>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.5_exercising_rights_title")}
              </h5>
              <p className="mb-4">
                {t("section6.5_exercising_rights_description")}{" "}
                <a
                  href="mailto:contact@beytty.com"
                  className="text-red-500 underline"
                >
                  contact@beytty.com
                </a>
                . {t("section6.5_exercising_rights_additional")}
              </p>

              <p className="mb-4">{t("section6.5_dispute_clause")}</p>

              <h4 className="text-lg font-semibold mb-2">
                {t("section7_title")}
              </h4>
              <p className="mb-4">{t("section7_p1")}</p>

              <h4 className="text-lg font-semibold mb-2">
                {t("section8_title")}
              </h4>
              <p className="mb-4">{t("section8_p1")}</p>

              <h5 className="text-md font-semibold mb-1">
                {t("section8.1_partner_responsibility_title")}
              </h5>
              <p className="mb-4">
                {t("section8.1_partner_responsibility_description")}
              </p>

              <h5 className="text-md font-semibold mb-1">
                {t("section8.2_limitations_of_liability_title")}
              </h5>
              <p className="mb-4">
                {t("section8.2_limitations_of_liability_description")}
              </p>

              <h4 className="text-lg font-semibold mb-2">
                {t("section9_title")}
              </h4>
              <p className="mb-4">{t("section9_p1")}</p>

              <h4 className="text-lg font-semibold mb-2">
                {t("section10_title")}
              </h4>
              <p className="mb-4">
                {t("section10_p1")}
                <a
                  href="mailto:contact@beytty.com"
                  className="text-red-500 underline"
                >
                  contact@beytty.com
                </a>
                .
              </p>

              <h4 class="text-lg font-semibold mb-2">
                {t("terms_of_sale_title")}
              </h4>
              <h5 class="text-md font-semibold mb-1">{t("1section1_title")}</h5>
              <p class="mb-4">{t("section1_description")}</p>

              <h5 class="text-md font-semibold mb-1">{t("1section2_title")}</h5>
              <p class="mb-4">{t("section2_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section3_title")}</h4>
              <p class="mb-4">{t("section3_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section4_title")}</h4>
              <p class="mb-4">{t("section4_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section5_title")}</h4>
              <p class="mb-4">{t("section5_description")}</p>
              <ul class="list-disc list-inside mb-4">
                <li>{t("section5_list_item1")}</li>
                <li>{t("section5_list_item2")}</li>
                <li>{t("section5_list_item3")}</li>
                <li>{t("section5_list_item4")}</li>
              </ul>
              <p class="mb-4">{t("section5_additional_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section6_title")}</h4>
              <p class="mb-4">{t("section6_description")}</p>
              <ul class="list-disc list-inside mb-4">
                <li>{t("section6_list_item1")}</li>
                <li>{t("section6_list_item2")}</li>
                <li>{t("section6_list_item3")}</li>
                <li>{t("section6_list_item4")}</li>
              </ul>
              <p class="mb-4">{t("section6_compliance_clause")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section7_title")}</h4>
              <p class="mb-4">{t("section7_description")}</p>
              <ul class="list-disc list-inside mb-4">
                <li>{t("section7_list_item1")}</li>
                <li>{t("section7_list_item2")}</li>
                <li>{t("section7_list_item3")}</li>
                <li>{t("section7_list_item4")}</li>
              </ul>
              <p class="mb-4">{t("section7_compliance_clause")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section8_title")}</h4>
              <p class="mb-4">{t("section8_description")}</p>
              <ul class="list-disc list-inside mb-4">
                <li>{t("section8_list_item1")}</li>
                <li>{t("section8_list_item2")}</li>
                <li>{t("section8_list_item3")}</li>
                <li>{t("section8_list_item4")}</li>
              </ul>
              <p class="mb-4">{t("section8_compliance_clause")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section9_title")}</h4>
              <p class="mb-4">{t("section9_description")}</p>

              <h4 class="text-lg font-semibold mb-2">
                {t("1section10_title")}
              </h4>
              <p class="mb-4">{t("section10_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("section11_title")}</h4>
              <p class="mb-4">{t("section11_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("section12_title")}</h4>
              <p class="mb-4">{t("section12_description")}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTerms} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
}
