import React, { useState, useEffect } from "react";

export default function Bar({ loading }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setProgress((prev) => (prev < 80 ? prev + 1 : prev));
      }, 50); // Adjust the speed of progress here
    } else {
      setProgress(100); // Set to 100% when loading is done
    }

    return () => clearInterval(interval);
  }, [loading]);

  return (
    <div className="loading-bar-container">
      <div className="loading-bar-background">
        <div className="loading-bar" style={{ width: `${progress}%` }}></div>
      </div>{" "}
    </div>
  );
}
