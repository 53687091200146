import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { GrFormPrevious } from "react-icons/gr";
import { Link } from "react-router-dom"; 
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { FaPhone } from "react-icons/fa6";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Button } from "@mui/material";
import "dayjs/locale/de";
import { IoTimeOutline } from "react-icons/io5";
import { getAuth } from "firebase/auth";









export default function Contact({ userRef, listing, Id }) {  const { t } = useTranslation();

  const [landlord, setLandlord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(
    t("whatsappMessageTemplate")
  );
  const [contactFormData, setContactFormData] = useState({
    name: "",
    phoneNumber: "",
  });
  const auth = getAuth();
  const [appointmentTime, setAppointmentTime] = useState(null);
  const [currentStep, setCurrentStep] = useState("initial");
  const [callButtonText, setCallButtonText] = useState("Call");
  const [listingType, setListingType] = useState("");
  const [loadingUser, setLoadingUser] = useState(true);
  const [timeError, setTimeError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  useEffect(() => {
    let isMounted = true;
    async function getLandlord() {
      const agentDocRef = doc(db, "agents", userRef);
      const userDocRef = doc(db, "users", userRef);
      const adminDocRef = doc(db, "admins", userRef);

      const agentDocSnap = await getDoc(agentDocRef);
      const userDocSnap = await getDoc(userDocRef);
      const adminDocSnap = await getDoc(adminDocRef);

      if (isMounted) {
        if (agentDocSnap.exists()) {
          setLandlord(agentDocSnap.data());
          setListingType(t("Listed by agent"));
          setCallButtonText(t("Call Agent"));
        } else if (userDocSnap.exists()) {
          setLandlord(userDocSnap.data());
          setListingType(t("Listed by owner"));
          setCallButtonText(t("Call Owner"));
        } else if (adminDocSnap.exists()) {
          setLandlord(adminDocSnap.data());
          setListingType(t("Official account"));
          setCallButtonText(t("Call Us"));
        } else {
          toast.error("Could not get landlord data");
        }

        setLoading(false);
      }
    }

    getLandlord();

    return () => {
      isMounted = false;
    };
  }, [userRef]);

  useEffect(() => {
    let isMounted = true;
    async function fetchUser() {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);
        if (isMounted && docSnap.exists()) {
          const userData = docSnap.data();
          const { firstName, phoneNumber } = userData;
          setContactFormData({
            name: firstName,
            phoneNumber: phoneNumber,
          });
        } else {
          console.log("No such document!");
        }
      }
      if (isMounted) {
        setLoadingUser(false);
      }
    }
    fetchUser();

    return () => {
      isMounted = false;
    };
  }, [auth.currentUser, db]);


  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === "message") {
      setMessage(value);
    } else if (name === "phoneNumber") {
      setContactFormData((prevData) => ({
        ...prevData,
        phoneNumber: value,
      }));
    }
  };

  function handleBack() {
    setCurrentStep("initial");
  }
  function handleShowMessageInput() {
    setCurrentStep("messageInput");
  }
  function handleSendEmail() {
    const { name, phoneNumber } = contactFormData;
    const formattedDateTime = appointmentTime
      ? appointmentTime.toLocaleString()
      : "No date/time selected";
    setPhoneNumberError("");
    setMessageError("");
    setTimeError("");

    // Check for errors in the phone number
    if (!phoneNumber || !phoneNumber.trim()) {
      setPhoneNumberError("Please enter your phone number.");
      return;
    }

    // Check for errors in the message
    if (!message || !message.trim()) {
      setMessageError("Please enter your message.");
      return;
    }

    // Check for errors in the appointment time
    if (!appointmentTime) {
      setTimeError("Please select a date/time.");
      return;
    }

    window.location.href = `mailto:${landlord.email}?Subject=Beytty : ${t(
      "newvisitreuestfrom"
    )} ${name} &body=${message}%0D%0A%0D%0A${t(
      "heresmypn"
    )} ${phoneNumber}%0D%0A%0D%0A${t(
      "appointmenttime"
    )} ${formattedDateTime}%0D%0A%0D%0AView Listing: www.beytty.com/listingdetails/${
      listing.id
    }`;
  }
  function handleSendWhatsApp() {
    const { name, phoneNumber } = contactFormData;
    const formattedDateTime = appointmentTime
      ? appointmentTime.toLocaleString()
      : t("nodateselected");
       setPhoneNumberError("");
       setMessageError("");
       setTimeError("");

       // Check for errors in the phone number
       if (!phoneNumber || !phoneNumber.trim()) {
         setPhoneNumberError("Please enter your phone number.");
         return;
       }

       // Check for errors in the message
       if (!message || !message.trim()) {
         setMessageError("Please enter your message.");
         return;
       }

       // Check for errors in the appointment time
       if (!appointmentTime) {
         setTimeError("Please select a date/time.");
         return;
       }
    const listingUrl = `www.beytty.com/listingdetails/${listing.id}`;

    const whatsappMessage = `${message}\n${t(
      "phonenumber"
    )} ${phoneNumber}\n${t("appointmenttime")} ${formattedDateTime}\n${t(
      "viewlisting"
    )} ${listingUrl}`;

    const whatsappLink = `https://wa.me/${
      landlord.phoneNumber
    }?text=${encodeURIComponent(whatsappMessage)}`;
    window.location.href = whatsappLink;
  }
  function handleCall() {
   if (
     callButtonText === t("Call Agent") ||
     callButtonText === t("Call Owner") ||
     callButtonText === t("Call Us")
   ) {
     // If the button text is "Call Agent" or "Call Owner", change it to display the phone number
     setCallButtonText(landlord.phoneNumber);
   } else {
     // If the button text is a phone number, initiate the call
     window.location.href = `tel:${landlord.phoneNumber}`;
   }
  }
  const handleScrollToTop = () => {
   window.scrollTo({
     top: 0
   });
  };

   
  return (
    <>
      {loading ? (
        <div className="h-[280px] border- shadow-lg w-full bg-gray-100 items-center  rounded-xl">
          <div className="h-[120px] rounded-t-xl w-full mt-8 bg-gray-100">
            <div className="w-[90px] bg-gray-200 h-[90px]  rounded-full justify-center mx-auto flex "></div>
          </div>
          <div className="flex mx-auto h-[40px] ml-4 mr-4 justify-center px-4 bg-gray-200 rounded-xl mb-4"></div>
          <div className="flex mx-auto h-[40px] ml-4 mr-4 justify-center px-4 bg-gray-200 rounded-xl "></div>
        </div>
      ) : (
        <div className="p-4 border-2 rounded-xl shadow-lg border-gray-200 w-full">
          {landlord !== null && (
            <div className="flex flex-col w-full">
              {currentStep === "messageInput" && (
                <div className="cursor-pointer hover:opacity-80 mb-3 ">
                  <GrFormPrevious size={25} onClick={handleBack} />
                </div>
              )}
              {currentStep === "initial" && (
                <div className="flex flex-col space-y-4">
                  <div className=" justify-center mx-auto ">
                    <div className="mb-2 mx-auto flex justify-center items-center">
                      {landlord.photoURL ? (
                        <a className="relative flex items-center justify-center">
                          <Link
                            to={`/agent/${userRef}`}
                            onClick={handleScrollToTop}
                            className="relative flex items-center"
                          >
                            <img
                              src={landlord.photoURL}
                              alt="Lister Profile Picture"
                              className="relative border-2 shadow-lg rounded-full flex mx-auto object-cover h-16 w-16"
                            />
                            {landlord.status === "approved" && (
                              <RiVerifiedBadgeFill
                                className="absolute top-0 bg-white rounded-full ml-12 h-5 w-5"
                                style={{ color: "red" }}
                              />
                            )}
                          </Link>
                        </a>
                      ) : (
                        <img
                          src="/anonym.png"
                          alt="Lister Profile Picture"
                          className="shadow-lg rounded-full flex mx-auto object-cover h-14 w-14"
                        />
                      )}
                    </div>
                    <div className="w-full capitalize mx-auto justify-center">
                      {listingType === t("Listed by agent") ? (
                        <Link
                          to={`/agent/${userRef}`}
                          onClick={handleScrollToTop}
                        >
                          <p className=" flex justify-center hover:text-red-600 font-semibold">
                            {`${landlord.firstName} ${landlord.lastName}`}
                            <label
                              className=" text-black -right-2 items-center justify-center rounded-full  cursor-pointer"
                              title="Verified agent"
                            ></label>
                          </p>
                        </Link>
                      ) : listingType === t("Official account") ? (
                        <Link
                          to={`/agent/${userRef}`}
                          onClick={handleScrollToTop}
                        >
                          <p className=" flex justify-center hover:text-red-600 font-semibold">
                            {`${landlord.firstName} ${landlord.lastName}`}
                            <label
                              className="text-black -right-2 items-center justify-center rounded-full cursor-pointer"
                              title="Official Beytty account "
                            ></label>
                          </p>
                        </Link>
                      ) : (
                        <p className="mt-4 text-center font-semibold">{`${landlord.firstName} ${landlord.lastName}`}</p>
                      )}
                      {listingType && (
                        <p className="relative  flex text-gray-400 text-sm mx-auto justify-center items-center">
                          {listingType}
                        </p>
                      )}
                    </div>
                  </div>{" "}
                  <button
                    className=" flex items-center justify-center gap-2 bg-custom-red text-white rounded-xl py-3 lg:text-md"
                    type="button"
                    onClick={handleCall}
                  >
                    <FaPhone />
                    {t(callButtonText)}{" "}
                  </button>
                  <button
                    className="block bg-gray-100 border-2 border-gray-200 hover:bg-gray-200 text-black rounded-xl py-3 lg:text-md active:border-black active:text-black"
                    type="button"
                    onClick={handleShowMessageInput}
                  >
                    {t("requestvisit")}
                  </button>
                </div>
              )}

              {currentStep === "messageInput" && (
                <div>
                  <p>{t("phonenumber")}</p>
                  <div className="w-full mt-3 mb-6">
                    <input
                      type="tel"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={contactFormData.phoneNumber}
                      onChange={onChange}
                      placeholder="Enter your phone number"
                      className="border-gray-200 bg-gray-100 w-full p-2 rounded"
                    />
                    {phoneNumberError && (
                      <p className="text-red-500 text-sm mb-2">
                        {phoneNumberError}
                      </p>
                    )}
                  </div>
                  <p>Message</p>
                  <div className="w-full mt-3 mb-6">
                    <textarea
                      name="message"
                      id="message"
                      rows="2"
                      value={message}
                      onChange={onChange}
                      className="border-gray-200 h-[250px] bg-gray-100 w-full p-2 rounded"
                    ></textarea>
                    {messageError && (
                      <p className="text-red-500 text-sm mb-2">
                        {messageError}
                      </p>
                    )}
                  </div>
                  <p>{t("whattime")}</p>
                  <div className="mt-3 w-full gap-2 items-center flex">
                    <IoTimeOutline className="w-10 h-10" />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className="bg-gray-100"
                        label="Select date and time"
                        value={appointmentTime}
                        sx={{ width: "1" }} // Example styling: adds margin bottom
                        onChange={setAppointmentTime}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  {timeError && (
                    <p className="text-red-500 text-sm mb-2">{timeError}</p>
                  )}
                  <button
                    className="block mt-4 w-full bg-custom-red  text-white rounded-xl py-3 lg:text-md"
                    type="button"
                    onClick={handleSendEmail}
                  >
                    {t("sendemail")}
                  </button>
                  <button
                    className="block w-full mt-2 border-gray-200 bg-gray-100 hover:bg-gray-200 border-2 text-black rounded-xl py-3 lg:text-md"
                    type="button"
                    onClick={handleSendWhatsApp}
                  >
                    {t("sendwtp")}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
