import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { PiNumberCircleOneFill } from "react-icons/pi";
import { PiNumberCircleTwoFill } from "react-icons/pi";
import { PiNumberCircleThreeFill } from "react-icons/pi";
import { PiNumberCircleFourFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";





export default function Help() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openBuying, setOpenBuying] = useState(false);
  const [openSelling, setOpenSelling] = useState(false);
  const [openRenting, setOpenRenting] = useState(false);
  const [openAgents, setOpenAgents] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openAboutUs, setOpenAboutUs] = useState(false);

    const handleOpenBuying = () => {
      setOpenBuying(true);
    };

    const handleCloseBuying = () => {
      setOpenBuying(false);
    };


      const handleOpenRenting = () => {
        setOpenRenting(true);
      };

      const handleCloseRenting = () => {
        setOpenRenting(false);
      };


        const handleOpenSelling = () => {
          setOpenSelling(true);
        };

        const handleCloseSelling = () => {
          setOpenSelling(false);
        };


          const handleOpenAgents = () => {
            setOpenAgents(true);
          };

          const handleCloseAgents = () => {
            setOpenAgents(false);
          };


            const handleOpenAboutUs = () => {
              setOpenAboutUs(true);
            };

            const handleCloseAboutUs = () => {
              setOpenAboutUs(false);
            };


              const handleOpenFAQ = () => {
                setOpenFAQ(true);
              };

              const handleCloseFAQ = () => {
                setOpenFAQ(false);
              };

                const handleOpenContactUs = () => {
                  setOpenContactUs(true);
                };

                const handleCloseContactUs = () => {
                  setOpenContactUs(false);
                };

  return (
    <div className="max-w-6xl mx-auto mt-12 mb-12 px-8">
      <div>
        <p className="text-xl lg:text-2xl custom-font font-semibold ">
          {t("needsomehelp")}{" "}
        </p>
        <div className="mt-8 grid grid-cols-3 grid-rows-2 gap-4 text-md ">
          <div
            className="flex items-center hover:shadow-md transition-all duration-100 cursor-pointer ease-in-out justify-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-8 rounded-xl"
            onClick={handleOpenBuying}
          >
            <p className="text-lg">{t("buy")}</p>
          </div>
          <div
            className="flex items-center hover:shadow-md transition-all duration-100 cursor-pointer ease-in-out justify-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-8 rounded-md"
            onClick={handleOpenRenting}
          >
            {" "}
            <p className="text-lg">{t("rent")}</p>
          </div>
          <div
            className="flex items-center hover:shadow-md transition-all duration-100 cursor-pointer ease-in-out justify-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-8 rounded-xl"
            onClick={handleOpenSelling}
          >
            {" "}
            <p className="text-lg">{t("sell")}</p>
          </div>
          <div
            className="flex items-center hover:shadow-md transition-all duration-100 cursor-pointer ease-in-out justify-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-8 rounded-xl"
            onClick={handleOpenAgents}
          >
            <p className="text-lg">{t("agents")}</p>
          </div>
          <div
            className="flex items-center hover:shadow-md transition-all duration-100 cursor-pointer ease-in-out justify-center  col-span-3 md:col-span-1 row-span-1 bg-gray-100 p-4 rounded-xl"
            onClick={handleOpenFAQ}
          >
            {" "}
            <p className="text-lg">FAQs</p>
          </div>

          <div
            className="flex items-center hover:shadow-md transition-all duration-100 cursor-pointer ease-in-out justify-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100 p-8 rounded-xl"
            onClick={handleOpenAboutUs}
          >
            <p className="text-lg">{t("aboutus")}</p>
          </div>
          <div
            className="flex items-center hover:shadow-md transition-all duration-100 cursor-pointer ease-in-out justify-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-8 rounded-xl"
            onClick={handleOpenContactUs}
          >
            {" "}
            <p className="text-lg">{t("contactus")}</p>
          </div>
          <div
            className="flex items-center capitalize hover:shadow-md transition-all duration-100 cursor-pointer ease-in-out justify-center  col-span-3 md:col-span-1 row-span-1 bg-gray-100 p-4 rounded-xl"
            onClick={() => {
              window.scrollTo(0, 0); // Scroll to the top
              navigate("/termsandconditions");
            }}
          >
            <p className="text-lg">{t("termsandconditions")}</p>
          </div>
        </div>
      </div>

      <Dialog
        className="justify-center items-center text-center "
        open={openBuying}
        onClose={handleCloseBuying}
      >
        <DialogContent>
          <DialogContentText>
            <body>
              <p className="flex mt-2  gap-2 text-center justify-center  text-xl md:text-2xl font-semibold mb-2">
                {t("howtobuyahouse")}{" "}
              </p>
              <p className="text-sm mb-8">{t("welcomebeytty")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleOneFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("searchingforhomes")}{" "}
              </h3>
              <p className="text-sm mb-8">{t("searchinstructions")}</p>
              <h3 className="flex  font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleTwoFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("contactingtheseller")}{" "}
              </h3>
              <p className="text-sm mb-8">{t("contactinstructions")}</p>
              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleThreeFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("makinganoffer")}{" "}
              </h3>
              <p className="text-sm mb-8">{t("offerinstructions")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleFourFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("customersupport")}{" "}
              </h3>
              <p className=" text-sm ">
                {t("supportinstructions")}
                <p className="text-red-600 mt-2 mb-8">
                  {" "}
                  <a
                    className=" transition-all ease-in-out duration-100 hover:font-semibold"
                    href="mailto:support@beytty.com"
                  >
                    {t("supportemail")}{" "}
                  </a>
                  .{" "}
                </p>
              </p>
            </body>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        className="justify-center items-center text-center "
        open={openSelling}
        onClose={handleCloseSelling}
      >
        <DialogContent>
          <DialogContentText>
            <body>
              <p className="flex mt-2  gap-2 text-center justify-center  text-xl md:text-2xl font-semibold mb-2">
                {t("howtosellahouse")}{" "}
              </p>
              <p className="text-sm mb-8">{t("thinkingselling")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleOneFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("chooseyoursellingoption")}{" "}
              </h3>
              <p className="text-sm mb-8">{t("sellingoptions")}</p>
              <ul className="list-disc text-sm list-inside mb-8">
                <li>
                  <strong> {t("sellwithagencytitle")}</strong>{" "}
                  {t("sellwithagency")}
                </li>
                <li>
                  <strong> {t("findanagenttitle")}</strong> {t("findanagent")}
                </li>
                <li>
                  <strong> {t("listbyyourselfttile")}</strong>{" "}
                  {t("listbyyourself")}
                </li>
              </ul>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleTwoFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("contactingtheseller")}
              </h3>
              <p className="text-sm mb-8">{t("contactsellerinstructions")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleThreeFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("completingthetransaction")}
              </h3>
              <p className="text-sm mb-8">{t("transactioninstructions")}</p>
              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleFourFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("customersupport")}
              </h3>
              <p className=" text-sm ">
                {t("supportinstructions")}

                <p className="text-red-600 mt-2 mb-8">
                  {" "}
                  <a
                    className=" transition-all ease-in-out duration-100 hover:font-semibold"
                    href="mailto:support@beytty.com"
                  >
                    {t("supportemail")}
                  </a>
                  .{" "}
                </p>
              </p>
            </body>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        className="justify-center items-center text-center "
        open={openRenting}
        onClose={handleCloseRenting}
      >
        <DialogContent>
          <DialogContentText>
            <body>
              <p className="flex mt-2  gap-2 text-center justify-center  text-xl md:text-2xl font-semibold mb-2">
                {t("howtorentaproperty")}
              </p>
              <p className="text-sm mb-8">{t("lookingtorentorfindrental")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleOneFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("chooseyourrentingoption")}
              </h3>
              <p className="text-sm mb-8">{t("rentingoptions")}</p>
              <ul className="list-disc text-sm list-inside mb-8">
                <li>
                  <strong> {t("rentoutwithbeyttytitle")}</strong>{" "}
                  {t("rentoutwithbeytty")}
                </li>
                <li>
                  <strong> {t("rentoutwithagenttitle")}</strong>{" "}
                  {t("rentoutwithagent")}
                </li>
                <li>
                  <strong> {t("rentoutbyyourselftitle")}</strong>{" "}
                  {t("rentoutbyyourself")}
                </li>
                <li>
                  <strong> {t("findarentaltitle")}</strong> {t("findarental")}
                </li>
              </ul>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleTwoFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("contactingotherparty")}
              </h3>
              <p className="text-sm mb-8">
                {t("contactotherpartyinstructions")}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleThreeFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("finalizingrentalagreement")}
              </h3>
              <p className="text-sm mb-8">{t("rentalagreementinstructions")}</p>
              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleFourFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("customersupport")}
              </h3>
              <p className=" text-sm ">
                {t("supportinstructions")}

                <p className="text-red-600 mt-2 mb-8">
                  {" "}
                  <a
                    className=" transition-all ease-in-out duration-100 hover:font-semibold"
                    href="mailto:support@beytty.com"
                  >
                    {t("supportemail")}
                  </a>
                  .{" "}
                </p>
              </p>
            </body>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        className="justify-center items-center text-center "
        open={openAgents}
        onClose={handleCloseAgents}
      >
        <DialogContent>
          <DialogContentText>
            <body>
              <p className="flex mt-2  gap-2 text-center justify-center  text-xl md:text-2xl font-semibold mb-2">
                {t("discoveragents")}
              </p>
              <p className="text-sm mb-8">{t("comprehensiveagentslist")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleOneFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("findingfilteringagents")}
              </h3>
              <p className="text-sm mb-8">
                {t("findingfilteringinstructions")}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleTwoFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("checkingreviewslistings")}
              </h3>
              <p className="text-sm mb-10">
                {t("checkingreviewslistingsinstructions")}
              </p>

              <p className="text-sm font-semibold mt-2 mb-5">
                {t("agentjoining")}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleThreeFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("joiningasagent")}
              </h3>
              <p className="text-sm mb-8 ">{t("joininginstructions")}</p>
              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleFourFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("customersupport")}
              </h3>
              <p className=" text-sm ">
                {t("supportinstructions")}

                <p className="text-red-600 mt-2 mb-8">
                  {" "}
                  <a
                    className=" transition-all ease-in-out duration-100 hover:font-semibold"
                    href="mailto:support@beytty.com"
                  >
                    {t("supportemail")}
                  </a>
                  .{" "}
                </p>
              </p>
            </body>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        className="justify-center items-center text-center "
        open={openFAQ}
        onClose={handleCloseFAQ}
      >
        <DialogContent>
          <DialogContentText>
            <body>
              <p className="flex mt-2  gap-2 text-center justify-center  text-xl md:text-2xl font-semibold mb-8">
                {t("frequentlyaskedquestions")}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleOneFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("howtolistproperty")}
              </h3>
              <p className="text-sm mb-8">{t("listpropertyinstructions")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleTwoFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("howtofindrentalproperty")}
              </h3>
              <p className="text-sm mb-8">
                {t("findrentalpropertyinstructions")}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleThreeFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("whatfees")}
              </h3>
              <p className="text-sm mb-8">{t("feesinstructions")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleFourFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("howtocontactsupport")}
              </h3>
              <p className="text-sm ">
                {t("supportcontactinstructions")}{" "}
                <p className="text-red-600 mt-2 mb-8"> {t("supportemail")}</p>
              </p>
            </body>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        className="justify-center items-center text-center "
        open={openAboutUs}
        onClose={handleCloseAboutUs}
      >
        <DialogContent>
          <DialogContentText>
            <body>
              <p className="flex mt-2 mb-2  gap-2 text-center justify-center  text-xl md:text-2xl font-semibold">
                {t("aboutbeytty")}{" "}
              </p>
              <p className="text-sm mb-8">{t("aboutdescription")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleOneFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("ourmission")}{" "}
              </h3>
              <p className="text-sm mb-8">{t("missiondescription")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleTwoFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("ourvalues")}{" "}
              </h3>
              <p className="text-sm mb-8">{t("valuesdescription")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleThreeFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("joinus")}{" "}
              </h3>
              <p className="text-sm mb-8">{t("joinusdescription")}</p>
            </body>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        className="justify-center items-center text-center "
        open={openContactUs}
        onClose={handleCloseContactUs}
      >
        <DialogContent>
          <DialogContentText>
            <body>
              <p className="flex mt-2 mb-2  gap-2 text-center justify-center  text-xl md:text-2xl font-semibold ">
                {t("contactus")}{" "}
              </p>
              <p className="text-sm mb-8">{t("contactdescription")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleOneFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("email")}
              </h3>
              <p className="text-sm mb-8">
                {t("emaildescription")}{" "}
                <a
                  className="text-red-600 transition-all ease-in-out duration-100 hover:font-semibold"
                  href="mailto:support@beytty.com"
                >
                  support@beytty.com
                </a>
                .{" "}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleTwoFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("phone")}
              </h3>
              <p className="text-sm mb-8">{t("phonedescription")}</p>
            </body>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
