import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "./Spinner"; // Import the Spinner component

const MeGuard = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [isMe, setIsMe] = useState(false); // Assume non-agent initially
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const checkMeStatus = async (user) => {
      if (user) {
        const meStatus = await isMeUser(user); // Replace with your agent check logic
        setIsMe(meStatus);
      }
      setIsLoading(false); // Set loading to false after check
    };

    const unsubscribe = onAuthStateChanged(auth, checkMeStatus);

    return () => unsubscribe();
  }, [auth]);

  // Conditionally render based on loading and agent status
  if (isLoading) {
    return <Spinner />; // Display Spinner component during check
  }

  if (!isMe) {
    navigate("/"); // Redirect to default page if not an agent
    return null;
  }

  return <>{children}</>;
};

const isMeUser = async (user) => {
  // Implement your logic to check if the user is an agent
  // This example assumes you have an "agents" collection in Firestore
  const userDocRef = doc(db, "me", user.uid);
  const userDoc = await getDoc(userDocRef);
  return userDoc.exists(); // Replace with your actual agent verification logic
};

export default MeGuard;
