import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase";
import ListingItem from "../components/ListingItem";
import ListingItemHover from "../components/ListingItemHover";
import { useLocation } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FaSearch } from "react-icons/fa";
import SecondHeader from "../components/SecondHeader";
import Footer from "../components/Footer";
import MoroccanPlaces from "../components/MoroccanPlaces";
import InputLabel from "@mui/material/InputLabel";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Drawer,
  Slider,
} from "@mui/material";
import ReactMapGL, { Marker, Popup, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { IoIosArrowDown } from "react-icons/io";
import Menu from "@mui/material/Menu";
import ListingPlaceholder from "../components/ListingPlaceholder";
import MarkerPopup from "../components/MarkerPopup";
import MarkerPopupSmall from "../components/MarkerPopupSmall";
import { CiMap } from "react-icons/ci";
import { LuArrowUpNarrowWide } from "react-icons/lu";
import { LuArrowDownNarrowWide } from "react-icons/lu";
import { MdSatelliteAlt } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { IoClose } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa6";
import { BsChevronCompactDown } from "react-icons/bs";













export default function Results() {
  const { t } = useTranslation();
  const location = useLocation();
  const initialFilterType = location.state ? location.state.filterType : null;
  const initialFilterListingType = location.state ? location.state.filterListingType : null;
  const initialSelectedLocation = location.state
    ? location.state.selectedLocation
    : null;
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const handleOpenFilters = () => {
    setIsFilterModalOpen(true);
  };
  const [hoveredListingId, setHoveredListingId] = useState(null);


  const handleCloseFilters = () => {
    setIsFilterModalOpen(false);
  };
  const [selectedLocation, setSelectedLocation] = useState(
    initialSelectedLocation
  );
  const [isOpenType, setIsOpenType] = useState(false);
  const [isOpenSmType, setIsOpenSmType] = useState(false);

 const handleResetFilters = () => {
   setMinPrice("");
   setMaxPrice("");
   setMinSize("");
   setMaxSize("");
   setFilters({
     parking: null,
     furnished: null,
     elevator: null,
     airCondition: null,
     pool: null,
   });
   setPriceRange(initialPriceRange);
   setSizeRange(initialSizeRange);
 };
 const [listingsToShow, setListingsToShow] = useState(8);

  // Function to show more listings
  const showMoreListings = () => {
    setListingsToShow(listingsToShow + 8);
  };



  function getMinPriceOptions() {
    if (filters.type === "rent") {
      return [
        { label: "0 DH", value: 0 },
        { label: "1,000 DH", value: 1000 },
        { label: "2,000 DH", value: 2000 },
        { label: "5,000 DH", value: 5000 },
        { label: "7,000 DH", value: 7000 },
        { label: "10,000 DH", value: 10000 },
        { label: "12,000 DH", value: 12000 },
        { label: "15,000 DH", value: 15000 },
        { label: "20,000 DH", value: 20000 },
        { label: "40,000 DH", value: 40000 },
        { label: "70,000 DH", value: 70000 },
      ];
    } else {
      return [
        { label: "0 DH", value: 0 },
        { label: "100,000 DH", value: 100000 },
        { label: "150,000 DH", value: 150000 },
        { label: "250,000 DH", value: 250000 },
        { label: "400,000 DH", value: 400000 },
        { label: "700,000 DH", value: 700000 },
        { label: "1,000,000 DH", value: 1000000 },
        { label: "1,200,000 DH", value: 1200000 },
        { label: "1,500,000 DH", value: 1500000 },
        { label: "2,000,000 DH", value: 2000000 },
        { label: "4,000,000 DH", value: 4000000 },
        { label: "7,000,000 DH", value: 4000000 },
        { label: "10,000,000 DH", value: 10000000 },
      ];
    }
  }

  function getMaxPriceOptions() {
    if (filters.type === "rent") {
      return [
        { label: "1,000 DH", value: 1000 },
        { label: "2,000 DH", value: 2000 },
        { label: "5,000 DH", value: 5000 },
        { label: "7,000 DH", value: 7000 },
        { label: "10,000 DH", value: 10000 },
        { label: "12,000 DH", value: 12000 },
        { label: "15,000 DH", value: 15000 },
        { label: "20,000 DH", value: 20000 },
        { label: "40,000 DH", value: 40000 },
        { label: "70,000 DH", value: 70000 },
      ];
    } else {
      return [
        { label: "100,000 DH", value: 100000 },
        { label: "150,000 DH", value: 150000 },
        { label: "250,000 DH", value: 250000 },
        { label: "400,000 DH", value: 400000 },
        { label: "700,000 DH", value: 700000 },
        { label: "1,000,000 DH", value: 1000000 },
        { label: "1,200,000 DH", value: 1200000 },
        { label: "1,500,000 DH", value: 1500000 },
        { label: "2,000,000 DH", value: 2000000 },
        { label: "4,000,000 DH", value: 4000000 },
        { label: "7,000,000 DH", value: 4000000 },
        { label: "10,000,000 DH", value: 10000000 },
      ];
    }
  }
  function getMinSizeOptions() {
    if (filters.listingType === "land") {
      return [
        { label: "0 m²", value: 0 },
        { label: "100 m²", value: 100 },
        { label: "200 m²", value: 200 },
        { label: "500 m²", value: 500 },
        { label: "800 m²", value: 800 },
        { label: "1000 m²", value: 1000 },
        { label: "1200 m²", value: 1200 },
        { label: "1500 m²", value: 1500 },
        { label: "2000 m²", value: 2000},
        { label: "3000 m²", value: 3000 },

      ];
    } else {
      return [
        { label: "0 m²", value: 0 },
        { label: "50 m²", value: 50 },
        { label: "80 m²", value: 80 },
        { label: "100 m²", value: 100 },
        { label: "120 m²", value: 120 },
        { label: "150 m²", value: 150 },
        { label: "200 m²", value: 200 },
        { label: "300 m²", value: 300 },
      ];
    }
  }

  function getMaxSizeOptions() {
    if (filters.listingType === "land") {
      return [
        { label: "100 m²", value: 100 },
        { label: "200 m²", value: 200 },
        { label: "500 m²", value: 500 },
        { label: "800 m²", value: 800 },
        { label: "1000 m²", value: 1000 },
        { label: "1200 m²", value: 1200 },
        { label: "1500 m²", value: 1500 },
        { label: "2000 m²", value: 2000 },
        { label: "3000 m²", value: 3000 },
        { label: "4000 m²", value: 4000 },
        { label: "6000 m²", value: 6000 },
        { label: "8000 m²", value: 8000 },
        { label: "10000 m²", value: 10000 },
      ];
    } else {
      return [
        { label: "50 m²", value: 50 },
        { label: "80 m²", value: 80 },
        { label: "100 m²", value: 100 },
        { label: "120 m²", value: 120 },
        { label: "150 m²", value: 150 },
        { label: "200 m²", value: 200 },
        { label: "300 m²", value: 300 },
        { label: "400 m²", value: 400 },
        { label: "600 m²", value: 600 },
        { label: "800 m²", value: 800 },
        { label: "1000 m²", value: 1000 },
        { label: "1300 m²", value: 1300 },
        { label: "1800 m²", value: 1800 },
      ];
    }
  }

  

  const locations = MoroccanPlaces();
  const [selectedListing, setSelectedListing] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minSize, setMinSize] = useState("");
  const [maxSize, setMaxSize] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [listingsWithinViewport, setListingsWithinViewport] = useState([]);
   const [mapStyle, setMapStyle] = useState(
     "mapbox://styles/mohamedmakdad/clsqz4sct00xn01pf8o9vg38g"
   );

  const [isActive, setIsActive] = useState(false);

  const handleStyleChange = () => {
    const newStyle =
      mapStyle === "mapbox://styles/mohamedmakdad/clsqz4sct00xn01pf8o9vg38g"
        ? "mapbox://styles/mapbox/satellite-v9"
        : "mapbox://styles/mohamedmakdad/clsqz4sct00xn01pf8o9vg38g";
    setMapStyle(newStyle);
    setIsActive(!isActive); // Toggle active state
  };

  
  const handleTypeOpen = () => {
    setIsOpenType(true);
  };
 
  const handleTypeClose = () => {
    setIsOpenType(false);
  };
 
  const handleOpenSmType = () => {
    setIsOpenSmType(true);
  };

  const handleCloseSmType = () => {
    setIsOpenSmType(false);
  };

  const handleSelectMinPrice = (price) => {
    setMinPrice(price);
  };

  const handleSelectMaxPrice = (price) => {
    setMaxPrice(price);
  };

  const handleSelectMinSize = (size) => {
    setMinSize(size);
  };

  const handleSelectMaxSize = (size) => {
    setMaxSize(size);
  };



  const toggleMapVisibility = () => {
     setShowMap((prevShowMap) => {
       const newShowMap = !prevShowMap;
       if (!newShowMap) {
         window.scrollTo(0, 0); // Scroll to the top
       }
       return newShowMap;
     });
  };

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const handleDrawerOpen = () => setIsFilterDrawerOpen(true);
  const handleDrawerClose = () => setIsFilterDrawerOpen(false);




  const mapRef = useRef(null);
  const desktopMapRef = useRef(null);
  const mobileMapRef = useRef(null);
  const [listings, setListings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    type: initialFilterType,
    listingType: initialFilterListingType,
    parking: null,
    furnished: null,
    elevator: null,
    airCondition: null,
    pool: null,
  });

const initialPriceRange = filters.type === "rent" ? [0, 100000] : [0, 100000000];
const [priceRange, setPriceRange] = useState(initialPriceRange);
  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
    handleSelectMinPrice(newValue[0]);
    handleSelectMaxPrice(newValue[1]);
  };
const initialSizeRange = filters.type === "rent" ? [0, 100000] : [0, 100000];
const [sizeRange, setSizeRange] = useState(initialSizeRange);
  const handleSizeChange = (event, newValue) => {
    setSizeRange(newValue);
    handleSelectMinSize(newValue[0]);
    handleSelectMaxSize(newValue[1]);
  };

  const [viewport, setViewport] = useState({
    latitude: 34, // Default latitude
    longitude: -7.0926, // Default longitude
    zoom: 7,
    minZoom: 5,
  });

  const [sortingOptions, setSortingOptions] = useState([
     { label:  t("newest"), value: "newest" },
    { label: t("oldest"), value: "oldest" },
    { label: t("highestprice"), value: "desc" },
    { label: t("lowestprice"), value: "asc" },
    
    
  ]);

  const [selectedSortingOption, setSelectedSortingOption] = useState(
    sortingOptions[0]
  );

  const [sortOrder, setSortOrder] = useState("newest");

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null); // Close menu if it's already open
    } else {
      setMenuAnchorEl(event.currentTarget); // Open menu
    }
  };

 const handleMenuClose = (event) => {
   event.stopPropagation(); // Stop propagation
   setMenuAnchorEl(null); // Close menu
 };


  const handleSortOrderChange = (option) => {
    setSortOrder(option.value);
    setSelectedSortingOption(option); // Update with the entire object
  };

  useEffect(() => {
    fetchListings();
  }, [sortOrder]);

  const handleSearch = () => {
    // Trigger the fetching of listings when the search button is clicked
    fetchListings();
  };


  useEffect(() => {
    if (listings && selectedLocation) {
      // Set the viewport based on the selected location
      setViewport({
        latitude: selectedLocation.latitude,
        longitude: selectedLocation.longitude,
        zoom: selectedLocation.zoom,
      });
      const currentZoomLevel = 12;

      const latitudeMargin = calculateLatitudeMargin(currentZoomLevel);
      const longitudeMargin = calculateLongitudeMargin(currentZoomLevel);

      const listingsWithinViewport = listings.filter(
        (listing) =>
          listing.data.latitude <= selectedLocation.latitude + latitudeMargin &&
          listing.data.latitude >= selectedLocation.latitude - latitudeMargin &&
          listing.data.longitude <=
            selectedLocation.longitude + longitudeMargin &&
          listing.data.longitude >= selectedLocation.longitude - longitudeMargin
      );

      function calculateLatitudeMargin(zoomLevel) {
        // Adjust these values based on your specific requirements
        if (zoomLevel < 5) {
          return 5; // Larger margin for very low zoom levels
        } else if (zoomLevel < 6) {
          return 4; // Larger margin for low zoom levels
        } else if (zoomLevel < 7) {
          return 3; // Medium margin for medium zoom levels
        } else if (zoomLevel < 8) {
          return 2; // Larger margin for low zoom levels
        } else if (zoomLevel < 9) {
          return 1; // Medium margin for medium zoom levels
        } else if (zoomLevel < 10) {
          return 0.8; // Larger margin for low zoom levels
        } else if (zoomLevel < 11) {
          return 0.2; // Medium margin for medium zoom levels
        } else if (zoomLevel < 12) {
          return 0.1; // Larger margin for low zoom levels
        } else if (zoomLevel < 13) {
          return 0.1; // Medium margin for medium zoom levels
        } else if (zoomLevel < 14) {
          return 0.08; // Larger margin for low zoom levels
        } else if (zoomLevel < 15) {
          return 0.06; // Medium margin for medium zoom levels
        } else if (zoomLevel < 16) {
          return 0.04; // Larger margin for low zoom levels
        } else if (zoomLevel < 17) {
          return 0.02; // Medium margin for medium zoom levels
        } else if (zoomLevel < 18) {
          return 0.01; // Larger margin for low zoom levels
        } else if (zoomLevel < 19) {
          return 0.008; // Medium margin for medium zoom levels
        } else if (zoomLevel < 20) {
          return 0.006; // Medium margin for medium zoom levels
        }
      }

      function calculateLongitudeMargin(zoomLevel) {
        // Adjust these values based on your specific requirements
        if (zoomLevel < 5) {
          return 5; // Larger margin for very low zoom levels
        } else if (zoomLevel < 6) {
          return 4; // Larger margin for low zoom levels
        } else if (zoomLevel < 7) {
          return 3; // Medium margin for medium zoom levels
        } else if (zoomLevel < 8) {
          return 2; // Larger margin for low zoom levels
        } else if (zoomLevel < 9) {
          return 1; // Medium margin for medium zoom levels
        } else if (zoomLevel < 10) {
          return 0.8; // Larger margin for low zoom levels
        } else if (zoomLevel < 11) {
          return 0.2; // Medium margin for medium zoom levels
        } else if (zoomLevel < 12) {
          return 0.1; // Larger margin for low zoom levels
        } else if (zoomLevel < 13) {
          return 0.1; // Medium margin for medium zoom levels
        } else if (zoomLevel < 14) {
          return 0.08; // Larger margin for low zoom levels
        } else if (zoomLevel < 15) {
          return 0.06; // Medium margin for medium zoom levels
        } else if (zoomLevel < 16) {
          return 0.04; // Larger margin for low zoom levels
        } else if (zoomLevel < 17) {
          return 0.02; // Medium margin for medium zoom levels
        } else if (zoomLevel < 18) {
          return 0.01; // Larger margin for low zoom levels
        } else if (zoomLevel < 19) {
          return 0.008; // Medium margin for medium zoom levels
        } else if (zoomLevel < 20) {
          return 0.006; // Medium margin for medium zoom levels
        }
      }

      const sortedListings = listingsWithinViewport.sort((a, b) => {
        switch (sortOrder) {
          case "asc":
            return a.data.regularPrice - b.data.regularPrice;
          case "desc":
            return b.data.regularPrice - a.data.regularPrice;
          case "newest":
            return b.data.timestamp - a.data.timestamp;
          case "oldest":
            return a.data.timestamp - b.data.timestamp;
          default:
            return 0;
        }
      });

      setLoading(true);
      setListingsWithinViewport(sortedListings);
      setLoading(false);
    }
  }, [selectedLocation, listings, sortOrder]);

  async function fetchListings() {
    try {
      const listingRef = collection(db, "listings");

      let baseQuery = query(listingRef);
      baseQuery = query(baseQuery, where("status", "==", "approved"));

      if (filters.type) {
        baseQuery = query(baseQuery, where("type", "==", filters.type));
      }
      if (filters.furnished) {
        baseQuery = query(baseQuery, where("furnished", "==", filters.furnished));
      }
      if (filters.parking) {
        baseQuery = query(baseQuery, where("parking", "==", filters.parking));
      }
      if (filters.elevator) {
        baseQuery = query(baseQuery, where("elevator", "==", filters.elevator));
      }
      if (filters.airCondition) {
        baseQuery = query(baseQuery, where("airCondition", "==", filters.airCondition));
      }
      if (filters.pool) {
        baseQuery = query(baseQuery, where("pool", "==", filters.pool));
      }
   

      if (filters.listingType) {
        baseQuery = query(
          baseQuery,
          where("listingType", "==", filters.listingType)
        );
      }

      if (minPrice !== "") {
        baseQuery = query(
          baseQuery,
          where("regularPrice", ">=", parseFloat(minPrice))
        );
      }

      if (maxPrice !== "") {
        baseQuery = query(
          baseQuery,
          where("regularPrice", "<=", parseFloat(maxPrice))
        );
      }

      if (minSize !== "") {
        baseQuery = query(
          baseQuery,
          where("size", ">=", parseFloat(minSize))
        );
      }

      if (maxSize !== "") {
        baseQuery = query(
          baseQuery,
          where("size", "<=", parseFloat(maxSize))
        );
      }

      // Apply sorting
      switch (sortOrder) {
        case "asc":
          baseQuery = query(
            baseQuery,
            orderBy("regularPrice"), // Use only one orderBy for the same property
            orderBy("timestamp")
          );
          break;
        case "desc":
          baseQuery = query(
            baseQuery,
            orderBy("regularPrice", "desc"), // Use only one orderBy for the same property
            orderBy("timestamp", "desc")
          );
          break;
        case "newest":
          baseQuery = query(
            baseQuery,
            orderBy("timestamp", "desc"),
            orderBy("regularPrice")
            // Use only one orderBy for the same property
          );
          break;
        case "oldest":
          baseQuery = query(
            baseQuery,
            orderBy("timestamp"),
            orderBy("regularPrice")
            // Use only one orderBy for the same property
          );
          break;
        default:
          break;
      }

      const querySnap = await getDocs(baseQuery);
      const listings = querySnap.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setListings(listings);
      const listingsWithinViewport = filterListingsWithinViewport(listings);

      setListingsWithinViewport(listingsWithinViewport);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching listings:", error);
      toast.error("Could not fetch listings");
    }
  }

  const toggleShowMap = () => {
    setShowMap((prevShowMap) => !prevShowMap);
  };

  // Helper function to filter listings within the current viewport
  function filterListingsWithinViewport(listings) {
    const listingsWithinViewport = listings.filter((listing) => {
      const currentZoomLevel = viewport.zoom;

      const latitudeMargin = calculateLatitudeMargin(currentZoomLevel);
      const longitudeMargin = calculateLongitudeMargin(currentZoomLevel);

      return (
        listing.data.latitude <= viewport.latitude + latitudeMargin &&
        listing.data.latitude >= viewport.latitude - latitudeMargin &&
        listing.data.longitude <= viewport.longitude + longitudeMargin &&
        listing.data.longitude >= viewport.longitude - longitudeMargin
      );
    });

    return listingsWithinViewport;
  }
  // Helper function to calculate latitude margin based on zoom level
  function calculateLatitudeMargin(zoomLevel) {
    // Adjust these values based on your specific requirements
    if (zoomLevel < 5) {
      return 5; // Larger margin for very low zoom levels
    } else if (zoomLevel < 6) {
      return 4; // Larger margin for low zoom levels
    } else if (zoomLevel < 7) {
      return 3; // Medium margin for medium zoom levels
    } else if (zoomLevel < 8) {
      return 2; // Larger margin for low zoom levels
    } else if (zoomLevel < 9) {
      return 1; // Medium margin for medium zoom levels
    } else if (zoomLevel < 10) {
      return 0.8; // Larger margin for low zoom levels
    } else if (zoomLevel < 11) {
      return 0.2; // Medium margin for medium zoom levels
    } else if (zoomLevel < 12) {
      return 0.1; // Larger margin for low zoom levels
    } else if (zoomLevel < 13) {
      return 0.1; // Medium margin for medium zoom levels
    } else if (zoomLevel < 14) {
      return 0.08; // Larger margin for low zoom levels
    } else if (zoomLevel < 15) {
      return 0.06; // Medium margin for medium zoom levels
    } else if (zoomLevel < 16) {
      return 0.04; // Larger margin for low zoom levels
    } else if (zoomLevel < 17) {
      return 0.02; // Medium margin for medium zoom levels
    } else if (zoomLevel < 18) {
      return 0.01; // Larger margin for low zoom levels
    } else if (zoomLevel < 19) {
      return 0.008; // Medium margin for medium zoom levels
    } else if (zoomLevel < 20) {
      return 0.006; // Medium margin for medium zoom levels
    }
  }

  // Helper function to calculate longitude margin based on zoom level
  function calculateLongitudeMargin(zoomLevel) {
    // Adjust these values based on your specific requirements
    if (zoomLevel < 5) {
      return 5; // Larger margin for very low zoom levels
    } else if (zoomLevel < 6) {
      return 4; // Larger margin for low zoom levels
    } else if (zoomLevel < 7) {
      return 3; // Medium margin for medium zoom levels
    } else if (zoomLevel < 8) {
      return 2; // Larger margin for low zoom levels
    } else if (zoomLevel < 9) {
      return 1; // Medium margin for medium zoom levels
    } else if (zoomLevel < 10) {
      return 0.8; // Larger margin for low zoom levels
    } else if (zoomLevel < 11) {
      return 0.2; // Medium margin for medium zoom levels
    } else if (zoomLevel < 12) {
      return 0.1; // Larger margin for low zoom levels
    } else if (zoomLevel < 13) {
      return 0.1; // Medium margin for medium zoom levels
    } else if (zoomLevel < 14) {
      return 0.08; // Larger margin for low zoom levels
    } else if (zoomLevel < 15) {
      return 0.06; // Medium margin for medium zoom levels
    } else if (zoomLevel < 16) {
      return 0.04; // Larger margin for low zoom levels
    } else if (zoomLevel < 17) {
      return 0.02; // Medium margin for medium zoom levels
    } else if (zoomLevel < 18) {
      return 0.01; // Larger margin for low zoom levels
    } else if (zoomLevel < 19) {
      return 0.008; // Medium margin for medium zoom levels
    } else if (zoomLevel < 20) {
      return 0.006; // Medium margin for medium zoom levels
    }
  }

  const [isMoving, setIsMoving] = useState(false);
  const moveTimeoutRef = useRef(null);
  const [viewportListings, setViewportListings] = useState(null);

  const handleMapMove = (evt) => {
    setSelectedLocation(null);
    setLoading(true);
    console.log({
      latitude: evt.viewState.latitude,
      longitude: evt.viewState.longitude,
      zoom: evt.viewState.zoom,
    });
    setViewport({
      latitude: evt.viewState.latitude,
      longitude: evt.viewState.longitude,
      zoom: evt.viewState.zoom,
    });
    
    
    const newViewport = evt;
    const listingsWithinViewport = listings.filter((listing) => {
      const currentZoomLevel = newViewport.viewState.zoom;

      const latitudeMargin = calculateLatitudeMargin(currentZoomLevel);
      const longitudeMargin = calculateLongitudeMargin(currentZoomLevel);

      return (
        listing.data.latitude <=
          newViewport.viewState.latitude + latitudeMargin &&
        listing.data.latitude >=
          newViewport.viewState.latitude - latitudeMargin &&
        listing.data.longitude <=
          newViewport.viewState.longitude + longitudeMargin &&
        listing.data.longitude >=
          newViewport.viewState.longitude - longitudeMargin
      );
    });


    function calculateLatitudeMargin(zoomLevel) {
      // Adjust these values based on your specific requirements
      if (zoomLevel < 5) {
        return 5; // Larger margin for very low zoom levels
      } else if (zoomLevel < 6) {
        return 4; // Larger margin for low zoom levels
      } else if (zoomLevel < 7) {
        return 3; // Medium margin for medium zoom levels
      } else if (zoomLevel < 8) {
        return 2; // Larger margin for low zoom levels
      } else if (zoomLevel < 9) {
        return 1; // Medium margin for medium zoom levels
      } else if (zoomLevel < 10) {
        return 0.8; // Larger margin for low zoom levels
      } else if (zoomLevel < 11) {
        return 0.2; // Medium margin for medium zoom levels
      } else if (zoomLevel < 12) {
        return 0.1; // Larger margin for low zoom levels
      } else if (zoomLevel < 13) {
        return 0.1; // Medium margin for medium zoom levels
      } else if (zoomLevel < 14) {
        return 0.08; // Larger margin for low zoom levels
      } else if (zoomLevel < 15) {
        return 0.06; // Medium margin for medium zoom levels
      } else if (zoomLevel < 16) {
        return 0.04; // Larger margin for low zoom levels
      } else if (zoomLevel < 17) {
        return 0.02; // Medium margin for medium zoom levels
      } else if (zoomLevel < 18) {
        return 0.01; // Larger margin for low zoom levels
      } else if (zoomLevel < 19) {
        return 0.008; // Medium margin for medium zoom levels
      } else if (zoomLevel < 20) {
        return 0.006; // Medium margin for medium zoom levels
      }
    }

    function calculateLongitudeMargin(zoomLevel) {
      // Adjust these values based on your specific requirements
      if (zoomLevel < 5) {
        return 5; // Larger margin for very low zoom levels
      } else if (zoomLevel < 6) {
        return 4; // Larger margin for low zoom levels
      } else if (zoomLevel < 7) {
        return 3; // Medium margin for medium zoom levels
      } else if (zoomLevel < 8) {
        return 2; // Larger margin for low zoom levels
      } else if (zoomLevel < 9) {
        return 1; // Medium margin for medium zoom levels
      } else if (zoomLevel < 10) {
        return 0.8; // Larger margin for low zoom levels
      } else if (zoomLevel < 11) {
        return 0.2; // Medium margin for medium zoom levels
      } else if (zoomLevel < 12) {
        return 0.1; // Larger margin for low zoom levels
      } else if (zoomLevel < 13) {
        return 0.1; // Medium margin for medium zoom levels
      } else if (zoomLevel < 14) {
        return 0.08; // Larger margin for low zoom levels
      } else if (zoomLevel < 15) {
        return 0.06; // Medium margin for medium zoom levels
      } else if (zoomLevel < 16) {
        return 0.04; // Larger margin for low zoom levels
      } else if (zoomLevel < 17) {
        return 0.02; // Medium margin for medium zoom levels
      } else if (zoomLevel < 18) {
        return 0.01; // Larger margin for low zoom levels
      } else if (zoomLevel < 19) {
        return 0.008; // Medium margin for medium zoom levels
      } else if (zoomLevel < 20) {
        return 0.006; // Medium margin for medium zoom levels
      }
    }

    moveTimeoutRef.current = setTimeout(() => {
      setListingsWithinViewport(listingsWithinViewport);
      setLoading(false);
    }, 1000);

    setIsMoving(false);

    setViewportListings(listingsWithinViewport);
  };

  function getMarkerColor(type) {
    // You can customize this function to return different colors based on your requirements
    if (type === "sale") {
      return "red";
    } else if (type === "rent") {
      return "orange";
    } else {
      // Default color for other cases
      return "blue";
    }
  }

  const handleMarkerClick = (listing) => {
    if (viewport && viewport.zoom < 12) {
      

      // Animate map flyTo
      mapRef.current.flyTo({
        center: [listing.data.longitude, listing.data.latitude],
        zoom: viewport.zoom + 2,
        essential: true, // This makes the animation non-interruptible
      });

    } else {
      setSelectedListing(listing);
      setSelectedMarker({
        latitude: listing.data.latitude,
        longitude: listing.data.longitude,
      });
    }
  };
  const handleMobileMarkerClick = (listing) => {
    if (viewport && viewport.zoom < 12) {
      

      // Animate map flyTo
      mobileMapRef.current.flyTo({
        center: [listing.data.longitude, listing.data.latitude],
        zoom: viewport.zoom + 2,
        essential: true, // This makes the animation non-interruptible
      });

    } else {
      setSelectedListing(listing);
      setSelectedMarker({
        latitude: listing.data.latitude,
        longitude: listing.data.longitude,
      });
    }
  };


  return (
    <div className="flex flex-col border-t-2 h-screen">
      <Helmet>
        <title>Search Results - Find Your Property | Beytty</title>
        <meta
          name="description"
          content="Browse search results on Beytty to find your ideal property. Use our interactive map and filtering options to view listings that match your preferences."
        />
        <meta
          name="keywords"
          content="property search, real estate listings, property map, filter search, Morocco real estate, Beytty, home search, office search, villa search"
        />
        <meta
          property="og:title"
          content="Search Results - Find Your Property | Beytty"
        />
        <meta
          property="og:description"
          content="Browse search results on Beytty to find your ideal property. Use our interactive map and filtering options to view listings that match your preferences."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.beytty.com/results" />
        <meta property="og:image" content="/apple-touch-icon.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Search Results - Find Your Property | Beytty"
        />
        <meta
          name="twitter:description"
          content="Browse search results on Beytty to find your ideal property. Use our interactive map and filtering options to view listings that match your preferences."
        />
        <meta name="twitter:image" content="/apple-touch-icon.png" />

        <title>Résultats de Recherche - Trouvez Votre Propriété | Beytty</title>
        <meta
          name="description"
          content="Parcourez les résultats de recherche sur Beytty pour trouver la propriété idéale. Utilisez notre carte interactive et nos options de filtrage pour consulter les annonces correspondant à vos préférences."
        />
        <meta
          name="keywords"
          content="recherche de propriété, annonces immobilières, carte des propriétés, recherche par filtre, immobilier au Maroc, Beytty, recherche de maison, recherche de bureau, recherche de villa"
        />
        <meta
          property="og:title"
          content="Résultats de Recherche - Trouvez Votre Propriété | Beytty"
        />
        <meta
          property="og:description"
          content="Parcourez les résultats de recherche sur Beytty pour trouver la propriété idéale. Utilisez notre carte interactive et nos options de filtrage pour consulter les annonces correspondant à vos préférences."
        />
        <meta
          name="twitter:title"
          content="Résultats de Recherche - Trouvez Votre Propriété | Beytty"
        />
        <meta
          name="twitter:description"
          content="Parcourez les résultats de recherche sur Beytty pour trouver la propriété idéale. Utilisez notre carte interactive et nos options de filtrage pour consulter les annonces correspondant à vos préférences."
        />
      </Helmet>

      <div className="">
        <SecondHeader
          setViewport={setViewport}
          setListingsWithinViewport={setListingsWithinViewport}
          listings={listings}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
        />
        <div className="flex h-[1px] md:h-[1px] bg-gray-200"></div>
      </div>

      <div className="hidden md:flex px-4 space-x-3 h-12 mt-4 mb-2 bg-white items-center">
        <div className="hidden relative sm:flex ">
          <Autocomplete
            className="bg-white items-center  rounded outline-0 custom-autocomplete"
            options={locations}
            value={selectedLocation}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "gray",
                    },
                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused": {
                      boxShadow: "none",
                    },
                    "& input": {
                      boxShadow: "none",
                    },
                    "& textarea": {
                      boxShadow: "none",
                    },
                  },
                }}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {t("searchplaceholder")}
                    <FaSearch style={{ marginLeft: "8px" }} />
                  </div>
                }
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                  style: { height: "14px" },
                }}
                InputLabelProps={{
                  // Add this to style the label
                  style: { lineHeight: "15px", paddingRight: "4px" }, // Adjust the lineHeight to vertically center the label
                }}
                InputProps={{
                  ...params.InputProps,

                  classes: {
                    root: selectedLocation ? "search-selected" : "",
                  },
                }}
                onChange={(event, newValue) => {
                  setSelectedLocation(newValue);
                }}
              />
            )}
            // Inside the onChange handler for Autocomplete component
            onChange={(event, newValue) => {
              setSelectedLocation(newValue);
              // Update the viewport immediately when a new location is selected
              if (newValue) {
                setViewport({
                  latitude: newValue.latitude,
                  longitude: newValue.longitude,
                  zoom: 12,
                });
              } else {
                // If the value is cleared, set viewport to default values
                setListingsWithinViewport(listings);
                setViewport({
                  latitude: 31.7917, // Default latitude
                  longitude: -7.0926, // Default longitude
                  zoom: 6,
                });
              }
            }}
            filterOptions={(options, { inputValue }) => {
              if (inputValue.trim() === "") {
                return [
                  {
                    name: "Type your address",
                    latitude: 33.5731,
                    longitude: -7.5898,
                  },
                ];
              }

              const filteredOptions = options.filter((option) =>
                option.name.toLowerCase().includes(inputValue.toLowerCase())
              );
              return filteredOptions.slice(0, 6);
            }}
          />
        </div>

        <div className="hidden md:flex justify-between w-2/4 xl:w-1/3 space-x-1 md:space-x-2">
          <FormControl className="w-full" variant="outlined">
            <InputLabel htmlFor="outlined-listing-type" id="listing-type-label">
              Type
            </InputLabel>
            <Select
              labelId="listing-type-label"
              id="outlined-listing-type"
              open={isOpenType}
              onOpen={handleTypeOpen}
              onClose={handleTypeClose}
              value={filters.type || "Type"}
              label="Type"
              displayEmpty
              inputProps={{ "aria-label": "Type" }}
              renderValue={(value) => {
                if (value === "sale") {
                  return t("forsale");
                } else if (value === "rent") {
                  return t("forrent");
                } else {
                  return value
                    ? value.charAt(0).toUpperCase() + value.slice(1)
                    : "Type";
                }
              }}
              className={`bg-white capitalized  rounded outline-0 ${
                filters.type ? "bg-red-100" : ""
              }`}
              style={{
                backgroundColor: filters.type ? "#f7e4e4" : "white",
                outline: "none",
                height: "40px",
                fontSize: "14px",
              }}
            >
              <div className="flex flex-col px-4 mt-2 mb-2 listing-type-options">
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                      checked={filters.type === "sale"}
                      value="sale"
                    />
                  }
                  label={t("forsale")}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      type: e.target.checked ? "sale" : null,
                    })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                      checked={filters.type === "rent"}
                      value="rent"
                    />
                  }
                  label={t("forrent")}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      type: e.target.checked ? "rent" : null,
                    })
                  }
                />
                <button
                  className="mt-2 py-2 bg-custom-red text-white rounded  px-2"
                  onClick={(e) => {
                    handleSearch();
                  }}
                >
                  {t("apply")}
                </button>

                {/* Add similar FormControlLabel for other listing types */}
              </div>
            </Select>
          </FormControl>
          <FormControl className="w-full" variant="outlined">
            <InputLabel htmlFor="outlined-listing-type" id="listing-type-label">
              {t("listingtype")}
            </InputLabel>
            <Select
              labelId="listing-type-label"
              id="outlined-listing-type"
              value={filters.listingType || t("listingtype")}
              label={t("listingtype")}
              displayEmpty
              inputProps={{ "aria-label": t("listingtype") }}
              renderValue={(value) => {
                if (value === "apartment") {
                  return t("apartment");
                } else if (value === "villa") {
                  return t("villa");
                } else if (value === "commercial") {
                  return t("commercial");
                } else if (value === "farmhouse") {
                  return t("farmhouse");
                } else if (value === "office") {
                  return t("office");
                } else if (value === "riad") {
                  return t("riad");
                } else if (value === "land") {
                  return t("land");
                } else {
                  return value
                    ? value.charAt(0).toUpperCase() + value.slice(1)
                    : "Listing Type";
                }
              }}
              className={`bg-white capitalized  rounded outline-0 ${
                filters.listingType ? "bg-red-100" : ""
              }`}
              style={{
                backgroundColor: filters.listingType ? "#f7e4e4" : "white",
                outline: "none",
                height: "40px",
                fontSize: "14px",
              }}
            >
              <div className="flex flex-col px-4 listing-type-options">
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                      checked={filters.listingType === "apartment"}
                      value="apartment"
                    />
                  }
                  label={t("apartment")}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      listingType: e.target.checked ? "apartment" : null,
                    })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                      checked={filters.listingType === "villa"}
                      value="villa"
                    />
                  }
                  label="Villa"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      listingType: e.target.checked ? "villa" : null,
                    })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                      checked={filters.listingType === "commercial"}
                      value="commercial"
                    />
                  }
                  label={t("commercial")}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      listingType: e.target.checked ? "commercial" : null,
                    })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                      checked={filters.listingType === "farmhouse"}
                      value="farmhouse"
                    />
                  }
                  label={t("farmhouse")}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      listingType: e.target.checked ? "farmhouse" : null,
                    })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                      checked={filters.listingType === "office"}
                      value="office"
                    />
                  }
                  label={t("office")}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      listingType: e.target.checked ? "office" : null,
                    })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                      checked={filters.listingType === "riad"}
                      value="riad"
                    />
                  }
                  label="Riad"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      listingType: e.target.checked ? "riad" : null,
                    })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                      value="land"
                    />
                  }
                  label={t("land")}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      listingType: e.target.checked ? "land" : null,
                    })
                  }
                />
                <button
                  className="mt-2 py-2 bg-custom-red text-white rounded mb-2 px-2"
                  onClick={(e) => {
                    handleSearch();
                  }}
                >
                  {t("apply")}
                </button>
                {/* Add similar FormControlLabel for other listing types */}
              </div>
            </Select>
          </FormControl>

          {/* Filter Trigger */}
          <div
            className="w-full border text-sm hover:border-black border-gray-300 rounded text-center flex items-center justify-center cursor-pointer"
            onClick={handleDrawerOpen}
          >
            {t("morefilters")}
          </div>

          {/* Drawer for Filter Panel */}
          <Drawer
            anchor="right"
            open={isFilterDrawerOpen}
            onClose={handleDrawerClose}
            className="w-full hidden md:block overflow-y-auto"
          >
            <div className="text-lg bg-gray-50 p-4">{t("morefilters")}</div>
            <div className="w-full px-4 rounded h-[2px] bg-gray-100"></div>

            <div className="p-4 w-full">
              {/* Filter Form Contents */}

              <div className="flex  flex-col  justify-center">
                <p className="text-lg mt-4">{t("price")}</p>
                <div className="flex justify-center items-center w-80 px-4">
                  <Slider
                    value={priceRange}
                    onChange={handlePriceChange}
                    valueLabelDisplay="auto"
                    min={0} // Always set minimum to 0
                    max={filters.type === "rent" ? 100000 : 100000000} // Max based on type: 100000 for rent, 1000000 for sale
                    step={filters.type === "rent" ? 1000 : 100000}
                    sx={{
                      color: "red", // Slider color
                      "& .MuiSlider-thumb": {
                        backgroundColor: "white",
                      },
                    }}
                  />
                </div>
                <div className="flex justify-between w-80">
                  <span>{`${new Intl.NumberFormat("en-US").format(
                    priceRange[0]
                  )} DH`}</span>
                  <span>{`${new Intl.NumberFormat("en-US").format(
                    priceRange[1]
                  )} DH`}</span>
                </div>
                <div className="w-full px-4 rounded h-[2px] bg-gray-100 mt-4 mb-4"></div>
                <p className="text-lg">{t("size")}</p>
                <div className="flex justify-center items-center w-80 px-4">
                  <Slider
                    value={sizeRange}
                    onChange={handleSizeChange}
                    valueLabelDisplay="auto"
                    min={0} // Always set minimum to 0
                    max={10000} // Max based on type: 100000 for rent, 1000000 for sale
                    step={100}
                    sx={{
                      color: "red", // Slider color
                      "& .MuiSlider-thumb": {
                        backgroundColor: "white",
                      },
                    }}
                  />
                </div>
                <div className="flex justify-between w-80">
                  <span>{`${new Intl.NumberFormat("en-US").format(
                    sizeRange[0]
                  )} m²`}</span>
                  <span>{`${new Intl.NumberFormat("en-US").format(
                    sizeRange[1]
                  )} m²`}</span>
                </div>
                <div className="w-full px-4 rounded h-[2px] bg-gray-100 mt-4 mb-4"></div>
                <p className="text-lg mb-2">{t("Extras")}</p>{" "}
              </div>
              <div className="flex flex-col">
                {/* Changed from grid to flex for better layout */}
                <FormControlLabel
                  control={
                    <Checkbox
                      id="parking"
                      checked={
                        filters.parking !== undefined ? filters.parking : false
                      } // Check for undefined and default to false
                      value="parking"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="mr-2"
                      sx={{
                        color: "red",
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  label={t("parking")}
                  onChange={(e) =>
                    setFilters({ ...filters, parking: e.target.checked })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="furnished"
                      checked={
                        filters.furnished !== undefined
                          ? filters.furnished
                          : false
                      } // Check for undefined and default to false
                      value="furnished"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="mr-2"
                      sx={{
                        color: "red",
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  label={t("furnished")}
                  onChange={(e) =>
                    setFilters({ ...filters, furnished: e.target.checked })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="elevator"
                      checked={
                        filters.elevator !== undefined
                          ? filters.elevator
                          : false
                      } // Check for undefined and default to false
                      value="elevator"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="mr-2"
                      sx={{
                        color: "red",
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  label={t("elevator")}
                  onChange={(e) =>
                    setFilters({ ...filters, elevator: e.target.checked })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="airCondition"
                      checked={
                        filters.airCondition !== undefined
                          ? filters.airCondition
                          : false
                      } // Check for undefined and default to false
                      value="airCondition"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="mr-2"
                      sx={{
                        color: "red",
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  label="A/C"
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      airCondition: e.target.checked,
                    })
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="pool"
                      checked={
                        filters.pool !== undefined ? filters.pool : false
                      } // Check for undefined and default to false
                      value="pool"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="mr-2"
                      sx={{
                        color: "red",
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  label={t("pool")}
                  onChange={(e) =>
                    setFilters({ ...filters, pool: e.target.checked })
                  }
                />
              </div>
              <div className="grid grid-cols-1 mt-4 mb-2">
                <button
                  className=" w-full  px-4 mt-2 py-2 bg-custom-red text-white rounded "
                  onClick={() => {
                    handleSearch();
                    handleDrawerClose();
                  }}
                >
                  {t("apply")}
                </button>
                <button
                  className="mt-2 opacity-70 hover:opacity-100 cursor-pointer transition ease-in-out duration-100"
                  onClick={() => {
                    handleResetFilters();
                  }}
                >
                  {t("reset")}
                </button>
              </div>
            </div>
          </Drawer>
        </div>
      </div>

      <div className="md:hidden flex px-1 gap-4 mt-2 mb-2 items-center ">
        <div className="flex gap-2 px-2 py-2 justify-between w-full">
          <FormControl
            className="w-full"
            variant="outlined"
            value={filters.type || "Type"}
            label="Type"
            displayEmpty
            inputProps={{ "aria-label": "Type" }}
            renderValue={(value) => {
              if (value === "sale") {
                return t("forsale");
              } else if (value === "rent") {
                return t("forrent");
              } else {
                return value
                  ? value.charAt(0).toUpperCase() + value.slice(1)
                  : "Type";
              }
            }}
            style={{
              backgroundColor: filters.type ? "#f7e4e4" : "white",
              borderRadius: "4px",
              height: "40px",
              outline: "none",
            }}
          >
            <InputLabel id="filters-label" className="-mt-2">
              Type
            </InputLabel>
            <Select
              labelId="listing-type-label"
              id="outlined-listing-type"
              open={isOpenSmType}
              onOpen={handleOpenSmType}
              onClose={handleCloseSmType}
              value={filters.type || "Type"}
              label="Listing Type"
              displayEmpty
              inputProps={{ "aria-label": "Type" }}
              renderValue={(value) => {
                if (value === "sale") {
                  return t("forsale");
                } else if (value === "rent") {
                  return t("forrent");
                } else {
                  return value
                    ? value.charAt(0).toUpperCase() + value.slice(1)
                    : "Type";
                }
              }}
              className={`bg-white capitalized  rounded outline-0 ${
                filters.type ? "bg-red-100" : ""
              }`}
              style={{
                backgroundColor: filters.type ? "#f7e4e4" : "white",
                outline: "none",
                height: "40px",
              }}
            >
              <div className="flex flex-col px-4 mt-2 mb-2 listing-type-options">
                <FormControlLabel
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                      checked={filters.type === "sale"}
                      value="sale"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          type: e.target.checked ? "sale" : null,
                        });
                      }}
                    />
                  }
                  label={t("forsale")}
                />
                <FormControlLabel
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                      checked={filters.type === "rent"}
                      value="rent"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          type: e.target.checked ? "rent" : null,
                        });
                      }}
                    />
                  }
                  label={t("forrent")}
                />
                <button
                  className="mt-2 py-2 bg-custom-red text-white rounded  px-2"
                  onClick={(e) => {
                    handleSearch();
                  }}
                >
                  {t("apply")}
                </button>
                {/* Add similar FormControlLabel for other listing types */}
              </div>
            </Select>
          </FormControl>

          <div
            onClick={handleOpenFilters}
            className="w-full border border-gray-300 rounded text-center items-center flex mx-auto justify-center"
          >
            {t("morefilters")}
          </div>

          {isFilterModalOpen && (
            <div className="filter-modal">
              <div className="w-full flex sticky">
                <button className="close-button" onClick={handleCloseFilters}>
                  <IoClose className="text-4xl" />
                </button>
                <p className="text-center justify-center mx-auto flex mt-4 font-semibold text-lg md:text-xl ">
                  {listingsWithinViewport ? listingsWithinViewport.length : 0}{" "}
                  {t("results")}
                </p>
              </div>
              <div className="w-full h-[1px] mt-4 bg-gray-200"></div>
              <div className="py-4 overflow-y-auto justify-center flex flex-col">
                <div className="flex flex-col items-center justify-center">
                  <div className="w-full px-4 rounded h-[2px] bg-gray-100 mb-4"></div>
                  <p className="text-lg">{t("listingtype")}</p>
                  <div className="flex justify-center items-center px-4 mt-8 mb-4 w-full">
                    <FormControl className="w-full" variant="outlined">
                      <InputLabel
                        htmlFor="outlined-listing-type"
                        id="listing-type-label"
                      >
                        {t("listingtype")}
                      </InputLabel>
                      <Select
                        labelId="listing-type-label"
                        id="listing-type-select"
                        value={filters.listingType || t("listingtype")}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            listingType: e.target.value || null,
                          })
                        }
                        label={t("listingtype")}
                        displayEmpty
                        inputProps={{ "aria-label": "Listing Type" }}
                        renderValue={(value) => {
                          if (value === "apartment") {
                            return t("apartment");
                          } else if (value === "villa") {
                            return t("villa");
                          } else if (value === "commercial") {
                            return t("commercial");
                          } else if (value === "farmhouse") {
                            return t("farmhouse");
                          } else if (value === "office") {
                            return t("office");
                          } else if (value === "riad") {
                            return t("riad");
                          } else if (value === "land") {
                            return t("land");
                          } else {
                            return value
                              ? value.charAt(0).toUpperCase() + value.slice(1)
                              : "Listing Type";
                          }
                        }}
                        className={`bg-white capitalized  rounded outline-0 ${
                          filters.listingType ? "bg-red-100" : ""
                        }`}
                        style={{
                          backgroundColor: filters.listingType
                            ? "#f7e4e4"
                            : "white",
                          outline: "none",

                          height: "50px",
                        }}
                      >
                        <div className="flex flex-col px-4 listing-type-options">
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "&.Mui-checked": {
                                    color: "red",
                                  },
                                }}
                                checked={filters.listingType === "apartment"}
                                value="apartment"
                              />
                            }
                            label={t("apartment")}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                listingType: e.target.checked
                                  ? "apartment"
                                  : null,
                              })
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "&.Mui-checked": {
                                    color: "red",
                                  },
                                }}
                                checked={filters.listingType === "villa"}
                                value="villa"
                              />
                            }
                            label="Villa"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                listingType: e.target.checked ? "villa" : null,
                              })
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "&.Mui-checked": {
                                    color: "red",
                                  },
                                }}
                                checked={filters.listingType === "commercial"}
                                value="commercial"
                              />
                            }
                            label={t("commercial")}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                listingType: e.target.checked
                                  ? "commercial"
                                  : null,
                              })
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "&.Mui-checked": {
                                    color: "red",
                                  },
                                }}
                                checked={filters.listingType === "farmhouse"}
                                value="farmhouse"
                              />
                            }
                            label={t("farmhouse")}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                listingType: e.target.checked
                                  ? "farmhouse"
                                  : null,
                              })
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "&.Mui-checked": {
                                    color: "red",
                                  },
                                }}
                                checked={filters.listingType === "office"}
                                value="office"
                              />
                            }
                            label={t("office")}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                listingType: e.target.checked ? "office" : null,
                              })
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "&.Mui-checked": {
                                    color: "red",
                                  },
                                }}
                                checked={filters.listingType === "riad"}
                                value="riad"
                              />
                            }
                            label="Riad"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                listingType: e.target.checked ? "riad" : null,
                              })
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "&.Mui-checked": {
                                    color: "red",
                                  },
                                }}
                                value="land"
                              />
                            }
                            label={t("land")}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                listingType: e.target.checked ? "land" : null,
                              })
                            }
                          />

                          {/* Add similar FormControlLabel for other listing types */}
                        </div>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="w-full px-4 rounded h-[2px] bg-gray-100 mt-4 mb-4"></div>
                  <p className="text-lg">{t("price")}</p>
                  <div className="flex justify-center items-center w-full px-8">
                    <Slider
                      value={priceRange}
                      onChange={handlePriceChange}
                      valueLabelDisplay="auto"
                      min={0} // Always set minimum to 0
                      max={filters.type === "rent" ? 100000 : 100000000} // Max based on type: 100000 for rent, 1000000 for sale
                      step={filters.type === "rent" ? 1000 : 100000}
                      sx={{
                        color: "red", // Slider color
                        "& .MuiSlider-thumb": {
                          backgroundColor: "white",
                        },
                      }}
                    />
                  </div>{" "}
                  <div className="flex justify-between w-full px-8">
                    <span>{`${new Intl.NumberFormat("en-US").format(
                      priceRange[0]
                    )} DH`}</span>
                    <span>{`${new Intl.NumberFormat("en-US").format(
                      priceRange[1]
                    )} DH`}</span>
                  </div>
                  <div className="w-full px-4 rounded h-[2px] bg-gray-100 mt-4 mb-4"></div>
                  <p className="text-lg">{t("size")}</p>
                  <div className="flex justify-center items-center w-full px-8">
                    <Slider
                      value={sizeRange}
                      onChange={handleSizeChange}
                      valueLabelDisplay="auto"
                      min={0} // Always set minimum to 0
                      max={10000} // Max based on type: 100000 for rent, 1000000 for sale
                      step={100}
                      sx={{
                        color: "red", // Slider color
                        "& .MuiSlider-thumb": {
                          backgroundColor: "white",
                        },
                      }}
                    />
                  </div>
                  <div className="flex justify-between w-full px-8">
                    <span>{`${new Intl.NumberFormat("en-US").format(
                      sizeRange[0]
                    )} m²`}</span>
                    <span>{`${new Intl.NumberFormat("en-US").format(
                      sizeRange[1]
                    )} m²`}</span>
                  </div>
                  <div className="w-full px-4 rounded h-[2px] bg-gray-100 mt-4 mb-4"></div>
                </div>
                <div className="grid grid-cols-1 px-4 mb-24">
                  <p className="text-lg mx-auto w-full flex justify-center mb-2">
                    {t("Extras")}
                  </p>{" "}
                  {/* Changed from grid to flex for better layout */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="parking"
                        checked={
                          filters.parking !== undefined
                            ? filters.parking
                            : false
                        } // Check for undefined and default to false
                        value="parking"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="mr-2"
                        sx={{
                          color: "red",
                          "&.Mui-checked": {
                            color: "red",
                          },
                        }}
                      />
                    }
                    label="Parking"
                    onChange={(e) =>
                      setFilters({ ...filters, parking: e.target.checked })
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="furnished"
                        checked={
                          filters.furnished !== undefined
                            ? filters.furnished
                            : false
                        } // Check for undefined and default to false
                        value="furnished"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="mr-2"
                        sx={{
                          color: "red",
                          "&.Mui-checked": {
                            color: "red",
                          },
                        }}
                      />
                    }
                    label={t("furnished")}
                    onChange={(e) =>
                      setFilters({ ...filters, furnished: e.target.checked })
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="elevator"
                        checked={
                          filters.elevator !== undefined
                            ? filters.elevator
                            : false
                        } // Check for undefined and default to false
                        value="elevator"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="mr-2"
                        sx={{
                          color: "red",
                          "&.Mui-checked": {
                            color: "red",
                          },
                        }}
                      />
                    }
                    label={t("elevator")}
                    onChange={(e) =>
                      setFilters({ ...filters, elevator: e.target.checked })
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="airCondition"
                        checked={
                          filters.airCondition !== undefined
                            ? filters.airCondition
                            : false
                        } // Check for undefined and default to false
                        value="airCondition"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="mr-2"
                        sx={{
                          color: "red",
                          "&.Mui-checked": {
                            color: "red",
                          },
                        }}
                      />
                    }
                    label="A/C"
                    onChange={(e) =>
                      setFilters({ ...filters, airCondition: e.target.checked })
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="pool"
                        checked={
                          filters.pool !== undefined ? filters.pool : false
                        } // Check for undefined and default to false
                        value="pool"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="mr-2"
                        sx={{
                          color: "red",
                          "&.Mui-checked": {
                            color: "red",
                          },
                        }}
                      />
                    }
                    label={t("pool")}
                    onChange={(e) =>
                      setFilters({ ...filters, pool: e.target.checked })
                    }
                  />
                </div>
              </div>
              <div className="py-4 border-t-[1px] border-gray-200 bg-white absolute bottom-0  w-full  px-4">
                <button
                  className=" w-1/2  opacity-70 text-sm hover:opacity-100 cursor-pointer transition ease-in-out duration-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleResetFilters();
                  }}
                >
                  {t("reset")}
                </button>
                <button
                  className=" w-1/2 px-4 py-3 bg-custom-red text-white rounded-lg "
                  onClick={(e) => {
                    handleSearch();
                    handleCloseFilters();
                  }}
                >
                  {t("apply")}
                </button>
              </div>
            </div>
          )}

          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              checked={showMap}
              onChange={toggleMapVisibility}
            />
            <div
              className={`relative w-16 h-9 bg-gray-100 shadow-md shadow-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-red-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:start-[4px] after:bg-white after:border-gray-200 after:border after:rounded-full after:h-7 after:w-7 after:shadow-md after:transition-all dark:border-gray-600 peer-checked:bg-red-600`}
            >
              {" "}
              <span
                className={`absolute text-center items-center justify-center w-1/2 px-3 top-[20%] right-2.5  text-xs font-medium  text-gray-900 dark:text-white`}
              >
                <CiMap style={{ color: "white" }} size={22} />
              </span>
            </div>
          </label>
        </div>
      </div>

      <div className="flex flex-col overflow-hidden md:flex-row">
        <div className="hidden md:block z-30 md:w-3/5">
          <div className="top-0 w-full col-span-1 overflow-hidden ">
            <div className="rounded">
              <div className=" w-full h-screen md:h-[calc(100vh-4rem)]">
                <ReactMapGL
                  {...viewport}
                  width="100%"
                  height="100%"
                  ref={(map) => (mapRef.current = map)}
                  mapStyle={mapStyle}
                  onMoveStart={(evt) => {
                    setSelectedListing(null);
                    setViewport(evt.viewport);
                  }}
                  onMoveEnd={handleMapMove}
                  mapboxApiAccessToken={
                    process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
                  }
                >
                  {listings &&
                    listings.map((listing) => (
                      <Marker
                        key={listing.id}
                        latitude={listing.data.latitude}
                        longitude={listing.data.longitude}
                        onClick={() => handleMarkerClick(listing)}
                      >
                        <div className="">
                          <div
                            className={`marker relative  hover:text-red-700 border-gray-200 border-2 z-50 bg-white px-2 py-1 shadow-lg font-semibold rounded-xl text-xs ${
                              hoveredListingId === listing.id
                                ? "text-red-700"
                                : "text-black"
                            }`}
                          >
                            {listing.data.regularPrice >= 1000000
                              ? `${(
                                  listing.data.regularPrice / 1000000
                                ).toFixed(1)}M DH`
                              : `${(listing.data.regularPrice / 1000).toFixed(
                                  1
                                )}K DH`}
                            <div className="absolute w-3 h-3 bg-white transform rotate-45 -bottom-2 left-1/2 -translate-x-1/2 border-b-2 border-r-2 border-gray-200"></div>
                          </div>
                          {new Date() -
                            new Date(listing.data.timestamp.toDate()) <
                            25 * 24 * 60 * 60 * 1000 && (
                            <div className="absolute -top-3 left-1 font-semibold bg-red-600 text-super-small text-white rounded text-xs px-1">
                              {" "}
                              NEW
                            </div>
                          )}
                        </div>
                      </Marker>
                    ))}
                  {selectedMarker && selectedListing && (
                    <Popup
                      latitude={selectedMarker.latitude}
                      longitude={selectedMarker.longitude}
                      onClose={() => setSelectedListing(null)}
                      closeButton={true}
                      closeOnClick={false}
                      tipSize={0}
                      anchor="top" // Adjust the anchor position as needed
                      style={{
                        maxWidth: "280px",
                        minWidth: "280px",
                        maxHeight: "300px",
                        minHeight: "300px",
                      }}
                    >
                      {/* Ensure the MarkerPopup covers the entire viewport */}
                      <div
                        style={{
                          justifyContent: "center",
                          position: "absolute",
                          top: -4,
                          left: -10,
                          right: 0,
                          bottom: 0,
                          maxWidth: "300px",
                          minWidth: "300px",
                          maxHeight: "300px",
                          minHeight: "300px",
                        }}
                      >
                        <MarkerPopup
                          listing={selectedListing.data}
                          id={selectedListing.id}
                        />
                      </div>
                    </Popup>
                  )}
                  <div style={{ position: "absolute", right: 10, top: 10 }}>
                    <NavigationControl />
                  </div>
                  <div style={{ position: "absolute", left: 10, top: 10 }}>
                    <div className="flex border-[1px] shadow-sm shadow-gray-500 border-black border-opacity-20 rounded flex-col">
                      <button
                        type="button"
                        className={`p-2 transition hover:bg-gray-400 ease-in-out duration-150 text-lg  rounded ${
                          isActive ? "bg-gray-400 border-white" : "bg-white"
                        }`}
                        onClick={handleStyleChange}
                      >
                        {" "}
                        <MdSatelliteAlt />
                      </button>
                    </div>
                  </div>
                </ReactMapGL>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="hidden md:block md:w-2/5 z-40 shadow-xl shadow-gray-700 overflow-y-auto">
          <p className="mb-5 md:text-xl px-3 mt-5 ">
            {listingsWithinViewport ? listingsWithinViewport.length : 0}{" "}
            {t("availablelistings")}{" "}
          </p>{" "}
          <div className="flex px-3">
            <div className="w-1/2 justify-start">
              <p className="mb-4 text-sm md:text-md">
                {listingsWithinViewport
                  ? listingsWithinViewport.length > 10
                    ? "10-"
                    : listingsWithinViewport.length
                  : 0}{" "}
                {t("listings")}{" "}
              </p>
            </div>
            <div className="flex px-2 w-1/2 justify-end">
              <p
                className="flex h-2/3 text-xs xl:text-sm justify-start text-red-600 font-semibold hover:text-red-800 cursor-pointer"
                onClick={handleMenuOpen}
              >
                {" "}
                {selectedSortingOption.label !== "" &&
                  `${selectedSortingOption.label}`}
                <IoIosArrowDown className="mt-1 ml-1" />
                <Menu
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                >
                  {sortingOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => handleSortOrderChange(option)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </p>
            </div>
          </div>
          <div className="items-center px-2 mb-6">
            {loading ? (
              <ul className="w-full gap-4 sm:grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
                <ListingPlaceholder />
                <ListingPlaceholder />
                <ListingPlaceholder />
                <ListingPlaceholder />
                <ListingPlaceholder />
              </ul>
            ) : listings.length > 0 ? (
              <>
                <main className="flex flex-col mx-auto items-center justify-center ">
                  <ul className="w-full  sm:grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
                    {listingsWithinViewport
                      .slice(0, listingsToShow)
                      .map((listing) => (
                        <ListingItemHover
                          key={listing.id}
                          id={listing.id}
                          listing={listing.data}
                          setHoveredListingId={setHoveredListingId}
                        />
                      ))}
                  </ul>
                  {listingsToShow < listings.length && (
                    <button
                      className="text-black  hover:bg-gray-100 transition-all duration ease-in-out mt-4 flex gap-2 items-center hover:underline justify-center mx-auto p-2 rounded-full px-4"
                      onClick={showMoreListings}
                    >
                      {t("showmore")}
                      <FaAngleDown className="mt-1" />
                    </button>
                  )}
                </main>
              </>
            ) : (
              <div className="flex flex-col justify-center items-center py-26 h-2/3">
                <h1 className="text-lg md:text-2xl">
                  {t("nomatchingproperties")}
                </h1>
                <p className="text-gray-400 text-sm md:text-md">
                  {t("trymodifyingfilters")}
                </p>
              </div>
            )}
          </div>
          <Footer />
        </div>

        <div className="md:hidden h-screen overflow-y-auto relative ">
          <div className="top-0 w-full rounded h-[100%]">
            <div className="rounded h-full ">
              <div className=" w-full h-full">
                <ReactMapGL
                  {...viewport}
                  width="100%"
                  height="100%"
                  ref={(map) => (mobileMapRef.current = map)}
                  mapStyle={mapStyle}
                  onMoveStart={(evt) => {
                    setViewport(evt.viewport);
                    setTimeout(() => {
                      setSelectedListing(null);
                    }, 500); // 1000 milliseconds = 1 second
                  }}
                  onMoveEnd={handleMapMove}
                  mapboxApiAccessToken={
                    process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
                  }
                >
                  {listings &&
                    listings.map((listing) => (
                      <Marker
                        key={listing.id}
                        latitude={listing.data.latitude}
                        longitude={listing.data.longitude}
                        onClick={() => handleMobileMarkerClick(listing)}
                      >
                        <div>
                          <div className="marker relative hover:text-red-700 border-gray-200 border-2 z-50 bg-white px-2 py-1 shadow-lg font-semibold rounded-xl text-xs">
                            {listing.data.regularPrice >= 1000000
                              ? `${(
                                  listing.data.regularPrice / 1000000
                                ).toFixed(1)}M DH`
                              : `${(listing.data.regularPrice / 1000).toFixed(
                                  1
                                )}K DH`}
                            <div className="absolute w-3 h-3 bg-white transform rotate-45 -bottom-2 left-1/2 -translate-x-1/2 border-b-2 border-r-2 border-gray-200"></div>
                          </div>
                          {new Date() -
                            new Date(listing.data.timestamp.toDate()) <
                            25 * 24 * 60 * 60 * 1000 && (
                            <div className="absolute -top-3 left-1 font-semibold bg-red-600 text-super-small text-white rounded text-xs px-1">
                              {" "}
                              NEW
                            </div>
                          )}
                        </div>
                      </Marker>
                    ))}
                  {selectedMarker && selectedListing && (
                    <div className="flex mx-auto justify-center items-center w-full absolute bottom-20  ">
                      <div className="flex justify-center items-center w-full px-4 max-w-sm">
                        <MarkerPopupSmall
                          listing={selectedListing.data}
                          id={selectedListing.id}
                        />
                      </div>
                    </div>
                  )}
                  <div style={{ position: "absolute", right: 10, top: 10 }}>
                    <NavigationControl />
                  </div>
                  <div style={{ position: "absolute", left: 10, top: 10 }}>
                    <div className="flex border-[1px] shadow-sm shadow-gray-500 border-black border-opacity-20 rounded flex-col">
                      <button
                        type="button"
                        className={`p-2 text-lg rounded ${
                          isActive ? "bg-gray-400 border-white" : "bg-white"
                        }`}
                        onClick={handleStyleChange}
                      >
                        {" "}
                        <MdSatelliteAlt />
                      </button>
                    </div>
                  </div>
                </ReactMapGL>{" "}
              </div>
            </div>
          </div>

          <div
            className={`bg-white z-30 md:hidden transition-top duration-500 overflow-y-auto absolute ${
              showMap ? "hidden " : "top-[0%]"
            } `}
          >
            <div className="px-2">
              <p className="text-center mb-5 md:text-xl px-3 mt-5">
                {listingsWithinViewport ? listingsWithinViewport.length : 0}{" "}
                {t("availablelistings")}
              </p>
              <div className="flex px-2">
                <div className="w-1/2 justify-start">
                  <p className="mb-4 px-3 text-sm md:text-md">
                    {listingsWithinViewport
                      ? listingsWithinViewport.length > 10
                        ? "10-"
                        : listingsWithinViewport.length
                      : 0}{" "}
                    {t("listings")}{" "}
                  </p>
                </div>
                <div className="flex px-2 w-1/2 justify-end">
                  <p
                    className="flex h-2/3 text-sm md:text-md justify-start text-red-600 font-semibold hover:text-red-800 cursor-pointer"
                    onClick={handleMenuOpen}
                  >
                    {" "}
                    {selectedSortingOption.label !== "" &&
                      `${selectedSortingOption.label}`}
                    <IoIosArrowDown className="mt-1 ml-1" />
                    <Menu
                      anchorEl={menuAnchorEl}
                      open={Boolean(menuAnchorEl)}
                      onClose={handleMenuClose}
                    >
                      {sortingOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          onClick={() => handleSortOrderChange(option)}
                        >
                          {option.value === "newest" ? (
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <LuArrowUpNarrowWide
                                style={{ marginRight: "8px" }}
                              />{" "}
                              {option.label}
                            </span>
                          ) : option.value === "oldest" ? (
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <LuArrowDownNarrowWide
                                style={{ marginRight: "8px" }}
                              />{" "}
                              {option.label}
                            </span>
                          ) : option.value === "asc" ? (
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <LuArrowDownNarrowWide
                                style={{ marginRight: "8px" }}
                              />{" "}
                              {option.label}
                            </span>
                          ) : option.value === "desc" ? (
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <LuArrowUpNarrowWide
                                style={{ marginRight: "8px" }}
                              />{" "}
                              {option.label}
                            </span>
                          ) : (
                            option.label
                          )}
                        </MenuItem>
                      ))}
                    </Menu>
                  </p>
                </div>
              </div>
              <div className="items-center px-2 mb-6">
                {loading ? (
                  <ul className="w-full gap-4 space-y-2 sm:grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
                    <ListingPlaceholder />
                    <ListingPlaceholder />
                    <ListingPlaceholder />
                    <ListingPlaceholder />
                  </ul>
                ) : listings.length > 0 ? (
                  <>
                    <main className="flex flex-col mx-auto items-center justify-center ">
                      <ul className="w-full  grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
                        {listingsWithinViewport
                          .slice(0, listingsToShow)
                          .map((listing) => (
                            <ListingItem
                              key={listing.id}
                              id={listing.id}
                              listing={listing.data}
                            />
                          ))}
                      </ul>
                      {listingsToShow < listings.length && (
                        <button
                          className="text-black  hover:bg-gray-100 transition-all duration ease-in-out mt-4 flex gap-2 items-center hover:underline justify-center mx-auto p-2 rounded-full px-4"
                          onClick={showMoreListings}
                        >
                          {t("showmore")}
                          <FaAngleDown className="mt-1" />
                        </button>
                      )}
                    </main>
                  </>
                ) : (
                  <div className="flex flex-col justify-center items-center py-26 h-2/3">
                    <h1 className="text-lg md:text-2xl">
                      {t("nomatchingproperties")}
                    </h1>
                    <p className="text-gray-400 text-sm md:text-md">
                      {t("trymodifyingfilters")}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
