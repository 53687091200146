// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Import getAuth
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCSMkevDefQAALbyMpaitAUKuxW2gxXb7E",
  authDomain: "novio-91bc4.firebaseapp.com",
  projectId: "novio-91bc4",
  storageBucket: "novio-91bc4.appspot.com",
  messagingSenderId: "301570914083",
  appId: "1:301570914083:web:76b30bfbf14c71752b3e96",
  measurementId: "G-Y9HZ0J1H9M",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app); // Pass app instance to getFirestore
export const auth = getAuth(app); // Initialize and export auth
export const analytics = getAnalytics(app); // Initialize and export analytics

