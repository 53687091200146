import React, { useState, useEffect, useMemo } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { GrNext, GrPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";
import StarRating from "../components/StartRating";
import moment from "moment";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Bar from "../components/Bar";
import { useNavigate } from "react-router-dom";




export default function AgentList() {
  const [agents, setAgents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSection, setSelectedSection] = useState("agents");
  const navigate = useNavigate();
  const [language, setLanguage] = useState("");
  const [loading, setLoading] = useState(true); // State variable for loading
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  moment.locale(i18n.language);
  const [cities, setCities] = useState(""); // New state for selected city
  const MoroccanCities = useMemo(
    () => [
      "Agadir",
      "Al Hoceima",
      "Azemmour",
      "Beni Mellal",
      "Boujdour",
      "Casablanca",
      "Chefchaouen",
      "Dakhla",
      "El Jadida",
      "Erfoud",
      "Essaouira",
      "Fes",
      "Fnideq",
      "Guelmim",
      "Ifrane",
      "Kénitra",
      "Khouribga",
      "Laayoune",
      "Larache",
      "Marrakech",
      "Meknes",
      "Mohammedia",
      "Nador",
      "Ouarzazate",
      "Oujda",
      "Rabat",
      "Safi",
      "Salé",
      "Tangier",
      "Taza",
      "Tétouan",
      "Tiznit",
    ],
    []
  );
  
   useEffect(() => {
     async function fetchAgents() {
       try {
         const agentsCollection = collection(db, "agents");
         const agentsSnapshot = await getDocs(agentsCollection);

         const agentsData = [];
         for (const doc of agentsSnapshot.docs) {
           const agent = { id: doc.id, ...doc.data() };

           if (agent.status === "approved") {
             // Fetch reviews for the agent
             const reviewsQuery = query(
               collection(db, "agent_reviews"),
               where("agentId", "==", agent.id)
             );
             const reviewsSnapshot = await getDocs(reviewsQuery);
             const reviewsData = reviewsSnapshot.docs.map((reviewDoc) =>
               reviewDoc.data()
             );

             // Calculate number of reviews and average rating
             const numReviews = reviewsData.length;
             const averageRating =
               numReviews > 0
                 ? reviewsData.reduce(
                     (total, review) => total + review.rating,
                     0
                   ) / numReviews
                 : 0;

             // Find the review with the highest rating
             const highestRatedReview = reviewsData.reduce(
               (prev, current) =>
                 prev.rating > current.rating ? prev : current,
               {}
             );

             agent.numReviews = numReviews;
             agent.averageRating = averageRating.toFixed(1);
             agent.highestRatedReviewComment =
               highestRatedReview.comment || "No reviews available";
             agent.highestRatedReviewTimestamp =
               highestRatedReview.timestamp || null;

             // Fetch listings for the agent
             const listingsQuery = query(
               collection(db, "listings"),
               where("userRef", "==", agent.id)
             );
             const listingsSnapshot = await getDocs(listingsQuery);
             const listingsData = listingsSnapshot.docs.map((listingDoc) =>
               listingDoc.data()
             );

             // Count sold listings
             const soldListingsCount = listingsData.filter(
               (listing) => listing.status === "sold"
             ).length;

             agent.soldListingsCount = soldListingsCount;

             agentsData.push(agent);
           }
         }

         // Define weights
         const reviewWeight = 0.3;
         const ratingWeight = 0.5;
         const soldListingsWeight = 0.2;

         // Sort agents based on the weighted combination of metrics
         agentsData.sort((a, b) => {
           const scoreA =
             a.numReviews * reviewWeight +
             a.averageRating * ratingWeight +
             a.soldListingsCount * soldListingsWeight;
           const scoreB =
             b.numReviews * reviewWeight +
             b.averageRating * ratingWeight +
             b.soldListingsCount * soldListingsWeight;
           return scoreB - scoreA;
         });

         setAgents(agentsData);
         setLoading(false); // Set loading state to false when data is fetched
       } catch (error) {
         console.error("Error fetching agents:", error);
       }
     }

     fetchAgents();
   }, []);

    const filteredAgents = useMemo(() => {
      return agents.filter((agent) => {
        const nameMatch = `${agent.firstName} ${agent.lastName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        const cityMatch =
          cities === "" || (agent.cities && agent.cities.includes(cities));
        const languageMatch =
          language === "" ||
          (agent.language && agent.language.includes(language));
        const typeMatch = agent.agentType === "agent";

        return nameMatch && cityMatch && languageMatch && typeMatch;
      });
    }, [agents, searchQuery, cities, language]);
    const filteredBuilders = useMemo(() => {
      return agents.filter((agent) => {
        const nameMatch = `${agent.agency}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        const cityMatch =
          cities === "" || (agent.cities && agent.cities.includes(cities));
        const languageMatch =
          language === "" ||
          (agent.language && agent.language.includes(language));
        const typeMatch = agent.agentType === "builder";

        return nameMatch && cityMatch && languageMatch && typeMatch;
      });
    }, [agents, searchQuery, cities, language]);
    const filteredNotary = useMemo(() => {
      return agents.filter((agent) => {
        const nameMatch = `${agent.firstName} ${agent.lastName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const cityMatch =
          cities === "" || (agent.cities && agent.cities.includes(cities));
        const languageMatch =
          language === "" ||
          (agent.language && agent.language.includes(language));
        const typeMatch = agent.agentType === "notary";
        return nameMatch && cityMatch && languageMatch && typeMatch;
      });
    }, [agents, searchQuery, cities, language]);
    const filteredRenovators = useMemo(() => {
      return agents.filter((agent) => {
        const nameMatch = `${agent.agency}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        const cityMatch =
          cities === "" || (agent.cities && agent.cities.includes(cities));
        const languageMatch =
          language === "" ||
          (agent.language && agent.language.includes(language));
        const typeMatch = agent.agentType === "renovator";

        return nameMatch && cityMatch && languageMatch && typeMatch;
      });
    }, [agents, searchQuery, cities, language]);
    const filteredPhotographers = useMemo(() => {
      return agents.filter((agent) => {
        const nameMatch = `${agent.firstName} ${agent.lastName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        const cityMatch =
          cities === "" || (agent.cities && agent.cities.includes(cities));
        const languageMatch =
          language === "" ||
          (agent.language && agent.language.includes(language));
        const typeMatch = agent.agentType === "photographer";

        return nameMatch && cityMatch && languageMatch && typeMatch;
      });
    }, [agents, searchQuery, cities, language]);
    const filteredDevelopers = useMemo(() => {
      return agents.filter((agent) => {
        const nameMatch = `${agent.firstName} ${agent.lastName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        const cityMatch =
          cities === "" || (agent.cities && agent.cities.includes(cities));
        const languageMatch =
          language === "" ||
          (agent.language && agent.language.includes(language));
        const typeMatch = agent.agentType === "developer";

        return nameMatch && cityMatch && languageMatch && typeMatch;
      });
    }, [agents, searchQuery, cities, language]);
    const filteredArchitects = useMemo(() => {
      return agents.filter((agent) => {
        const nameMatch = `${agent.firstName} ${agent.lastName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        const cityMatch =
          cities === "" || (agent.cities && agent.cities.includes(cities));
        const languageMatch =
          language === "" ||
          (agent.language && agent.language.includes(language));
        const typeMatch = agent.agentType === "architect";

        return nameMatch && cityMatch && languageMatch && typeMatch;
      });
    }, [agents, searchQuery, cities, language]);

    const [currentPage, setCurrentPage] = useState(1);
    const agentsPerPage = 4;
    const indexOfLastAgent = currentPage * agentsPerPage;
    const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;
    const currentAgents = filteredAgents.slice(
      indexOfFirstAgent,
      indexOfLastAgent
    );
    const totalPages = Math.ceil(filteredAgents.length / agentsPerPage);
    const totalNotaryPages = Math.ceil(filteredNotary.length / agentsPerPage);
    const totalBuilderPages = Math.ceil(filteredBuilders.length / agentsPerPage);
    const totalRenovatorPages = Math.ceil(
      filteredRenovators.length / agentsPerPage
    );
    const totalPhotographerPages = Math.ceil(
      filteredPhotographers.length / agentsPerPage
    );
    const totalDevelopersPages = Math.ceil(
      filteredDevelopers.length / agentsPerPage
    );
    const totalArchitectsPages = Math.ceil(
      filteredArchitects.length / agentsPerPage
    );

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    const handlePrevPage = () => {
      setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextPage = () => {
      setCurrentPage((prevPage) => prevPage + 1);
    };

    const currentBuilders = filteredBuilders.slice(
      indexOfFirstAgent,
      indexOfLastAgent
    );
    const currentNotary = filteredNotary.slice(
      indexOfFirstAgent,
      indexOfLastAgent
    );  
    const currentRenovators = filteredRenovators.slice(
      indexOfFirstAgent,
      indexOfLastAgent
    );  
    const currentPhotographers = filteredPhotographers.slice(
      indexOfFirstAgent,
      indexOfLastAgent
    );  
    const currentDevelopers = filteredDevelopers.slice(
      indexOfFirstAgent,
      indexOfLastAgent
    );  
    const currentArchitects = filteredArchitects.slice(
      indexOfFirstAgent,
      indexOfLastAgent
    );  

    const handleSectionChange = (section) => {
      setSelectedSection(section);
      setCurrentPage(1); // Reset pagination to the first page
    };

  return (
    <div className="bg-gray-50 ">
      <Helmet>
        <title>Real Estate Agents in Morocco - Find Your Agent</title>
        <meta
          name="description"
          content="Browse our list of trusted real estate agents in Morocco. Find the perfect agent to help you buy, sell, or rent your property."
        />
        <meta
          name="keywords"
          content="real estate, agents, Morocco, property, buy, sell, rent"
        />
        <meta
          property="og:title"
          content="Real Estate Agents in Morocco - Find Your Agent"
        />
        <meta
          property="og:description"
          content="Browse our list of trusted real estate agents in Morocco. Find the perfect agent to help you buy, sell, or rent your property."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.beytty.com/agentlist" />
        <meta property="og:image" content="/apple-touch-icon.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Real Estate Agents in Morocco - Find Your Agent"
        />
        <meta
          name="twitter:description"
          content="Browse our list of trusted real estate agents in Morocco. Find the perfect agent to help you buy, sell, or rent your property."
        />
        <meta name="twitter:image" content="/apple-touch-icon.png" />

        <title>Agents Immobiliers au Maroc - Trouvez Votre Agent</title>
        <meta
          name="description"
          content="Parcourez notre liste d'agents immobiliers de confiance au Maroc. Trouvez l'agent parfait pour vous aider à acheter, vendre ou louer votre propriété."
        />
        <meta
          name="keywords"
          content="immobilier, agents, Maroc, propriété, acheter, vendre, louer"
        />
        <meta
          property="og:title"
          content="Agents Immobiliers au Maroc - Trouvez Votre Agent"
        />
        <meta
          property="og:description"
          content="Parcourez notre liste d'agents immobiliers de confiance au Maroc. Trouvez l'agent parfait pour vous aider à acheter, vendre ou louer votre propriété."
        />
        <meta
          name="twitter:title"
          content="Agents Immobiliers au Maroc - Trouvez Votre Agent"
        />
        <meta
          name="twitter:description"
          content="Parcourez notre liste d'agents immobiliers de confiance au Maroc. Trouvez l'agent parfait pour vous aider à acheter, vendre ou louer votre propriété."
        />
      </Helmet>
      {loading ? (
        <Bar loading={loading} />
      ) : (
        <>
          <div className="flex overflow-x-auto whitespace-nowrap gap-2 px-8 py-4 max-w-6xl mx-auto scrollbar-hide">
            <span
              className={`inline-block px-3 py-1 rounded-lg cursor-pointer bg-gray-200 ${
                selectedSection === "agents" ? "text-red-600" : ""
              }`}
              onClick={() => handleSectionChange("agents")}
            >
              {t("agents")}
            </span>
            <span
              className={`inline-block px-3 py-1 rounded-lg cursor-pointer bg-gray-200 ${
                selectedSection === "architects" ? "text-red-600" : ""
              }`}
              onClick={() => handleSectionChange("architects")}
            >
              {t("architects")}
            </span>
            <span
              className={`inline-block px-3 py-1 rounded-lg cursor-pointer bg-gray-200 ${
                selectedSection === "developers" ? "text-red-600" : ""
              }`}
              onClick={() => handleSectionChange("developers")}
            >
              {t("developers")}
            </span>
            <span
              className={`inline-block px-3 py-1 rounded-lg cursor-pointer bg-gray-200 ${
                selectedSection === "homeimprovement" ? "text-red-600" : ""
              }`}
              onClick={() => handleSectionChange("homeimprovement")}
            >
              {t("homeimprovement")}
            </span>
            <span
              className={`inline-block px-3 py-1 rounded-lg cursor-pointer bg-gray-200 ${
                selectedSection === "notary" ? "text-red-600" : ""
              }`}
              onClick={() => handleSectionChange("notary")}
            >
              {t("notary")}
            </span>   
            <span
              className={`inline-block px-3 py-1 rounded-lg cursor-pointer bg-gray-200 ${
                selectedSection === "builders" ? "text-red-600" : ""
              }`}
              onClick={() => handleSectionChange("builders")}
            >
              {t("builders")}
            </span>     
            <span
              className={`inline-block px-3 py-1 rounded-lg cursor-pointer bg-gray-200 ${
                selectedSection === "photographers" ? "text-red-600" : ""
              }`}
              onClick={() => handleSectionChange("photographers")}
            >
              {t("photographers")}
            </span>
          </div>

          {selectedSection === "agents" && (
            <div>
              <div className="px-8 py-2 max-w-6xl mx-auto">
                {/* New section for translated categories */}

                <h1 className="font-semibold mb-4 mt-2 custom-font text-xl md:text-3xl">
                  {t("searchagents")} {cities ? cities : t("morocco")}.
                </h1>
                <div className="sm:flex rounded-xl  gap-4 bg-gray-50 mb-8">
                  {" "}
                  <div className="w-full">
                    <p className="mb-1 font-semibold">{t("name")}</p>
                    <input
                      type="text"
                      placeholder={t("searchbyname")}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full border border-gray-300 rounded-xl"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 flex w-full gap-4">
                    <div className="w-full">
                      <p className="mb-1 font-semibold">{t("city")}</p>
                      <select
                        value={cities}
                        onChange={(e) => setCities(e.target.value)}
                        className="w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")}</option>
                        {MoroccanCities.map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                    <div className=" w-full ">
                      <p className="mb-1 font-semibold">{t("language")}</p>
                      <select
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="p-2 w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")} </option>
                        <option value="English">{t("english")}</option>
                        <option value="French">{t("french")}</option>
                        <option value="Arabic">{t("arabic")}</option>
                        <option value="Tamazight">{t("tamazight")}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  {filteredAgents.length === 0 ? (
                    <p className="sm:text-xl text-center mb-4">
                      {t("sorrytext")}
                    </p>
                  ) : (
                    <ul className="grid lg:grid-cols-2 md:gap-4">
                      {currentAgents.map((agent) => (
                        <li
                          key={agent.id}
                          className="flex rounded-xl border-2 hover:bg-gray-100 cursor-pointer bg-white mb-4 p-4 sm:p-8 transform transition-all ease-in-out duration-150 "
                        >
                          <img
                            src={
                              agent.photoURL ? agent.photoURL : "/anonym.png"
                            }
                            alt={`Profile of ${agent.firstName} ${agent.lastName}`}
                            className="h-16 w-16 sm:h-20 sm:w-20 mb-2 rounded-full object-cover"
                          />
                          <div className="flex-col px-8 w-full">
                            <Link
                              to={`/agent/${agent.id}`}
                              className="sm:text-xl hover:text-red-700 hover:underline font-semibold"
                            >{`${agent.firstName} ${agent.lastName}`}</Link>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex text-sm md:text-lg w-full"
                            >
                              {agent.phoneNumber}
                            </a>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex font-light text-gray-400 text-sm md:text-lg w-full"
                            >
                              {agent.agency}
                            </a>
                            <div className="">
                              <div className="md:text-xl">
                                <StarRating
                                  rating={parseFloat(agent.averageRating)}
                                />
                              </div>
                            </div>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.numReviews > 0 ? (
                                <span> {agent.numReviews} </span>
                              ) : (
                                <></>
                              )}
                              {agent.numReviews > 1 ? (
                                t("reviews")
                              ) : agent.numReviews === 1 ? (
                                t("review")
                              ) : (
                                <></>
                              )}
                            </p>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.soldListingsCount > 0 ? (
                                <span> {agent.soldListingsCount} </span>
                              ) : (
                                <></>
                              )}
                              {agent.soldListingsCount > 1 ? (
                                t("soldlistings")
                              ) : agent.soldListingsCount === 1 ? (
                                t("soldlisting")
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                          <div className="hidden items-center justify-center mx-auto w-full flex-col">
                            {agent.highestRatedReviewTimestamp && (
                              <p className="text-md text-red-700 mb-1 font-semibold">
                                {t("reviewon")}{" "}
                                {moment(
                                  agent.highestRatedReviewTimestamp.toDate()
                                ).format("D MMMM YYYY")}
                              </p>
                            )}
                            <p className="text-center">
                              "{agent.highestRatedReviewComment}"
                            </p>
                          </div>
                          <Link
                            to={`/agent/${agent.id}`}
                            className="flex text-2xl items-center md:text-4xl w-full hover:text-red-700 justify-end"
                          >
                            <GrNext />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="hidden md:flex mt-4 font-semibold pagination gap-1 justify-center">
                {filteredAgents.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "40px", // Adjust width as needed for circular buttons
                          height: "40px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredAgents.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
              <div className="flex md:hidden mt-4 text-sm font-semibold gap-1 pagination  justify-center">
                {filteredAgents.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "30px", // Adjust width as needed for circular buttons
                          height: "30px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredAgents.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
              <div className="w-full py-6 mt-6 bg-white">
                <div className="w-full flex items-center max-w-6xl gap-8 mx-auto px-8 my-6">
                  <div className="hidden lg:flex w-1/4">
                    <img
                      alt="Two real estate agents discussing business"
                      src={process.env.PUBLIC_URL + "/professionalagents.jpeg"}
                      className="hidden lg:flex bg-white object-cover"
                    />
                  </div>

                  <div>
                    <p className="text-lg md:text-xl font-semibold">
                      {t("areyouagent")}
                    </p>
                    <p className="md:w-1/2 text-sm md:text-base my-4">
                      {t("areyouagenttext")}
                    </p>
                    <button
                      className="bg-custom-red text-sm md:text-base rounded py-2 px-4 text-white"
                      onClick={() => {
                        navigate("/agent-sign-up");
                      }}
                    >
                      {t("createfreeagentaccount")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedSection === "architects" && (
            <div>
              <div className="px-8 py-2 max-w-6xl mx-auto">
                {/* New section for translated categories */}

                <h1 className="font-semibold mb-4 mt-2 custom-font text-xl md:text-3xl">
                  {t("searcharchitects")} {cities ? cities : t("morocco")}.
                </h1>
                <div className="sm:flex rounded-xl  gap-4 bg-gray-50 mb-8">
                  {" "}
                  <div className="w-full">
                    <p className="mb-1 font-semibold">{t("name")}</p>
                    <input
                      type="text"
                      placeholder={t("searchbyname")}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full border border-gray-300 rounded-xl"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 flex w-full gap-4">
                    <div className="w-full">
                      <p className="mb-1 font-semibold">{t("city")}</p>
                      <select
                        value={cities}
                        onChange={(e) => setCities(e.target.value)}
                        className="w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")}</option>
                        {MoroccanCities.map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                    <div className=" w-full ">
                      <p className="mb-1 font-semibold">{t("language")}</p>
                      <select
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="p-2 w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")} </option>
                        <option value="English">{t("english")}</option>
                        <option value="French">{t("french")}</option>
                        <option value="Arabic">{t("arabic")}</option>
                        <option value="Tamazight">{t("tamazight")}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  {filteredArchitects.length === 0 ? (
                    <p className="sm:text-xl text-center mb-4">
                      {t("sorrytext")}
                    </p>
                  ) : (
                    <ul className="grid lg:grid-cols-2 md:gap-4">
                      {currentArchitects.map((agent) => (
                        <li
                          key={agent.id}
                          className="flex rounded-xl border-2 hover:bg-gray-100 cursor-pointer bg-white mb-4 p-4 sm:p-8 transform transition-all ease-in-out duration-150 "
                        >
                          <img
                            src={
                              agent.photoURL ? agent.photoURL : "/anonym.png"
                            }
                            alt={`Profile of ${agent.firstName} ${agent.lastName}`}
                            className="h-16 w-16 sm:h-20 sm:w-20 mb-2 rounded-full object-cover"
                          />
                          <div className="flex-col px-8 w-full">
                            <Link
                              to={`/agent/${agent.id}`}
                              className="sm:text-xl hover:text-red-700 hover:underline font-semibold"
                            >{`${agent.firstName} ${agent.lastName}`}</Link>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex text-sm md:text-lg w-full"
                            >
                              {agent.phoneNumber}
                            </a>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex font-light text-gray-400 text-sm md:text-lg w-full"
                            >
                              {agent.agency}
                            </a>
                            <div className="">
                              <div className="md:text-xl">
                                <StarRating
                                  rating={parseFloat(agent.averageRating)}
                                />
                              </div>
                            </div>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.numReviews > 0 ? (
                                <span> {agent.numReviews} </span>
                              ) : (
                                <></>
                              )}
                              {agent.numReviews > 1 ? (
                                t("reviews")
                              ) : agent.numReviews === 1 ? (
                                t("review")
                              ) : (
                                <></>
                              )}
                            </p>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.soldListingsCount > 0 ? (
                                <span> {agent.soldListingsCount} </span>
                              ) : (
                                <></>
                              )}
                              {agent.soldListingsCount > 1 ? (
                                t("soldlistings")
                              ) : agent.soldListingsCount === 1 ? (
                                t("soldlisting")
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                          <div className="hidden items-center justify-center mx-auto w-full flex-col">
                            {agent.highestRatedReviewTimestamp && (
                              <p className="text-md text-red-700 mb-1 font-semibold">
                                {t("reviewon")}{" "}
                                {moment(
                                  agent.highestRatedReviewTimestamp.toDate()
                                ).format("D MMMM YYYY")}
                              </p>
                            )}
                            <p className="text-center">
                              "{agent.highestRatedReviewComment}"
                            </p>
                          </div>
                          <Link
                            to={`/agent/${agent.id}`}
                            className="flex text-2xl items-center md:text-4xl w-full hover:text-red-700 justify-end"
                          >
                            <GrNext />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="hidden py-4 md:flex mt-4 font-semibold pagination gap-1 justify-center">
                {filteredArchitects.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalArchitectsPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "40px", // Adjust width as needed for circular buttons
                          height: "40px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredArchitects.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
              <div className="flex py-2 md:hidden mt-4 text-sm font-semibold gap-1 pagination  justify-center">
                {filteredArchitects.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalArchitectsPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "30px", // Adjust width as needed for circular buttons
                          height: "30px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredArchitects.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
            </div>
          )}
          {selectedSection === "notary" && (
            <div>
              <div className="px-8 py-2 max-w-6xl mx-auto">
                {/* New section for translated categories */}

                <h1 className="font-semibold mb-4 mt-2 custom-font text-xl md:text-3xl">
                  {t("searchnotaries")} {cities ? cities : t("morocco")}.
                </h1>
                <div className="sm:flex rounded-xl  gap-4 bg-gray-50 mb-8">
                  {" "}
                  <div className="w-full">
                    <p className="mb-1 font-semibold">{t("name")}</p>
                    <input
                      type="text"
                      placeholder={t("searchbyname")}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full border border-gray-300 rounded-xl"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 flex w-full gap-4">
                    <div className="w-full">
                      <p className="mb-1 font-semibold">{t("city")}</p>
                      <select
                        value={cities}
                        onChange={(e) => setCities(e.target.value)}
                        className="w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")}</option>
                        {MoroccanCities.map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                    <div className=" w-full ">
                      <p className="mb-1 font-semibold">{t("language")}</p>
                      <select
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="p-2 w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")} </option>
                        <option value="English">{t("english")}</option>
                        <option value="French">{t("french")}</option>
                        <option value="Arabic">{t("arabic")}</option>
                        <option value="Tamazight">{t("tamazight")}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  {filteredNotary.length === 0 ? (
                    <p className="sm:text-xl text-center mb-4">
                      {t("sorrytext")}
                    </p>
                  ) : (
                    <ul className="grid lg:grid-cols-2 md:gap-4">
                      {currentNotary.map((agent) => (
                        <li
                          key={agent.id}
                          className="flex rounded-xl border-2 hover:bg-gray-100 cursor-pointer bg-white mb-4 p-4 sm:p-8 transform transition-all ease-in-out duration-150 "
                        >
                          <img
                            src={
                              agent.photoURL ? agent.photoURL : "/anonym.png"
                            }
                            alt={`Profile of ${agent.firstName} ${agent.lastName}`}
                            className="h-16 w-16 sm:h-20 sm:w-20 mb-2 rounded-full object-cover"
                          />
                          <div className="flex-col px-8 w-full">
                            <Link
                              to={`/agent/${agent.id}`}
                              className="sm:text-xl hover:text-red-700 hover:underline font-semibold"
                            >{`${agent.firstName} ${agent.lastName}`}</Link>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex text-sm md:text-lg w-full"
                            >
                              {agent.phoneNumber}
                            </a>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex font-light text-gray-400 text-sm md:text-lg w-full"
                            >
                              {agent.agency}
                            </a>
                            <div className="">
                              <div className="md:text-xl">
                                <StarRating
                                  rating={parseFloat(agent.averageRating)}
                                />
                              </div>
                            </div>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.numReviews > 0 ? (
                                <span> {agent.numReviews} </span>
                              ) : (
                                <></>
                              )}
                              {agent.numReviews > 1 ? (
                                t("reviews")
                              ) : agent.numReviews === 1 ? (
                                t("review")
                              ) : (
                                <></>
                              )}
                            </p>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.soldListingsCount > 0 ? (
                                <span> {agent.soldListingsCount} </span>
                              ) : (
                                <></>
                              )}
                              {agent.soldListingsCount > 1 ? (
                                t("soldlistings")
                              ) : agent.soldListingsCount === 1 ? (
                                t("soldlisting")
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                          <div className="hidden items-center justify-center mx-auto w-full flex-col">
                            {agent.highestRatedReviewTimestamp && (
                              <p className="text-md text-red-700 mb-1 font-semibold">
                                {t("reviewon")}{" "}
                                {moment(
                                  agent.highestRatedReviewTimestamp.toDate()
                                ).format("D MMMM YYYY")}
                              </p>
                            )}
                            <p className="text-center">
                              "{agent.highestRatedReviewComment}"
                            </p>
                          </div>
                          <Link
                            to={`/agent/${agent.id}`}
                            className="flex text-2xl items-center md:text-4xl w-full hover:text-red-700 justify-end"
                          >
                            <GrNext />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="hidden py-4 md:flex mt-4 font-semibold pagination gap-1 justify-center">
                {filteredNotary.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalNotaryPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "40px", // Adjust width as needed for circular buttons
                          height: "40px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredNotary.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
              <div className="flex py-2 md:hidden mt-4 text-sm font-semibold gap-1 pagination  justify-center">
                {filteredNotary.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalNotaryPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "30px", // Adjust width as needed for circular buttons
                          height: "30px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredNotary.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
            </div>
          )}
          {selectedSection === "developers" && (
            <div>
              <div className="px-8 py-2 max-w-6xl mx-auto">
                {/* New section for translated categories */}

                <h1 className="font-semibold mb-4 mt-2 custom-font text-xl md:text-3xl">
                  {t("searchdevelopers")} {cities ? cities : t("morocco")}.
                </h1>
                <div className="sm:flex rounded-xl  gap-4 bg-gray-50 mb-8">
                  {" "}
                  <div className="w-full">
                    <p className="mb-1 font-semibold">{t("name")}</p>
                    <input
                      type="text"
                      placeholder={t("searchbyname")}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full border border-gray-300 rounded-xl"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 flex w-full gap-4">
                    <div className="w-full">
                      <p className="mb-1 font-semibold">{t("city")}</p>
                      <select
                        value={cities}
                        onChange={(e) => setCities(e.target.value)}
                        className="w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")}</option>
                        {MoroccanCities.map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                    <div className=" w-full ">
                      <p className="mb-1 font-semibold">{t("language")}</p>
                      <select
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="p-2 w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")} </option>
                        <option value="English">{t("english")}</option>
                        <option value="French">{t("french")}</option>
                        <option value="Arabic">{t("arabic")}</option>
                        <option value="Tamazight">{t("tamazight")}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  {filteredDevelopers.length === 0 ? (
                    <p className="sm:text-xl text-center mb-4">
                      {t("sorrytext")}
                    </p>
                  ) : (
                    <ul className="grid lg:grid-cols-2 md:gap-4">
                      {currentDevelopers.map((agent) => (
                        <li
                          key={agent.id}
                          className="flex rounded-xl border-2 hover:bg-gray-100 cursor-pointer bg-white mb-4 p-4 sm:p-8 transform transition-all ease-in-out duration-150 "
                        >
                          <img
                            src={
                              agent.photoURL ? agent.photoURL : "/anonym.png"
                            }
                            alt={`Profile of ${agent.firstName} ${agent.lastName}`}
                            className="h-16 w-16 sm:h-20 sm:w-20 mb-2 rounded-full object-cover"
                          />
                          <div className="flex-col px-8 w-full">
                            <Link
                              to={`/agent/${agent.id}`}
                              className="sm:text-xl hover:text-red-700 hover:underline font-semibold"
                            >{`${agent.firstName} ${agent.lastName}`}</Link>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex text-sm md:text-lg w-full"
                            >
                              {agent.phoneNumber}
                            </a>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex font-light text-gray-400 text-sm md:text-lg w-full"
                            >
                              {agent.agency}
                            </a>
                            <div className="">
                              <div className="md:text-xl">
                                <StarRating
                                  rating={parseFloat(agent.averageRating)}
                                />
                              </div>
                            </div>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.numReviews > 0 ? (
                                <span> {agent.numReviews} </span>
                              ) : (
                                <></>
                              )}
                              {agent.numReviews > 1 ? (
                                t("reviews")
                              ) : agent.numReviews === 1 ? (
                                t("review")
                              ) : (
                                <></>
                              )}
                            </p>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.soldListingsCount > 0 ? (
                                <span> {agent.soldListingsCount} </span>
                              ) : (
                                <></>
                              )}
                              {agent.soldListingsCount > 1 ? (
                                t("soldlistings")
                              ) : agent.soldListingsCount === 1 ? (
                                t("soldlisting")
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                          <div className="hidden items-center justify-center mx-auto w-full flex-col">
                            {agent.highestRatedReviewTimestamp && (
                              <p className="text-md text-red-700 mb-1 font-semibold">
                                {t("reviewon")}{" "}
                                {moment(
                                  agent.highestRatedReviewTimestamp.toDate()
                                ).format("D MMMM YYYY")}
                              </p>
                            )}
                            <p className="text-center">
                              "{agent.highestRatedReviewComment}"
                            </p>
                          </div>
                          <Link
                            to={`/agent/${agent.id}`}
                            className="flex text-2xl items-center md:text-4xl w-full hover:text-red-700 justify-end"
                          >
                            <GrNext />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="hidden py-4 md:flex mt-4 font-semibold pagination gap-1 justify-center">
                {filteredDevelopers.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalDevelopersPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "40px", // Adjust width as needed for circular buttons
                          height: "40px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredDevelopers.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
              <div className="flex py-2 md:hidden mt-4 text-sm font-semibold gap-1 pagination  justify-center">
                {filteredDevelopers.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalDevelopersPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "30px", // Adjust width as needed for circular buttons
                          height: "30px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredDevelopers.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
            </div>
          )}
          {selectedSection === "homeimprovement" && (
            <div>
              <div className="px-8 py-2 max-w-6xl mx-auto">
                {/* New section for translated categories */}

                <h1 className="font-semibold mb-4 mt-2 custom-font text-xl md:text-3xl">
                  {t("searchrenovators")} {cities ? cities : t("morocco")}.
                </h1>
                <div className="sm:flex rounded-xl  gap-4 bg-gray-50 mb-8">
                  {" "}
                  <div className="w-full">
                    <p className="mb-1 font-semibold">{t("name")}</p>
                    <input
                      type="text"
                      placeholder={t("searchbyname")}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full border border-gray-300 rounded-xl"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 flex w-full gap-4">
                    <div className="w-full">
                      <p className="mb-1 font-semibold">{t("city")}</p>
                      <select
                        value={cities}
                        onChange={(e) => setCities(e.target.value)}
                        className="w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")}</option>
                        {MoroccanCities.map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                    <div className=" w-full ">
                      <p className="mb-1 font-semibold">{t("language")}</p>
                      <select
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="p-2 w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")} </option>
                        <option value="English">{t("english")}</option>
                        <option value="French">{t("french")}</option>
                        <option value="Arabic">{t("arabic")}</option>
                        <option value="Tamazight">{t("tamazight")}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  {filteredRenovators.length === 0 ? (
                    <p className="sm:text-xl text-center mb-4">
                      {t("sorrytext")}
                    </p>
                  ) : (
                    <ul className="grid lg:grid-cols-2 md:gap-4">
                      {currentRenovators.map((agent) => (
                        <li
                          key={agent.id}
                          className="flex rounded-xl border-2 hover:bg-gray-100 cursor-pointer bg-white mb-4 p-4 sm:p-8 transform transition-all ease-in-out duration-150 "
                        >
                          <img
                            src={
                              agent.photoURL ? agent.photoURL : "/anonym.png"
                            }
                            alt={`Profile of ${agent.firstName} ${agent.lastName}`}
                            className="h-16 w-16 sm:h-20 sm:w-20 mb-2 rounded-full object-cover"
                          />
                          <div className="flex-col px-8 w-full">
                            <Link
                              to={`/agent/${agent.id}`}
                              className="sm:text-xl hover:text-red-700 hover:underline font-semibold"
                            >{`${agent.firstName} ${agent.lastName}`}</Link>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex text-sm md:text-lg w-full"
                            >
                              {agent.phoneNumber}
                            </a>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex font-light text-gray-400 text-sm md:text-lg w-full"
                            >
                              {agent.agency}
                            </a>
                            <div className="">
                              <div className="md:text-xl">
                                <StarRating
                                  rating={parseFloat(agent.averageRating)}
                                />
                              </div>
                            </div>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.numReviews > 0 ? (
                                <span> {agent.numReviews} </span>
                              ) : (
                                <></>
                              )}
                              {agent.numReviews > 1 ? (
                                t("reviews")
                              ) : agent.numReviews === 1 ? (
                                t("review")
                              ) : (
                                <></>
                              )}
                            </p>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.soldListingsCount > 0 ? (
                                <span> {agent.soldListingsCount} </span>
                              ) : (
                                <></>
                              )}
                              {agent.soldListingsCount > 1 ? (
                                t("soldlistings")
                              ) : agent.soldListingsCount === 1 ? (
                                t("soldlisting")
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                          <div className="hidden items-center justify-center mx-auto w-full flex-col">
                            {agent.highestRatedReviewTimestamp && (
                              <p className="text-md text-red-700 mb-1 font-semibold">
                                {t("reviewon")}{" "}
                                {moment(
                                  agent.highestRatedReviewTimestamp.toDate()
                                ).format("D MMMM YYYY")}
                              </p>
                            )}
                            <p className="text-center">
                              "{agent.highestRatedReviewComment}"
                            </p>
                          </div>
                          <Link
                            to={`/agent/${agent.id}`}
                            className="flex text-2xl items-center md:text-4xl w-full hover:text-red-700 justify-end"
                          >
                            <GrNext />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="hidden py-4 md:flex mt-4 font-semibold pagination gap-1 justify-center">
                {filteredRenovators.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalRenovatorPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "40px", // Adjust width as needed for circular buttons
                          height: "40px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredRenovators.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
              <div className="flex py-2 md:hidden mt-4 text-sm font-semibold gap-1 pagination  justify-center">
                {filteredRenovators.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalRenovatorPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "30px", // Adjust width as needed for circular buttons
                          height: "30px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredRenovators.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
            </div>
          )}
          {selectedSection === "builders" && (
            <div>
              <div className="px-8 py-2 max-w-6xl mx-auto">
                {/* New section for translated categories */}

                <h1 className="font-semibold mb-4 mt-2 custom-font text-xl md:text-3xl">
                  {t("searchbuilders")} {cities ? cities : t("morocco")}.
                </h1>
                <div className="sm:flex rounded-xl  gap-4 bg-gray-50 mb-8">
                  {" "}
                  <div className="w-full">
                    <p className="mb-1 font-semibold">{t("name")}</p>
                    <input
                      type="text"
                      placeholder={t("searchbyname")}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full border border-gray-300 rounded-xl"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 flex w-full gap-4">
                    <div className="w-full">
                      <p className="mb-1 font-semibold">{t("city")}</p>
                      <select
                        value={cities}
                        onChange={(e) => setCities(e.target.value)}
                        className="w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")}</option>
                        {MoroccanCities.map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                    <div className=" w-full ">
                      <p className="mb-1 font-semibold">{t("language")}</p>
                      <select
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="p-2 w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")} </option>
                        <option value="English">{t("english")}</option>
                        <option value="French">{t("french")}</option>
                        <option value="Arabic">{t("arabic")}</option>
                        <option value="Tamazight">{t("tamazight")}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  {filteredBuilders.length === 0 ? (
                    <p className="sm:text-xl text-center mb-4">
                      {t("sorrytext")}
                    </p>
                  ) : (
                    <ul className="grid lg:grid-cols-2 md:gap-4">
                      {currentBuilders.map((agent) => (
                        <li
                          key={agent.id}
                          className="flex rounded-xl border-2 hover:bg-gray-100 cursor-pointer bg-white mb-4 p-4 sm:p-8 transform transition-all ease-in-out duration-150 "
                        >
                          <img
                            src={
                              agent.photoURL ? agent.photoURL : "/anonym.png"
                            }
                            alt={`Profile of ${agent.agency}`}
                            className="h-16 w-16 sm:h-20 sm:w-20 mb-2 rounded-full object-cover"
                          />
                          <div className="flex-col px-8 w-full">
                            <Link
                              to={`/agent/${agent.id}`}
                              className="sm:text-xl hover:text-red-700 hover:underline font-semibold"
                            >{`${agent.agency}`}</Link>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex text-sm md:text-lg w-full"
                            >
                              {agent.phoneNumber}
                            </a>
                            <div className="">
                              <div className="md:text-xl">
                                <StarRating
                                  rating={parseFloat(agent.averageRating)}
                                />
                              </div>
                            </div>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.numReviews > 0 ? (
                                <span> {agent.numReviews} </span>
                              ) : (
                                <></>
                              )}
                              {agent.numReviews > 1 ? (
                                t("reviews")
                              ) : agent.numReviews === 1 ? (
                                t("review")
                              ) : (
                                <></>
                              )}
                            </p>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.soldListingsCount > 0 ? (
                                <span> {agent.soldListingsCount} </span>
                              ) : (
                                <></>
                              )}
                              {agent.soldListingsCount > 1 ? (
                                t("soldlistings")
                              ) : agent.soldListingsCount === 1 ? (
                                t("soldlisting")
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                          <div className="hidden items-center justify-center mx-auto w-full flex-col">
                            {agent.highestRatedReviewTimestamp && (
                              <p className="text-md text-red-700 mb-1 font-semibold">
                                {t("reviewon")}{" "}
                                {moment(
                                  agent.highestRatedReviewTimestamp.toDate()
                                ).format("D MMMM YYYY")}
                              </p>
                            )}
                            <p className="text-center">
                              "{agent.highestRatedReviewComment}"
                            </p>
                          </div>
                          <Link
                            to={`/agent/${agent.id}`}
                            className="flex text-2xl items-center md:text-4xl w-full hover:text-red-700 justify-end"
                          >
                            <GrNext />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="hidden py-4 md:flex mt-4 font-semibold pagination gap-1 justify-center">
                {filteredBuilders.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalBuilderPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "40px", // Adjust width as needed for circular buttons
                          height: "40px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredBuilders.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
              <div className="flex py-2 md:hidden mt-4 text-sm font-semibold gap-1 pagination  justify-center">
                {filteredBuilders.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalBuilderPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "30px", // Adjust width as needed for circular buttons
                          height: "30px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredBuilders.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
            </div>
          )}
          {selectedSection === "photographers" && (
            <div>
              <div className="px-8 py-2 max-w-6xl mx-auto">
                {/* New section for translated categories */}

                <h1 className="font-semibold mb-4 mt-2 custom-font text-xl md:text-3xl">
                  {t("searchphotographers")} {cities ? cities : t("morocco")}.
                </h1>
                <div className="sm:flex rounded-xl  gap-4 bg-gray-50 mb-8">
                  {" "}
                  <div className="w-full">
                    <p className="mb-1 font-semibold">{t("name")}</p>
                    <input
                      type="text"
                      placeholder={t("searchbyname")}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full border border-gray-300 rounded-xl"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 flex w-full gap-4">
                    <div className="w-full">
                      <p className="mb-1 font-semibold">{t("city")}</p>
                      <select
                        value={cities}
                        onChange={(e) => setCities(e.target.value)}
                        className="w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")}</option>
                        {MoroccanCities.map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                    <div className=" w-full ">
                      <p className="mb-1 font-semibold">{t("language")}</p>
                      <select
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="p-2 w-full border border-gray-300 rounded-xl"
                      >
                        <option value="">{t("all")} </option>
                        <option value="English">{t("english")}</option>
                        <option value="French">{t("french")}</option>
                        <option value="Arabic">{t("arabic")}</option>
                        <option value="Tamazight">{t("tamazight")}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  {filteredPhotographers.length === 0 ? (
                    <p className="sm:text-xl text-center mb-4">
                      {t("sorrytext")}
                    </p>
                  ) : (
                    <ul className="grid lg:grid-cols-2 md:gap-4">
                      {currentPhotographers.map((agent) => (
                        <li
                          key={agent.id}
                          className="flex rounded-xl border-2 hover:bg-gray-100 cursor-pointer bg-white mb-4 p-4 sm:p-8 transform transition-all ease-in-out duration-150 "
                        >
                          <img
                            src={
                              agent.photoURL ? agent.photoURL : "/anonym.png"
                            }
                            alt={`Profile of ${agent.firstName} ${agent.lastName}`}
                            className="h-16 w-16 sm:h-20 sm:w-20 mb-2 rounded-full object-cover"
                          />
                          <div className="flex-col px-8 w-full">
                            <Link
                              to={`/agent/${agent.id}`}
                              className="sm:text-xl hover:text-red-700 hover:underline font-semibold"
                            >{`${agent.firstName} ${agent.lastName}`}</Link>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex text-sm md:text-lg w-full"
                            >
                              {agent.phoneNumber}
                            </a>
                            <a
                              href={`tel:${agent.phoneNumber}`}
                              className="flex font-light text-gray-400 text-sm md:text-lg w-full"
                            >
                              {agent.agency}
                            </a>
                            <div className="">
                              <div className="md:text-xl">
                                <StarRating
                                  rating={parseFloat(agent.averageRating)}
                                />
                              </div>
                            </div>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.numReviews > 0 ? (
                                <span> {agent.numReviews} </span>
                              ) : (
                                <></>
                              )}
                              {agent.numReviews > 1 ? (
                                t("reviews")
                              ) : agent.numReviews === 1 ? (
                                t("review")
                              ) : (
                                <></>
                              )}
                            </p>
                            <p className="text-sm sm:text-md font-light opacity-90">
                              {agent.soldListingsCount > 0 ? (
                                <span> {agent.soldListingsCount} </span>
                              ) : (
                                <></>
                              )}
                              {agent.soldListingsCount > 1 ? (
                                t("soldlistings")
                              ) : agent.soldListingsCount === 1 ? (
                                t("soldlisting")
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                          <div className="hidden items-center justify-center mx-auto w-full flex-col">
                            {agent.highestRatedReviewTimestamp && (
                              <p className="text-md text-red-700 mb-1 font-semibold">
                                {t("reviewon")}{" "}
                                {moment(
                                  agent.highestRatedReviewTimestamp.toDate()
                                ).format("D MMMM YYYY")}
                              </p>
                            )}
                            <p className="text-center">
                              "{agent.highestRatedReviewComment}"
                            </p>
                          </div>
                          <Link
                            to={`/agent/${agent.id}`}
                            className="flex text-2xl items-center md:text-4xl w-full hover:text-red-700 justify-end"
                          >
                            <GrNext />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="hidden py-4 md:flex mt-4 font-semibold pagination gap-1 justify-center">
                {filteredPhotographers.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalPhotographerPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "40px", // Adjust width as needed for circular buttons
                          height: "40px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredPhotographers.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
              <div className="flex py-2 md:hidden mt-4 text-sm font-semibold gap-1 pagination  justify-center">
                {filteredPhotographers.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalPhotographerPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "30px", // Adjust width as needed for circular buttons
                          height: "30px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {filteredPhotographers.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
