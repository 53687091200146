import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import OAuth from "../components/OAuth";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { db } from "../firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export default function AdminSignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });
  const [emailError, setEmailError] = useState("");
  const [termsError, setTermsError] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+212");
  const [passwordError, setPasswordError] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  const { firstName, lastName, email, phoneNumber, password } = formData;

  const handleSignUpAsAgentClick = () => {
    // Navigate to the AgentSignUp page when clicked
    navigate("/agent-sign-up");
  };

  const onPhoneNumberChange = (e) => {
    const enteredNumber = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      phoneNumber: enteredNumber,
    }));
  };

  const onCountryCodeChange = (e) => {
    setSelectedCountryCode(e.target.value);
  };

  const navigate = useNavigate();

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();
    setEmailError("");
    setTermsError("");

    setPasswordError("");
    setLoading(true);

    const domainRegex = /@([a-zA-Z0-9.-]+)$/;
    const match = email.match(domainRegex);

    if (!match) {
      setEmailError("Invalid email address. Please enter a valid email.");
      setLoading(false);
      return;
    }

    const emailDomain = match[1].toLowerCase();
    const allowedDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
      "icloud.com",
      "beytty.com",
      "aol.com",
      "protonmail.com",
      "mail.com",
      "zoho.com",
      "yandex.com",
      "gmx.com",
    ];
    if (!allowedDomains.includes(emailDomain)) {
      setEmailError(
        "Invalid email domain. Please use a supported email provider."
      );
      setLoading(false);
      return;
    }

    if (!agreeToTerms) {
      setTermsError("You must agree to the terms and services.");
      setLoading(false);
      return;
    }

    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;
      const initialProfilePicture = process.env.PUBLIC_URL + "/anonym.png";
      await updateProfile(auth.currentUser, {
        displayName: `${firstName} ${lastName}`,
        photoURL: initialProfilePicture,
      });

      const formDataCopy = { ...formData };
      delete formDataCopy.password;
      formDataCopy.timestamp = serverTimestamp();

      await updateProfile(auth.currentUser, {
        displayName: `${firstName} ${lastName}`,
      });

      await sendEmailVerification(user);

      const completePhoneNumber = `${selectedCountryCode}${phoneNumber}`;
      formDataCopy.phoneNumber = completePhoneNumber;

      await setDoc(doc(db, "admins", user.uid), formDataCopy);
      navigate("/");
    } catch (error) {
      console.error("Firebase authentication error:", error);

      switch (error.code) {
        case "auth/email-already-in-use":
          setEmailError("This email is already in use. Please choose another.");
          break;
        case "auth/invalid-email":
          setEmailError("Invalid email address. Please enter a valid email.");
          break;
        case "auth/weak-password":
          setPasswordError(
            "Password is too weak. Please choose a stronger password."
          );
          break;
        default:
          setEmailError("Something went wrong with the registration");
      }
    } finally {
      setLoading(false);
    }
  }

  const handleOpenTerms = () => {
    setOpenTerms(true);
  };

  const handleCloseTerms = () => {
    setOpenTerms(false);
  };

  return (
    <section>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="hidden md:grid w-full h-48 grid-cols-1 grid-rows-1 overflow-hidden bg-cover md:h-screen bg-blue-100 ">
          <div className='h-full  col-span-1 col-start-1 row-span-1 row-start-1"'>
            <img
              src={process.env.PUBLIC_URL + "/img007.png"}
              alt="key"
              className=" object-cover h-full bg-blend-overlay"
            />
          </div>
          <div className="h-full col-span-1 col-start-1 row-span-1 row-start-1 text-center  ">
            <a href="/">
              <img
                src={process.env.PUBLIC_URL + "/Logo.png"}
                className="mt-12 w-36 md:w-60 text-center hover:opacity-70 transition ease-in-out duration-200 inline-block"
              />
            </a>
          </div>
        </div>
        {loading ? (
          <div className="items-center flex w-full justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <div className="flex items-center overflow-y-auto py-12 bg-white md:my-0 md:h-screen md:shadow-md shadow-black/30">
            <form
              onSubmit={onSubmit}
              className="max-w-md px-4 w-[28rem] mx-auto"
            >
              <a href="/">
                <img
                  src={process.env.PUBLIC_URL + "/Logo.png"}
                  className="md:hidden mb-20 w-40 text-center mx-auto flex hover:opacity-70 transition ease-in-out duration-200 "
                />
              </a>
              <div className=" justify-start text-center md:text-left text-xl md:text-4xl md:py-8 text-black">
                Sign Up
              </div>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={onChange}
                placeholder="First name"
                className="w-full mt-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
              />
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={onChange}
                placeholder="Last name"
                className="w-full mt-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
              />
              <input
                type="email"
                id="email"
                value={email}
                onChange={onChange}
                placeholder="Email address"
                className="w-full mt-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
              />
              {emailError && (
                <p className="text-red-500 text-sm mb-2">{emailError}</p>
              )}
              <div className="flex mt-6">
                <select
                  value={selectedCountryCode}
                  onChange={onCountryCodeChange}
                  className="w-1/4 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
                >
                  <option value="+212">+212</option>
                  <option value="+33">+33</option>
                  <option value="+32">+32</option>
                  <option value="+34">+34</option>
                  <option value="+351">+351</option>
                </select>
                <input
                  type="tel"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={onPhoneNumberChange}
                  className="w-3/4 ml-2 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
                />
              </div>
              <div className="relative mt-6 ">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Password"
                  className="w-full px-4  py-2 text-md  shadow-md color-grey-700 bg-white border-gray-300 rounded transition ease-in-out"
                />
                {showPassword ? (
                  <AiFillEye
                    className="absolute right-3 top-3 text-xl cursor-pointer"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="absolute right-3 top-3 text-xl cursor-pointer"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                )}
              </div>
              {passwordError && (
                <p className="text-red-500 text-sm mb-2">{passwordError}</p>
              )}
              <div className="flex items-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreeToTerms}
                      onChange={(e) => setAgreeToTerms(e.target.checked)}
                      color="primary"
                      sx={{
                        color: "red",
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  sx={{ marginRight: "4px" }}
                  label={<>I agree to the </>}
                />
                <p
                  className="text-blue-700 hover:underline cursor-pointer  mb-[2px]"
                  onClick={handleOpenTerms}
                >
                  terms and conditions.
                </p>
              </div>
              {termsError && (
                <p className="text-red-500 text-sm -mt-2">{termsError}</p>
              )}

              <div className="flex justify-between whitespace-nowrap text-sm sm:text-md">
                <p className="mt-3 mb-6">
                  Have an account?
                  <Link
                    to="/sign-in"
                    className="text-black hover:text-red-700 transition duration-200 ease-in-out font-semibold"
                  >
                    {" "}
                    Sign In
                  </Link>
                </p>
              </div>
              <button
                className="w-full uppercase bg-custom-red rounded text-white px-7 py-3"
                type="submit"
              >
                Submit
              </button>
              <div className="flex items-center my-4 before:border-t before:flex-1 before:border-gray-300 after:border-t after:flex-1 after:border-gray-300">
                <p
                  className="text-center
        font-semibold mx-4"
                >
                  OR
                </p>
              </div>
              <OAuth />
              <p
                className="text-center md:text-lg py-6 font-semibold mx-4 cursor-pointer hover:text-red-700"
                onClick={handleSignUpAsAgentClick}
              >
                Are you an agent ?
              </p>
            </form>
          </div>
        )}
      </div>
      <Dialog open={openTerms} onClose={handleCloseTerms}>
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h2>Terms and Conditions</h2>
            <p>Last updated: [Date]</p>
            <h3>1. Introduction</h3>
            <p>
              Welcome to [Company Name]. These Terms and Conditions govern your
              use of our website and services. By accessing or using our
              services, you agree to comply with these terms.
            </p>
            <h3>2. Accounts</h3>
            <p>
              When you create an account with us, you must provide accurate and
              complete information. You are responsible for maintaining the
              confidentiality of your account and password.
            </p>
            <h3>3. Use of Service</h3>
            <p>
              You agree not to use the service for any illegal or unauthorized
              purpose. You must not violate any laws in your jurisdiction while
              using the service.
            </p>
            <h3>4. Intellectual Property</h3>
            <p>
              All content on this site, including text, graphics, logos, and
              images, is the property of [Company Name] and is protected by
              intellectual property laws.
            </p>
            <h3>5. Termination</h3>
            <p>
              We may terminate or suspend your account and access to the service
              immediately, without prior notice or liability, for any reason,
              including if you breach the terms.
            </p>
            <h3>6. Limitation of Liability</h3>
            <p>
              In no event shall [Company Name], nor its directors, employees,
              partners, agents, suppliers, or affiliates, be liable for any
              indirect, incidental, special, consequential, or punitive damages.
            </p>
            <h3>7. Changes to Terms</h3>
            <p>
              We reserve the right to modify or replace these terms at any time.
              If a revision is material, we will try to provide at least 30
              days' notice prior to any new terms taking effect.
            </p>
            <h3>8. Contact Us</h3>
            <p>
              If you have any questions about these Terms and Conditions, please
              contact us at [Contact Information].
            </p>
            <h3>7. Changes to Terms</h3>
            <p>
              We reserve the right to modify or replace these terms at any time.
              If a revision is material, we will try to provide at least 30
              days' notice prior to any new terms taking effect.
            </p>
            <h3>8. Contact Us</h3>
            <p>
              If you have any questions about these Terms and Conditions, please
              contact us at [Contact Information].
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTerms} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
}
