import {
  getAuth,
  updateProfile,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { TextField, Typography } from "@mui/material";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { PiSignOutBold } from "react-icons/pi";
import { FormControl, InputLabel, MenuItem, Select, Chip } from "@mui/material";
import { LiaUserEditSolid } from "react-icons/lia";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GrPrevious, GrNext } from "react-icons/gr";
import { MdDelete, MdEdit } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner";
import { IoClose } from "react-icons/io5";
import { RxDot } from "react-icons/rx";
import Bar from "../components/Bar";
import { FaCheck } from "react-icons/fa";




export default function Profile() {
  const { t } = useTranslation();
  const auth = getAuth();
  const navigate = useNavigate();
  const [changeDetail, setChangeDetail] = useState(false);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasReviews, setHasReviews] = useState(false);
  const [hasApprovedListing, setHasApprovedListing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [aboutMe, setAboutMe] = useState("");
  const [specialtiesDisabled, setSpecialtiesDisabled] = useState(true);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [agentType, setAgentType] = useState([]);
  const handleSpecialtiesChange = (event) => {
    const selectedSpecialties = event.target.value;
    if (selectedSpecialties.length <= 3) {
      setSelectedSpecialties(selectedSpecialties);
    } else {
      toast.error("You can only choose up to three specialties");
    }
  };
  const [phoneError, setPhoneError] = useState("");
  const [isTipVisible, setIsTipVisible] = useState(true);

  const handleTipClose = () => {
  setIsTipVisible(false);
  };
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
  setIsVisible(false);
  };

  const specialties = [
    "residential",
    "commercial",
    "luxury",
    "property",
    "investment",
    "construction",
    "vacation",
    "land",
    "foreclosures",
    "development",
  ];
  const handleDelete = (value) => {
    setSelectedSpecialties((prevSelected) =>
      prevSelected.filter((selected) => selected !== value)
    );
  };

      const [isVerified, setIsVerified] = useState(false);
      const [user, setUser] = useState(null);
      const [sendingVerification, setSendingVerification] = useState(false);
      const [emailSent, setEmailSent] = useState(false);
      const [error, setError] = useState("");

      useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setUser(user);
            setIsVerified(user.emailVerified);
          } else {
            setUser(null);
            setIsVerified(false);
          }
        });
        return () => unsubscribe();
      }, []);

      const handleResendVerification = async () => {
        if (!user) return;
        setSendingVerification(true);
        setError("");
        try {
          await sendEmailVerification(user);
          setEmailSent(true);
        } catch (error) {
          setError(t("failedemail"));
        } finally {
          setSendingVerification(false);
        }
      };

  const [citiesDisabled, setCitiesDisabled] = useState(true);
  const [selectedCities, setSelectedCities] = useState([]);
  const handleCitiesChange = (event) => {
    const selectedCities = event.target.value;

    if (selectedCities.length <= 3) {
      setSelectedCities(selectedCities);
    } else {
      toast.error("You can only choose up to three Cities");
    }
  };

  const cities = [
    "Agadir",
    "Al Hoceima",
    "Azemmour",
    "Beni Mellal",
    "Boujdour",
    "Casablanca",
    "Chefchaouen",
    "Dakhla",
    "El Jadida",
    "Erfoud",
    "Essaouira",
    "Fes",
    "Fnideq",
    "Guelmim",
    "Ifrane",
    "Kénitra",
    "Khouribga",
    "Laayoune",
    "Larache",
    "Marrakech",
    "Meknes",
    "Mohammedia",
    "Nador",
    "Ouarzazate",
    "Oujda",
    "Rabat",
    "Safi",
    "Salé",
    "Tangier",
    "Taza",
    "Tétouan",
    "Tiznit",
  ];
  const handleCitiesDelete = (value) => {
    setSelectedCities((prevSelected) =>
      prevSelected.filter((selected) => selected !== value)
    );
  };
  const [languageDisabled, setLanguageDisabled] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;

    if (selectedLanguage.length <= 3) {
      setSelectedLanguage(selectedLanguage);
    } else {
      toast.error("You can only choose up to three Language");
    }
  };

  const language = ["Arabic", "French", "English", "Spanish", "Tamazight"];
  const handleLanguageDelete = (value) => {
    setSelectedLanguage((prevSelected) =>
      prevSelected.filter((selected) => selected !== value)
    );
  };

  const [formData, setFormData] = useState({
    firstName: auth.currentUser.displayName.split(" ")[0] || "",
    lastName: auth.currentUser.displayName.split(" ")[1] || "",
    agency: auth.currentUser.displayName.split(" ")[2] || "",
    email: auth.currentUser.email,
    phoneNumber: "",
    photoURL: auth.currentUser.photoURL || "",
    aboutMe: "",
    instagram: "", 
    facebook: "",
    youtube: "",
  });


  const { firstName, lastName, agency, email, photoURL } = formData;
  const [isAgentUser, setIsAgentUser] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [userStatus, setUserStatus] = useState(null);
  const [isMyProfileOpen, setIsMyProfileOpen] = useState(false);

  useEffect(() => {
  let isMounted = true; // Track if the component is mounted

  async function fetchData() {
    try {
      setLoading(true);
      const userUid = auth.currentUser.uid;

      // Check if the user is an agent
      const agentStatus = await isAgent();
      if (isMounted) setIsAgentUser(agentStatus);

      // Check if the user is an admin
      const adminStatus = await isAdmin();
      if (isMounted) setIsAdminUser(adminStatus);

      let userDocRef;
      if (isAdminUser) {
        userDocRef = doc(db, "admins", userUid);
      } else if (isAgentUser) {
        userDocRef = doc(db, "agents", userUid);
      } else {
        userDocRef = doc(db, "users", userUid);
      }

      const userDoc = await getDoc(userDocRef);

      if (isMounted) {
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const status = userData.status;

          setUserStatus(status);
          setAboutMe(userData.aboutMe || "");
          setSelectedSpecialties(userData.specialties || []);
          setSelectedLanguage(userData.language || []);
          setSelectedCities(userData.cities || []);
          setAgentType(userData.agentType || []);

          // Set social media links
          setFormData({
            ...formData,
            instagram: userData.instagram || "",
            facebook: userData.facebook || "",
            youtube: userData.youtube || "",
          });

          const userPhoneNumber = userData.phoneNumber;
          if (!userPhoneNumber) {
            setPhoneError(t("pleaseenteryourphone"));
          } else {
            setPhoneNumber(userPhoneNumber);
          }
          // Fetch reviews for the agent if the user is an agent
          if (isAgentUser) {
            const reviewsRef = collection(db, "agent_reviews");
            const q = query(reviewsRef, where("agentId", "==", userUid));
            const querySnap = await getDocs(q);
            const hasReviews = !querySnap.empty; // Check if there are any reviews
            setHasReviews(hasReviews); // Store the result in state if needed
          }
          // Fetch user listings to check for approved status
          const listingRef = collection(db, "listings");
          const listingQuery = query(
            listingRef,
            where("userRef", "==", userUid),
            where("status", "==", "approved") // Check for approved status
          );
          const listingQuerySnap = await getDocs(listingQuery);
          const hasApprovedListings = !listingQuerySnap.empty; // Check if there are any approved listings
          setHasApprovedListing(hasApprovedListings); // Store the result in state if needed
        } else {
          setUserStatus(null);
        }

        // Fetch user listings
        const listingRef = collection(db, "listings");
        const q = query(
          listingRef,
          where("userRef", "==", userUid),
          orderBy("timestamp", "desc")
        );
        const querySnap = await getDocs(q);
        let listings = [];
        querySnap.forEach((doc) => {
          listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        if (isMounted) setListings(listings);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      if (isMounted) setLoading(false);
    }
  }

  fetchData();

  return () => {
    isMounted = false; // Clean up the mounted flag
  };
  }, [isAgentUser, isAdminUser, t]);

  




  function onLogout() {
    auth.signOut();
    window.scrollTo(0, 0); // Scroll to the top
    navigate("/");
                
  }

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    // Handle changes to phone number
    if (e.target.id === "phoneNumber") {
      setPhoneNumber(e.target.value);
    }
  }

  async function onSubmit() {
    try {
      const usersCollection = isAgentUser ? "agents" : "users";
      const currentUserUid = auth.currentUser.uid;

      // Fetch the current user's document to get the current phone number
      const userDocRef = doc(db, usersCollection, currentUserUid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const currentPhoneNumber = userDocSnapshot.data().phoneNumber;

        // Check if the phone number has actually been changed
        if (currentPhoneNumber !== phoneNumber) {
          // Query the database to see if the new phone number exists
          const existingUsersQuery = query(
            collection(db, usersCollection),
            where("phoneNumber", "==", phoneNumber)
          );
          const querySnapshot = await getDocs(existingUsersQuery);

          if (!querySnapshot.empty) {
            // Phone number already exists
            setPhoneError(t("phonealreadyexists")); // Set error message using translation function
            return; // Exit the function early
          }
        }
      }

      const displayName = `${firstName} ${lastName} ${agency}`;

      // Update display name in Firebase Auth
      await updateProfile(auth.currentUser, {
        displayName,
      });

      // Update the user's information in Firestore
      await updateDoc(userDocRef, {
        firstName,
        lastName,
        agency,
        phoneNumber,
        aboutMe,
        instagram: formData.instagram,
        facebook: formData.facebook,
        youtube: formData.youtube,
        specialties: selectedSpecialties,
        language: selectedLanguage,
        cities: selectedCities,
      });

      toast.success("Profile details updated");
    } catch (error) {
      toast.error("Could not update the profile details");
    }
  }


  const isAgent = async () => {
    const agentDocRef = doc(db, "agents", auth.currentUser.uid);
    const agentDoc = await getDoc(agentDocRef);
    return agentDoc.exists();
  };
  const isAdmin = async () => {
    const adminDocRef = doc(db, "admins", auth.currentUser.uid);
    const adminDoc = await getDoc(adminDocRef);
    return adminDoc.exists();
  };

  const renderGreeting = () => {
    const displayName = `${firstName}${" "}${lastName}`;

    const handleImageChange = async (e) => {
      const file = e.target.files[0];
      setSelectedImage(file);

      if (file) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `profilePictures/${auth.currentUser.uid}`
        );
        await uploadBytes(storageRef, file);
        const imageUrl = await getDownloadURL(storageRef);

        // Update the user's profile picture in Firestore
        const userDocRef = doc(
          db,
          isAgentUser ? "agents" : "users",
          auth.currentUser.uid
        );
        await updateDoc(userDocRef, {
          photoURL: imageUrl,
        });

        // Update the local state
        setFormData((prevState) => ({
          ...prevState,
          photoURL: imageUrl,
        }));

        // Update the current user's photoURL in the auth object
        await updateProfile(auth.currentUser, {
          photoURL: imageUrl,
        });

        toast.success("Profile picture updated");
      }
    };

    if (isAgentUser) {
      return (
        <div className="flex flex-col justify-center items-center w-full mx-auto">
          <div className="flex w-full bg-gray-200 h-20 md:h-40"></div>
          <div className="flex relative -top-10">
            <div className="relative flex items-end ">
              <img
                src={photoURL || process.env.PUBLIC_URL + "/anonym.png"}
                alt="Profile Picture"
                className="h-20 w-20 shadow-lg shadow-gray-400 rounded-full object-cover mr-2"
              />
              <label
                htmlFor="imageInput"
                className="absolute h-6 w-6 top-0 right-2 shadow-lg text-black items-center justify-center bg-white rounded-full p-1 cursor-pointer"
              >
                <input
                  type="file"
                  id="imageInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                <LiaUserEditSolid className="ml-[1px] h-4 w-4 justify-center mx-auto  hover:opacity-40 " />
              </label>
            </div>
          </div>
          {userStatus !== "approved" && (
            <p className="flex relative items-center gap-1 md:text-2xl -mt-4">
              <strong>{displayName}</strong>
              <RiVerifiedBadgeFill
                className="absolute -right-5 text-base md:text-lg"
                style={{ color: "orange" }}
              />
            </p>
          )}

          {userStatus === "approved" && (
            <p className="flex relative items-center gap-1 md:text-2xl -mt-4">
              <strong>{displayName}</strong>
              <RiVerifiedBadgeFill
                className="absolute -right-5 text-base md:text-lg"
                style={{ color: "red" }}
              />
            </p>
          )}

          {userStatus === "approved" && (
            <p className="flex items-center text-sm">{
              t(agentType === "agent" ? "verifiedagent" :
            agentType === "builder" ? "verifiedbuilder" :
            agentType === "developer" ? "verifieddeveloper" :
            agentType === "notary" ? "verifiednotary" :
            agentType === "architect" ? "verifiedarchitect" :
            agentType === "photographer" ? "verifiedphotographer" :
            agentType === "renovator" ? "verifiedrenovator" : "" )}
            </p>
          )}
          {userStatus !== "approved" && (
            <p className="flex items-center gap-1 text-sm md:text-base">
              {t("pendingverification")}{" "}
            </p>
          )}
        </div>
      );
    } else {
      return (
        <div className="flex mt-10 flex-col justify-center items-center w-full mx-auto">
          <div className="flex relative">
            <div className="relative flex items-end">
              {" "}
              <img
                src={photoURL || process.env.PUBLIC_URL + "/anonym.png"}
                alt="Profile Picture"
                className="h-20 w-20 rounded-full object-cover mr-2"
              />
            </div>
          </div>
          <p className="md:text-2xl mt-2">
            <strong>{displayName}</strong>
          </p>
        </div>
      );
    }
  };

  return (
    <div className="bg-gray-50 ">
      {loading ? (
        <div className="">
          <Bar loading={loading} />
        </div>
      ) : (
        <div className="max-w-6xl mx-auto h-full">
          <section className=" max-w-6xl mx-auto flex justify-center items-center  flex-col">
            <div className="w-full px-4 ">
              {isVisible && (
                <>
                  {!isVerified && (
                    <div className="border-2 mx-auto bg-white items-center justify-center text-center flex flex-col my-8 rounded-xl space-y-2 p-4">
                      <div className="w-full ml-auto flex justify-end text-xl">
                        <IoClose
                          onClick={handleClose}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <p className="font-semibold md:text-lg text-gray-600">
                        {t("verifyyouremail")}
                      </p>
                      <p className="text-sm  md:text-md text-gray-600">
                        {t("verifyemail")}
                      </p>
                      {emailSent ? (
                        <p className="text-green-600">{t("emailsent")}</p>
                      ) : (
                        <button
                          onClick={handleResendVerification}
                          disabled={sendingVerification}
                          className="md:w-1/3  text-red-600 hover:text-red-700 px-4 py-2 rounded"
                        >
                          {sendingVerification ? t("sending") : t("resend")}
                        </button>
                      )}
                      {error && <p className="text-red-600">{error}</p>}
                    </div>
                  )}
                </>
              )}
              {isVerified && (
                <>
                  {isAgentUser && agentType === "agent" && (
                    <>
                      {isTipVisible &&
                        (!photoURL || !hasReviews || !hasApprovedListing) && (
                          <div className="w-full rounded-xl border-2 bg-white my-8">
                            <div className="w-full ml-auto flex justify-end text-lg md:text-xl p-4">
                              <IoClose
                                onClick={handleTipClose}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div className="px-4 mb-4 text-xs sm:text-sm text-gray-400">
                              <p className="text-lg text-red-500 font-semibold mb-2">
                                {t("tipsforincreasing")} !
                              </p>
                              <p
                                className={`flex gap-1 items-center ${
                                  photoURL ? "text-red-500" : ""
                                }`}
                              >
                                {photoURL && <FaCheck />}
                                {t(
                                  agentType === "builder" ||
                                    agentType === "homeimprovement" ||
                                    agentType === "developer"
                                    ? "tipsforincreasing4"
                                    : "tipsforincreasing1"
                                )}
                              </p>

                              <p
                                className={`flex gap-1 items-center ${
                                  hasReviews ? "text-red-500" : ""
                                }`}
                              >
                                {hasReviews && <FaCheck />}
                                {t("tipsforincreasing2")}
                              </p>
                              <p
                                className={`flex gap-1 items-center ${
                                  hasApprovedListing ? "text-red-500" : ""
                                }`}
                              >
                                {hasApprovedListing && <FaCheck />}
                                {t("tipsforincreasing3")}
                              </p>
                            </div>
                          </div>
                        )}
                    </>
                  )}
                </>
              )}

              {/* My Profile Section */}
              <div className="mb-8">{renderGreeting()}</div>
              <div className="mb-4 ">
                <div
                  className="flex items-center justify-between "
                  onClick={() => setIsMyProfileOpen(!isMyProfileOpen)}
                >
                  <h2 className="cursor-pointer text-md md:text-xl ">
                    {t("myprofil")}{" "}
                  </h2>
                  <p className="flex items-center">
                    <span
                      onClick={() => {
                        changeDetail && onSubmit();
                        setChangeDetail((prevState) => !prevState);
                        setSpecialtiesDisabled(false);
                        setLanguageDisabled(false);
                        setCitiesDisabled(false);
                      }}
                      className="text-black text-md md:text-xl capitalize hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer"
                    >
                      {changeDetail ? t("applychange") : t("editprofil")}
                    </span>
                  </p>{" "}
                </div>
                <form className="text-md md:text-xl">
                  <div className=" md:flex md:gap-2">
                    <TextField
                      className="bg-white"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused": {
                            boxShadow: "none",
                          },
                          "& input": {
                            boxShadow: "none",
                          },
                          "& textarea": {
                            boxShadow: "none",
                          },
                        },
                      }}
                      id="firstName"
                      label={t("firstname")}
                      value={firstName}
                      disabled={!changeDetail}
                      onChange={onChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    />
                    <TextField
                      className="bg-white"
                      id="lastName"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused": {
                            boxShadow: "none",
                          },
                          "& input": {
                            boxShadow: "none",
                          },
                          "& textarea": {
                            boxShadow: "none",
                          },
                        },
                      }}
                      label={t("lastname")}
                      value={lastName}
                      disabled={!changeDetail}
                      onChange={onChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    />
                  </div>
                  <div className=" md:flex md:gap-2">
                    <TextField
                      className={phoneNumber ? "bg-white" : "bg-red-300"}
                      id="phoneNumber"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused": {
                            boxShadow: "none",
                          },
                          "& input": {
                            boxShadow: "none",
                          },
                          "& textarea": {
                            boxShadow: "none",
                          },
                          backgroundColor: phoneNumber ? "white" : "#fff0f2",
                        },
                      }}
                      label={t("phonenumber")}
                      value={phoneNumber}
                      onChange={onChange}
                      fullWidth
                      disabled={!changeDetail}
                      variant="outlined"
                      margin="normal"
                    />
                    {phoneError && (
                      <p className="text-red-500 text-sm ">{phoneError}</p>
                    )}
                    <TextField
                      className="bg-white"
                      id="email"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused": {
                            boxShadow: "none",
                          },
                          "& input": {
                            boxShadow: "none",
                          },
                          "& textarea": {
                            boxShadow: "none",
                          },
                        },
                      }}
                      label="Email"
                      value={email}
                      disabled
                      onChange={onChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    />
                  </div>
                  {isAgentUser && (
                    <div>
                      <TextField
                        className="bg-white"
                        id="agency"
                        label={t("company")}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused": {
                              boxShadow: "none",
                            },
                            "& input": {
                              boxShadow: "none",
                            },
                            "& textarea": {
                              boxShadow: "none",
                            },
                          },
                        }}
                        value={agency}
                        disabled={!changeDetail}
                        onChange={onChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                      />
                      <TextField
                        className="bg-white"
                        id="aboutMe"
                        inputProps={{ maxLength: 200 }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused": {
                              boxShadow: "none",
                            },
                            "& input": {
                              boxShadow: "none",
                            },
                            "& textarea": {
                              boxShadow: "none",
                            },
                          },
                        }}
                        label={t("aboutme")}
                        value={aboutMe}
                        disabled={!changeDetail}
                        onChange={(e) => setAboutMe(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        margin="normal"
                      />
                      <div className="flex mx-auto justify-end">
                        <Typography variant="caption" color="textSecondary">
                          {aboutMe.length}/200
                        </Typography>
                      </div>

                      {/* Specialties */}
                      <h4 className="text-sm mt-2">
                        {t("specialties")} - {selectedSpecialties.length}/3
                      </h4>

                      <FormControl
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        style={{ backgroundColor: "white" }}
                      >
                        <Select
                          id="specialties"
                          sx={{
                            height: "100px",
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "black", // Change to match the TextField focus border color
                              },
                              "&.Mui-focused": {
                                boxShadow: "none",
                              },
                              "& input": {
                                boxShadow: "none",
                              },
                            },
                          }}
                          multiple
                          value={selectedSpecialties}
                          onChange={handleSpecialtiesChange}
                          renderValue={(selected) => (
                            <div className="flex flex-wrap gap-2">
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={t(value)} // Use translation here
                                  onDelete={() => handleDelete(value)}
                                />
                              ))}
                            </div>
                          )}
                          disabled={!changeDetail || specialtiesDisabled}
                        >
                          {specialties.map((specialty) => (
                            <MenuItem key={specialty} value={specialty}>
                              {t(specialty)} {/* Use translation here */}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* Language */}
                      <h4 className="text-sm mt-2">
                        {t("languages")} - {selectedLanguage.length}/3
                      </h4>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        style={{ backgroundColor: "white" }}
                      >
                        <Select
                          labelId="language-label"
                          id="language"
                          multiple
                          style={{
                            height: "100px",
                          }}
                          value={selectedLanguage}
                          onChange={handleLanguageChange}
                          renderValue={(selected) => (
                            <div className="flex flex-wrap gap-2">
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  onDelete={() => handleLanguageDelete(value)}
                                />
                              ))}
                            </div>
                          )}
                          disabled={!changeDetail || languageDisabled}
                        >
                          {language.map((lang) => (
                            <MenuItem key={lang} value={lang}>
                              {lang}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* Cities */}
                      <h4 className="text-sm mt-2">
                        {t("cities")} - {selectedCities.length}/3
                      </h4>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        style={{ backgroundColor: "white" }}
                      >
                        <Select
                          labelId="city-label"
                          id="city"
                          multiple
                          style={{
                            height: "100px",
                          }}
                          value={selectedCities}
                          onChange={handleCitiesChange}
                          renderValue={(selected) => (
                            <div className="flex flex-wrap gap-2">
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  onDelete={() => handleCitiesDelete(value)}
                                />
                              ))}
                            </div>
                          )}
                          disabled={!changeDetail || citiesDisabled}
                        >
                          {cities.map((city) => (
                            <MenuItem key={city} value={city}>
                              {city}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  {isAgentUser && (
                    <div className=" md:flex md:gap-2">
                      <TextField
                        className="bg-white"
                        id="instagram"
                        label={t("@ Instagram Username")}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused": {
                              boxShadow: "none",
                            },
                            "& input": {
                              boxShadow: "none",
                            },
                            "& textarea": {
                              boxShadow: "none",
                            },
                          },
                        }}
                        value={formData.instagram}
                        disabled={!changeDetail}
                        onChange={onChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                      />
                      <TextField
                        className="bg-white"
                        id="facebook"
                        label={t("@ Facebook Username")}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused": {
                              boxShadow: "none",
                            },
                            "& input": {
                              boxShadow: "none",
                            },
                            "& textarea": {
                              boxShadow: "none",
                            },
                          },
                        }}
                        value={formData.facebook}
                        disabled={!changeDetail}
                        onChange={onChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                      />
                      <TextField
                        className="bg-white"
                        id="youtube"
                        label={t("@ Youtube Username")}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused": {
                              boxShadow: "none",
                            },
                            "& input": {
                              boxShadow: "none",
                            },
                            "& textarea": {
                              boxShadow: "none",
                            },
                          },
                        }}
                        value={formData.youtube}
                        disabled={!changeDetail}
                        onChange={onChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                      />
                    </div>
                  )}{" "}
                </form>
              </div>
              <div className="flex justify-center sm:justify-start whitespace-nowrap mb-16 ">
                <p
                  onClick={onLogout}
                  className="flex items-center gap-1 mt-8 sm:text-lg capitalize hover:text-gray-300 transition ease-in-out duration-200 cursor-pointer font-semibold"
                >
                  <PiSignOutBold />
                  {t("signout")}{" "}
                </p>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}
