import React, { useState } from "react";
import { useNavigate } from "react-router";
import { MdDone } from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { PiNumberCircleOneFill } from "react-icons/pi";
import { PiNumberCircleTwoFill } from "react-icons/pi";
import { PiNumberCircleThreeFill } from "react-icons/pi";
import { PiNumberCircleFourFill } from "react-icons/pi";
import { Helmet } from "react-helmet";


import { useTranslation } from "react-i18next";


export default function Sell() {
  const navigate = useNavigate();
  const [openBeytty, setOpenBeytty] = useState(false);
  const [openAgents, setOpenAgents] = useState(false);
  const [openMyself, setOpenMyself] = useState(false);
    const { t } = useTranslation();


  const handleOpenBeytty = () => {
    setOpenBeytty(true);
  };

  const handleCloseBeytty= () => {
    setOpenBeytty(false);
  };
  const handleOpenAgents = () => {
    setOpenAgents(true);
  };

  const handleCloseAgents= () => {
    setOpenAgents(false);
  };
  const handleOpenMyself = () => {
    setOpenMyself(true);
  };

  const handleCloseMyself= () => {
    setOpenMyself(false);
  };

  return (
    <div className="bg-white">
      <Helmet>
  <title>
    Sell Your Property Quickly - Best Value in Morocco | Beytty
  </title>
  <meta
    name="description"
    content="List your property for sale on Beytty and sell quickly. Connect with potential buyers and get the best value for your home, office, or villa in Morocco with our easy-to-use selling platform."
  />
  <meta
    name="keywords"
    content="sell property, list property, real estate selling, sell quickly, Morocco real estate, property for sale, home selling, office selling, villa selling, Beytty"
  />
  <meta
    property="og:title"
    content="Sell Your Property Quickly - Best Value in Morocco | Beytty"
  />
  <meta
    property="og:description"
    content="List your property for sale on Beytty and sell quickly. Connect with potential buyers and get the best value for your home, office, or villa in Morocco with our easy-to-use selling platform."
  />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.beytty.com/sell" />
  <meta property="og:image" content="/apple-touch-icon.png"/>
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="Sell Your Property Quickly - Best Value in Morocco | Beytty"
  />
  <meta
    name="twitter:description"
    content="List your property for sale on Beytty and sell quickly. Connect with potential buyers and get the best value for your home, office, or villa in Morocco with our easy-to-use selling platform."
  />
  <meta name="twitter:image" content="/apple-touch-icon.png" />

  <title>
    Vendez Votre Propriété Rapidement - Meilleure Valeur au Maroc | Beytty
  </title>
  <meta
    name="description"
    content="Listez votre propriété à vendre sur Beytty et vendez rapidement. Connectez-vous avec des acheteurs potentiels et obtenez la meilleure valeur pour votre maison, bureau ou villa au Maroc grâce à notre plateforme de vente facile à utiliser."
  />
  <meta
    name="keywords"
    content="vendre propriété, lister propriété, vente immobilière, vendre rapidement, immobilier au Maroc, propriété à vendre, vente de maison, vente de bureau, vente de villa, Beytty"
  />
  <meta
    property="og:title"
    content="Vendez Votre Propriété Rapidement - Meilleure Valeur au Maroc | Beytty"
  />
  <meta
    property="og:description"
    content="Listez votre propriété à vendre sur Beytty et vendez rapidement. Connectez-vous avec des acheteurs potentiels et obtenez la meilleure valeur pour votre maison, bureau ou villa au Maroc grâce à notre plateforme de vente facile à utiliser."
  />
  <meta
    name="twitter:title"
    content="Vendez Votre Propriété Rapidement - Meilleure Valeur au Maroc | Beytty"
  />
  <meta
    name="twitter:description"
    content="Listez votre propriété à vendre sur Beytty et vendez rapidement. Connectez-vous avec des acheteurs potentiels et obtenez la meilleure valeur pour votre maison, bureau ou villa au Maroc grâce à notre plateforme de vente facile à utiliser."
  />
</Helmet>


      <div className="relative bg-gray-200 h-[15rem] sm:h-[24rem] flex overflow-hidden">
        <img
          src={process.env.PUBLIC_URL + "/agenthero.jpeg"}
          alt="Man discussing real estate options with a professional agent, exploring property opportunities on Beytty"
          className="hidden bg-gray-200 lg:flex object-cover w-full h-full"
        />
        <img
          src={process.env.PUBLIC_URL + "/agentherosm.jpeg"}
          alt="Man discussing real estate options with a professional agent, exploring property opportunities on Beytty"
          className="flex bg-gray-200 lg:hidden object-cover w-full h-full"
        />
        <h1 className="absolute items-center top-[20%] sm:top-[30%] inset-0 text-white custom-font font-bold text-center text-2xl sm:text-4xl lg:text-5xl">
          {t("chooseyourselloption")}
        </h1>
        <h2 className="absolute px-4  top-[45%] inset-0 text-center text-md lg:text-lg text-white   ">
          {t("sellherotext")}
        </h2>
      </div>
      <div className="mx-auto p-8 max-w-6xl ">
        <ul className="hidden lg:grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-y-2">
          <li
            className="relative cursor-pointer border text-center hover:shadow-2xl items-center shadow-lg px-8 flex flex-col rounded-xl bg-white transition duration-300"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/beyttylisting");
            }}
          >
            <IoInformationCircleOutline
              onClick={(e) => {
                e.stopPropagation();
                handleOpenBeytty();
              }}
              className="absolute hover:text-black transition-all ease-in-out duration-150 top-2 right-2 text-2xl opacity-50 text-gray-500"
            />
            <img
              src={process.env.PUBLIC_URL + "/sellwithbimg.png"}
              alt="Vector illustration of listing with Beytty"
              className="mt-8 flex object-cover w-1/2"
            />
            <div>
              <h1 className="mt-2  custom-font font-bold text-2xl mb-4 ">
                {t("listwithbeytty")}
              </h1>
            </div>
            <ul className="mb-8">
              <div>
                <li className="flex justify-center items-center gap-2  font-semibold">
                  <MdDone style={{ color: "red" }} className="text-xl" />
                  {t("effortlessprocess")}
                </li>{" "}
                <p> {t("effortlessprocesstext")}</p>
              </div>
              <li className="flex justify-center items-center gap-2 mt-4 font-semibold">
                <MdDone style={{ color: "red" }} className="text-xl" />
                {t("professionalcontent")}
              </li>
              <p> {t("professionalcontenttext")}</p>
            </ul>

            <button
              type="button"
              className="mt-auto w-2/3 mb-8 border-2 border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white px-4 py-2 transition duration-300"
            >
              {t("list")}
            </button>
          </li>
          <li
            className="relative cursor-pointer border text-center hover:shadow-2xl items-center shadow-lg px-8  flex flex-col rounded-xl bg-white transition duration-300"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/agentlist");
            }}
          >
            <IoInformationCircleOutline
              onClick={(e) => {
                e.stopPropagation();
                handleOpenAgents();
              }}
              className="absolute  hover:text-black transition-all ease-in-out duration-150 top-2 right-2 text-2xl opacity-50 text-gray-500"
            />
            <img
              src={process.env.PUBLIC_URL + "/sellwithagentimg.png"}
              alt="Vector illustration of listing with an agent"
              className="mt-8 flex object-cover w-1/2"
            />
            <h1 className="mt-2 text-2xl  custom-font font-bold mb-4 ">
              {t("listwithagent")}
            </h1>
            <ul className="mb-8">
              <li className="flex justify-center items-center gap-2 font-semibold">
                <MdDone style={{ color: "red" }} className="text-xl" />
                {t("personalconnection")}
              </li>{" "}
              <p>{t("personalconnectiontext")}</p>
              <li className="flex justify-center items-center gap-2 mt-4 font-semibold">
                <MdDone style={{ color: "red" }} className="text-xl" />
                {t("showcasingexcellence")}
              </li>
              <p> {t("showcasingexcellencetext")}</p>
            </ul>

            <button
              type="button"
              className="mt-auto w-2/3 mb-8 border-2 border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white px-4 py-2 transition duration-300"
              à
            >
              {t("findagent")}
            </button>
          </li>
          <li
            className="relative cursor-pointer border text-center hover:shadow-2xl items-center shadow-lg px-8 flex flex-col rounded-xl bg-white transition duration-300"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/create-listing");
            }}
          >
            <IoInformationCircleOutline
              onClick={(e) => {
                e.stopPropagation();
                handleOpenMyself();
              }}
              className="absolute hover:text-black transition-all ease-in-out duration-150 top-2 right-2 text-2xl opacity-50 text-gray-500"
            />
            <img
              src={process.env.PUBLIC_URL + "/sellitmyselfimg.png"}
              alt="Vector illustration of self listing"
              className="mt-8 flex object-cover w-1/2"
            />
            <h1 className="mt-2 text-2xl  custom-font font-bold mb-4 ">
              {t("listitmyself")}
            </h1>
            <ul className="mb-4">
              <li className="flex justify-center items-center gap-2 font-semibold">
                <MdDone style={{ color: "red" }} className="text-xl" />
                {t("diyfreedom")}
              </li>{" "}
              <p> {t("diyfreedomtext")}</p>
              <li className="flex justify-center items-center gap-2 mt-4 font-semibold">
                <MdDone style={{ color: "red" }} className="text-xl" />
                {t("costeffective")}
              </li>
              <p> {t("costeffectivetext")}</p>
            </ul>

            <button
              type="button"
              className="mt-auto w-2/3 mb-8 border-2 border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white px-4 py-2 transition duration-300"
            >
              {t("list")}
            </button>
          </li>
        </ul>

        <ul className="lg:hidden grid gap-6 grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-y-2">
          <li
            className="relative cursor-pointer w-full border text-center hover:shadow-2xl items-center shadow-lg  flex  rounded-xl bg-white transition duration-300"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/beyttylisting");
            }}
          >
            <IoInformationCircleOutline
              onClick={(e) => {
                e.stopPropagation();
                handleOpenBeytty();
              }}
              className="absolute top-2 right-2 text-2xl opacity-50 text-gray-500"
            />

            <div className="w-1/2">
              <img
                src={process.env.PUBLIC_URL + "/sellwithbimg.png"}
                alt="Vector illustration of listing with Beytty"
                className="flex object-cover mb-2 mx-auto  max-w-xs w-2/3"
              />
            </div>

            <div className="text-left pr-8  w-1/2">
              <div>
                <h1 className="mt-8 custom-font font-bold text-2xl mb-4 ">
                  {t("listwithbeytty")}
                </h1>
              </div>
              <ul className="hidden sm:flex flex-col mb-8">
                <div>
                  <li className="flex  gap-2  font-semibold">
                    <MdDone style={{ color: "red" }} className="text-xl" />
                    {t("effortlessprocess")}
                  </li>{" "}
                  <p className="hidden sm:flex">{t("effortlessprocesstext")}</p>
                </div>
                <li className="flex  gap-2 mt-4 font-semibold">
                  <MdDone style={{ color: "red" }} className="text-xl" />
                  {t("professionalcontent")}
                </li>
                <p className="hidden sm:flex"> {t("professionalcontenttext")}</p>
              </ul>

              <button
                type="button"
                className="mt-auto w-full mb-8 border-2 border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white px-4 py-2 transition duration-300"
              >
                {t("list")}
              </button>
            </div>
          </li>
          <li
            className="relative cursor-pointer w-full border text-center hover:shadow-2xl items-center shadow-lg  flex  rounded-xl bg-white transition duration-300"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/agentlist");
            }}
          >
            <IoInformationCircleOutline
              onClick={(e) => {
                e.stopPropagation();
                handleOpenAgents();
              }}
              className=" absolute top-2 right-2 text-2xl opacity-50 text-gray-500"
            />

            <div className="w-1/2 flex items-center justify-center">
              <img
                src={process.env.PUBLIC_URL + "/sellwithagentimg.png"}
                alt="Vector illustration of listing with an agent"
                className="flex object-cover mb-2 mx-auto justify-center items-center max-w-xs w-2/3"
              />
            </div>

            <div className="text-left pr-8 w-1/2">
              <div>
                <h1 className="mt-8 custom-font font-bold text-2xl mb-4 ">
                  {t("listwithagent")}
                </h1>
              </div>
              <ul className="hidden sm:flex flex-col  mb-8">
                <div>
                  <li className="flex  gap-2  font-semibold">
                    <MdDone style={{ color: "red" }} className="text-xl" />
                    {t("personalconnection")}
                  </li>{" "}
                  <p className="hidden sm:flex">
                    {t("personalconnectiontext")}
                  </p>
                </div>
                <li className="flex  gap-2 mt-4 font-semibold">
                  <MdDone style={{ color: "red" }} className="text-xl" />
                  {t("showcasingexcellence")}
                </li>
                <p className="hidden sm:flex">
                  {" "}
                  {t("showcasingexcellencetext")}
                </p>
              </ul>

              <button
                type="button"
                className="mt-auto w-full mb-8 border-2 border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white px-4 py-2 transition duration-300"
              >
                {t("list")}
              </button>
            </div>
          </li>
          <li
            className="relative cursor-pointer w-full border text-center hover:shadow-2xl items-center shadow-lg  flex  rounded-xl bg-white transition duration-300"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/create-listing");
            }}
          >
            <IoInformationCircleOutline
              onClick={(e) => {
                e.stopPropagation();
                handleOpenMyself();
              }}
              className="absolute top-2 right-2 text-2xl opacity-50 text-gray-500"
            />

            <div className="w-1/2">
              <img
                src={process.env.PUBLIC_URL + "/sellitmyselfimg.png"}
                alt="Vector illustration of self-listing"
                className="flex object-cover mb-2 mx-auto  max-w-xs w-2/3"
              />
            </div>

            <div className="text-left pr-8 w-1/2">
              <div>
                <h1 className="mt-8 custom-font font-bold text-2xl mb-4 ">
                  {t("listitmyself")}
                </h1>
              </div>
              <ul className="hidden sm:flex flex-col  mb-8">
                <div>
                  <li className="flex  gap-2  font-semibold">
                    <MdDone style={{ color: "red" }} className="text-xl" />
                    {t("diyfreedom")}
                  </li>{" "}
                  <p className="hidden sm:flex">{t("diyfreedomtext")}</p>
                </div>
                <li className="flex  gap-2 mt-4 font-semibold">
                  <MdDone style={{ color: "red" }} className="text-xl" />
                  {t("costeffective")}
                </li>
                <p className="hidden sm:flex"> {t("costeffectivetext")}</p>
              </ul>

              <button
                type="button"
                className="mt-auto w-full mb-8 border-2 border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white px-4 py-2 transition duration-300"
              >
                {t("list")}
              </button>
            </div>
          </li>
        </ul>
      </div>
      <Dialog
        className="justify-center items-center text-center "
        open={openBeytty}
        onClose={handleCloseBeytty}
      >
        <DialogContent>
          <DialogContentText>
            <body>
              <p className="flex   gap-2 text-center justify-center  text-xl md:text-2xl font-semibold mb-2">
                {t("listingYourPropertyWithBeytty")}
              </p>
              <p className="text-sm mb-8">
                {t("whenYouListYourPropertyWithBeytty")}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleOneFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("submitYourListing")}{" "}
              </h3>
              <p className="text-sm mb-8">{t("beginBySubmitting")} </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleTwoFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("marketingAndPromotion")}{" "}
              </h3>
              <p className="text-sm mb-8">{t("onceYourListingIsLive")} </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleThreeFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("assistanceAndSupport")}{" "}
              </h3>
              <p className="text-sm mb-8">
                {t("throughoutTheSellingProcess")}{" "}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleFourFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("transparentPricing")}{" "}
              </h3>
              <p className="text-sm mb-8">{t("atBeytty")} </p>
            </body>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        className="justify-center items-center text-center "
        open={openAgents}
        onClose={handleCloseAgents}
      >
        <DialogContent>
          <DialogContentText>
            <body>
              <p className="flex   gap-2 text-center justify-center  text-xl md:text-2xl font-semibold mb-2">
                {t("listYourPropertyWithAnAgent")}{" "}
              </p>
              <p className="text-sm mb-8">
                {t("ifYouPreferToListYourProperty")}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleOneFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("findAnAgent")}{" "}
              </h3>
              <p className="text-sm mb-8">{t("exploreOurExtensiveList")}</p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleTwoFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("contactTheAgent")}{" "}
              </h3>
              <p className="text-sm mb-8">
                {t("onceYouIdentifiedSuitableAgent")}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleThreeFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("collaborationAndSupport")}{" "}
              </h3>
              <p className="text-sm mb-8">
                {t("collaborateCloselyWithYourChosenAgent")}
              </p>
            </body>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        className="justify-center items-center text-center "
        open={openMyself}
        onClose={handleCloseMyself}
      >
        <DialogContent>
          <DialogContentText>
            <body>
              <p className="flex   gap-2 text-center justify-center  text-xl md:text-2xl font-semibold mb-2">
                {t("listYourPropertyYourself")}{" "}
              </p>
              <p className="text-sm mb-8">{t("ifYouPreferToTakeControl")} </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleOneFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("createYourListing")}{" "}
              </h3>
              <p className="text-sm mb-8">
                {t("startByCreatingYourPropertyListing")}{" "}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleTwoFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("manageYourListing")}{" "}
              </h3>
              <p className="text-sm mb-8">
                {t("onceYourListingIsLiveControl")}{" "}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleThreeFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("negotiateAndClose")}{" "}
              </h3>
              <p className="text-sm mb-8">
                {t("engageDirectlyWithPotentialBuyers")}{" "}
              </p>

              <h3 className="flex font-semibold items-center gap-1 justify-center mb-2">
                <PiNumberCircleFourFill
                  style={{ color: "red" }}
                  className="text-xl"
                />
                {t("costEffectiveSolution")}{" "}
              </h3>
              <p className="text-sm mb-8">
                {t("listingYourPropertyYourself")}{" "}
              </p>
            </body>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
