import ReactGA from "react-ga4";

const initializeAnalytics = () => {
  ReactGA.initialize("445090096"); // Replace with your tracking ID
};

const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

const logEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
};

export { initializeAnalytics, logPageView, logEvent };
