import React from "react";

const MoroccanPlaces = () => {
  const locations = [
    {
      name: "Casablanca, Morocco",
      latitude: 33.5791,
      longitude: -7.626549,
      zoom: 11,
    },
    { name: "Rabat, Morocco", latitude: 34.0209, longitude: -6.8416, zoom: 11 },
    {
      name: "Marrakech, Morocco",
      latitude: 31.6077,
      longitude: -7.9811,
      zoom: 11,
    },
    { name: "Fes, Morocco", latitude: 34.0181, longitude: -5.0078, zoom: 11 },
    {
      name: "Tangier, Morocco",
      latitude: 35.7595,
      longitude: -5.802,
      zoom: 11,
    },
    { name: "Agadir, Morocco", latitude: 30.421, longitude: -9.5981, zoom: 11 },
    { name: "Oujda, Morocco", latitude: 34.6783, longitude: -1.9064, zoom: 11 },
    {
      name: "Kenitra, Morocco",
      latitude: 34.261,
      longitude: -6.5802,
      zoom: 11,
    },
    {
      name: "Tetouan, Morocco",
      latitude: 35.5785,
      longitude: -5.3684,
      zoom: 11,
    },
    { name: "Temara, Morocco", latitude: 33.9282, longitude: -7.348, zoom: 11 },
    { name: "Safi, Morocco", latitude: 32.2968, longitude: -9.2318, zoom: 11 },
    {
      name: "Mohammedia, Morocco",
      latitude: 33.6874,
      longitude: -7.3893,
      zoom: 11,
    },
    {
      name: "El Jadida, Morocco",
      latitude: 33.2338,
      longitude: -8.5018,
      zoom: 11,
    },
    { name: "Taza, Morocco", latitude: 34.219, longitude: -4.0087, zoom: 11 },
    { name: "Nador, Morocco", latitude: 35.1739, longitude: -2.9339, zoom: 11 },
    {
      name: "Settat, Morocco",
      latitude: 33.0014,
      longitude: -7.6164,
      zoom: 11,
    },
    {
      name: "Berrechid, Morocco",
      latitude: 33.267,
      longitude: -7.5861,
      zoom: 11,
    },
    {
      name: "Khouribga, Morocco",
      latitude: 32.8854,
      longitude: -6.9061,
      zoom: 11,
    },
    {
      name: "Beni Mellal, Morocco",
      latitude: 32.3373,
      longitude: -6.3629,
      zoom: 11,
    },
    {
      name: "Meknes, Morocco",
      latitude: 33.8935,
      longitude: -5.5428,
      zoom: 11,
    },
    { name: "Sale", latitude: 34.0522, longitude: -6.7981, zoom: 11 },
    {
      name: "Ouarzazate, Morocco",
      latitude: 30.9336,
      longitude: -6.9372,
      zoom: 11,
    },
    {
      name: "Errachidia, Morocco",
      latitude: 31.9292,
      longitude: -4.4244,
      zoom: 11,
    },
    {
      name: "Guelmim, Morocco",
      latitude: 28.9861,
      longitude: -10.0579,
      zoom: 11,
    },
    {
      name: "Laayoune, Morocco",
      latitude: 27.1492,
      longitude: -13.2006,
      zoom: 11,
    },
    {
      name: "Ain Chock, Casablanca",
      latitude: 33.533610269674824,
      longitude: -7.614482452930758,
      zoom: 13.1,
    },
    {
      name: "Ain Diab, Casablanca",
      latitude: 33.58442,
      longitude: -7.685185,
      zoom: 13.2,
    },
    {
      name: "Ain Sebaa, Casablanca",
      latitude: 33.60594,
      longitude: -7.538376,
      zoom: 13,
    },
    {
      name: "Maarif, Casablanca",
      latitude: 33.574037,
      longitude: -7.63598759,
      zoom: 14,
    },
    {
      name: "L'Oasis, Casablanca",
      latitude: 33.56008,
      longitude: -7.63700872,
      zoom: 14.2,
    },

    {
      name: "Californie, Casablanca",
      latitude: 33.53830278,
      longitude: -7.63144806,
      zoom: 14,
    },
    {
      name: "CFC, Casablanca",
      latitude: 33.563458678,
      longitude: -7.6625,
      zoom: 14.6,
    },
    {
      name: "Oulfa, Casablanca",
      latitude: 33.552988,
      longitude: -7.680989,
      zoom: 14,
    },
    {
      name: "Dar Bouazza, Casablanca",
      latitude: 33.5184405,
      longitude: -7.816612,
      zoom: 12.8,
    },
    {
      name: "Roches Noires, Casablanca",
      latitude: 33.592509,
      longitude: -7.59553,
      zoom: 14.05,
    },
    {
      name: "Sidi Ma'rouf, Casablanca",
      latitude: 33.5355392,
      longitude: -7.647584,
      zoom: 14.2,
    },
    {
      name: "Guéliz, Marrakesh",
      latitude: 31.63105,
      longitude: -8.0107,
      zoom: 14,
    },
    {
      name: "Annakhil, Marrakesh",
      latitude: 31.64909,
      longitude: -7.9583,
      zoom: 12.8,
    },
    {
      name: "Agdal, Rabat",
      latitude: 33.99673,
      longitude: -6.85477,
      zoom: 14,
    },
    {
      name: "Yacoub El Mansour, Rabat",
      latitude: 33.989059,
      longitude: -6.88027,
      zoom: 13.6,
    },
    {
      name: "Hay Riad, Rabat",
      latitude: 33.956484,
      longitude: -6.86915,
      zoom: 14,
    },
    {
      name: "Bouznika, Morocco",
      latitude: 33.773481,
      longitude: -7.159424,
      zoom: 11,
    },
    {
      name: "Bouskoura, Casablanca",
      latitude: 33.4497,
      longitude: -7.6517,
      zoom: 12,
    },
  ];

  return locations;
};

export default MoroccanPlaces;
