import React from "react";
import { IoStarSharp } from "react-icons/io5";

const StarRating = ({ rating }) => {
  const stars = [];
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;

  // Add full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(<IoStarSharp key={i} className="text-red-700" />);
  }

  // Add half star if applicable
  if (hasHalfStar) {
    stars.push(<IoStarSharp key={stars.length} className="text-red-700" />);
  }

  // Fill remaining stars with empty stars
  while (stars.length < 5) {
    stars.push(<IoStarSharp key={stars.length} className="text-gray-300" />);
  }

  return <div className="flex">{stars}</div>;
};

export default StarRating;
