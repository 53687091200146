
import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "./Spinner";
import Bar from "../components/Bar";


export default function PrivateRoute() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

   if (loading) {
     return <Bar loading={loading} />;
   }

  if (!user) {
    // User not logged in, navigate to sign-in
    return <Navigate to="/sign-in" />;
  }

  if (!user.emailVerified) {
    // User is logged in but email is not verified, navigate to email verification page or show message
    return <Navigate to="/email-verification" />;
  }

  // User is logged in and email is verified, allow access to private route
  return <Outlet />;
}
