import React from "react";

export default function Spinner() {
  return (
    <div className="spinner-container flex h-screen justify-center items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          margin: "auto",
          background: "none",
          display: "block",
          shapeRendering: "auto",
          width: "100px",
          height: "100px",
        }}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          stroke-linecap="round"
          fill="none"
          stroke-dasharray="54.97787143782138 54.97787143782138"
          stroke="#e61b00"
          stroke-width="10"
          r="35"
          cy="50"
          cx="50"
        >
          <animateTransform
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="0.6493506493506493s"
            repeatCount="indefinite"
            type="rotate"
            attributeName="transform"
          ></animateTransform>
        </circle>
      </svg>
    </div>
  );
}
