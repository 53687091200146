import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../components/Spinner"; // Import the Spinner component

const UserGuard = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [isUser, setIsUser] = useState(false); // Assume non-agent initially
  const navigate = useNavigate();

  const auth = getAuth();

  useEffect(() => {
    const checkUserStatus = async (user) => {
      if (user) {
        const userStatus = await isUserUser(user); // Replace with your agent check logic
        setIsUser(userStatus);
      }
      setIsLoading(false); // Set loading to false after check
    };

    const unsubscribe = onAuthStateChanged(auth, checkUserStatus);

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (!isLoading) {
      if (!isUser) {
        navigate("/"); // Redirect to default page if not an agent
      }
    }
  }, [isLoading, isUser, navigate]);

  if (isLoading) {
    return <Spinner />; // Display Spinner component during check
  }

  return <>{children}</>;
};

const isUserUser = async (user) => {
  // Implement your logic to check if the user is an agent
  // This example assumes you have an "agents" collection in Firestore
  const userDocRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(userDocRef);
  return userDoc.exists(); // Replace with your actual agent verification logic
};

export default UserGuard;
