import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "./Spinner"; // Import the Spinner component

const AdminGuard = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [isAdmin, setIsAdmin] = useState(false); // Assume non-admin initially
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const checkAdminStatus = async (user) => {
      if (user) {
        const adminStatus = await isAdminUser(user);
        setIsAdmin(adminStatus);
      }
      setIsLoading(false); // Set loading to false after check
    };

    const unsubscribe = onAuthStateChanged(auth, checkAdminStatus);

    return () => unsubscribe();
  }, [auth]);

  // Conditionally render based on loading and admin status
  if (isLoading) {
    return <Spinner />; // Display Spinner component during check
  }

  if (!isAdmin) {
    navigate("/");
    return null;
  }

  return <>{children}</>;
};

const isAdminUser = async (user) => {
  const userDocRef = doc(db, "admins", user.uid);
  const userDoc = await getDoc(userDocRef);
  return userDoc.exists();
};

export default AdminGuard;
