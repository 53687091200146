import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, sendEmailVerification,  onAuthStateChanged,
 } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { MdOutlineVerified } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";

export default function EmailVerificationPage() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [countdown, setCountdown] = useState(0); // State for countdown timer
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsVerified(user.emailVerified);
      } else {
        setIsVerified(false);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [countdown]);

  const handleResendVerificationEmail = async () => {
    setLoading(true);
    setMessage("");

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        await sendEmailVerification(user);
        setMessage(t("emailsent"));
        setCountdown(60); // Start the countdown
      } else {
        setMessage(t("usernotfound"));
      }
    } catch (error) {
      console.error("Error sending verification email:", error);
      setMessage(t("failedemail"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-white">
      <div className="max-w-xl px-5 text-center">
        {isVerified ? (
          <>
            <MdOutlineVerified className="mx-auto h-20 w-20 mb-2 -mt-24 " />
            <h2 className="text-[32px] sm:text-[42px] mb-8 font-bold text-zinc-800">
              {t("successfullyverified")}
            </h2>
            <button
              className="bg-gray-100 hover:bg-gray-200 border-2 border-gray-200 text-black rounded p-4"
              onClick={() => navigate("/")}
            >
              Continue to Beytty
            </button>
          </>
        ) : (
          <>
            <MdOutlineEmail className="mx-auto h-20 w-20 mb-2 -mt-24" />
            <h2 className="mb-2 text-[32px] sm:text-[42px] font-bold text-zinc-800">
              {t("checkyourinbox")}
            </h2>
            <p className="mb-2 px- sm:text-lg text-zinc-500">
              {t("weareglad")}
            </p>
            <button
              onClick={handleResendVerificationEmail}
              className="mt-3 inline-block w-80 rounded bg-custom-red px-5 py-3 font-medium text-white shadow-md shadow-indigo-500/20"
              disabled={loading || countdown > 0}
            >
              {loading
                ? t("checkyourinbox")
                : countdown > 0
                ? `00:${countdown < 10 ? `0${countdown}` : countdown}`
                : t("resend")}
            </button>
            {message && <p className="mt-3 text-zinc-500">{message}</p>}
          </>
        )}
      </div>
    </div>
  );
}
